import React from "react";
import { Link, useHistory } from "react-router-dom";

function WhoWeAreArea() {
  const history = useHistory();

  function gotoNew() {
    history.push("/new");
  }

  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
          alt="section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-6 col-md-10">
              <div className="about-img-area">
                {/* <div className="total-tag">
                  <img src={process.env.PUBLIC_URL + "/images/bg/total-tag.png"} alt="images" />
                  <h6>Total Raised</h6>
                  <h5>$45,390.00</h5>
                </div> */}
                <img
                  src={"/images/bg/Levo3.png"}
                  className="img-fluid about-img wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  alt="about-img"
                  height="300px"
                />
                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-linear.png"}
                  className="img-fluid about-linear"
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-vector.png"}
                  className="img-fluid about-vector"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="about-content wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                {/* <span>Who we are!</span> */}
                <h3>Unlock the true value of your bike!</h3>
                <p className="para">
                  Ready to turn your high-end bike into cash? Look no further
                  than BidBikes, the ultimate marketplace for bike enthusiasts!
                  Whether you have a high-end carbon road bike, a cutting-edge
                  eMTB, or a custom blinged-up mountain bike, we're here to help
                  you sell it quickly and profitably.
                </p>
                {/* <p className="para">But getting your own auction site up and running has always required learning complex coding languages, or hiring an expensive design firm for thousands of dollars and months of work.</p> */}
                {/* <ul className="about-list">
                  <li>
                    <Link to={"#"} disabled={true}>
                      Maximize your earnings.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"} disabled={true}>
                      Easy listing process.
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"} disabled={true}>
                      Dedicated bike enthusiasts
                    </Link>
                  </li>
                </ul> */}
                {/* <Link to={"#"} className="eg-btn btn--primary btn--md">
                  More About
                </Link> */}
                <button
                  className="form-button join-button wow fadeInUp"
                  style={{
                    maxWidth: "300px",
                    marginTop: "40px",
                  }}
                  onClick={gotoNew}
                >
                  Sell Your Bike
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoWeAreArea;
