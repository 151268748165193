import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import FB from "../../../data/Firebase";
import Breadcrumb from "../../common/Breadcrumb";

function AdminDashboard() {
  const [auctions, setAuctions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue") || "All"
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState("id"); // Default sorting field is "id"
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order is ascending
  const [sortedAuctions, setSortedAuctions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Retrieve the selected value from localStorage when the component mounts
    const storedValue = localStorage.getItem("selectedValue");
    if (storedValue) {
      setSelectedValue(storedValue);
    }
  }, []);

  // Function to handle sorting change
  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortBy(value);
    setSortedAuctions(sortData(auctions));
  };

  // Function to toggle sorting order between "asc" and "desc"
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setSortedAuctions(sortData(auctions));
  };

  // Function to sort users or auctions based on the selected field and order
  const sortData = (data) => {
    const sortedData = [...data].sort((a, b) => {
      if (sortBy === "current_bid") {
        // Convert bids to numbers for numerical sorting
        const bidA = parseFloat(a[sortBy]);
        const bidB = parseFloat(b[sortBy]);
        if (sortOrder === "asc") {
          return bidA - bidB;
        } else {
          return bidB - bidA;
        }
      } else if (sortBy === "status") {
        // Custom sorting for the "status" field
        // Check if the status field exists in both objects
        if (a.hasOwnProperty(sortBy) && b.hasOwnProperty(sortBy)) {
          if (sortOrder === "asc") {
            return a[sortBy].localeCompare(b[sortBy]);
          } else {
            return b[sortBy].localeCompare(a[sortBy]);
          }
        } else {
          // Handle the case where the status field is missing in one of the objects
          return 0;
        }
      } else {
        // Default sorting using localeCompare for strings (e.g., "id", "title")
        if (a.hasOwnProperty(sortBy) && b.hasOwnProperty(sortBy)) {
          if (sortOrder === "asc") {
            return a[sortBy].localeCompare(b[sortBy]);
          } else {
            return b[sortBy].localeCompare(a[sortBy]);
          }
        } else {
          // Handle the case where the sortBy field is missing in one of the objects
          return 0;
        }
      }
    });
    return sortedData;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        const auctionsArray = await FB.getAuctions({
          searchTerm: selectedValue === "All" ? null : selectedValue,
        });
        setAuctions(auctionsArray);
        setSortedAuctions(auctionsArray);
      } catch (error) {
        // Handle the error
        setError(error.message);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedValue]);

  useEffect(() => {
    setSortedAuctions(sortData(auctions));
  }, [auctions, sortBy, sortOrder]);

  useEffect(() => {
    setSortedAuctions(sortData(filterData(auctions)));
  }, [auctions, searchQuery]);

  // Function to handle select change and save it to localStorage
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    localStorage.setItem("selectedValue", value); // Save to localStorage
  };

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  }

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    if (value === "") {
      setSortedAuctions(auctions);
    } else {
      setSortedAuctions(sortData(filterData(auctions)));
    }
  };

  // Function to filter users or auctions based on the search query
  const filterData = (data) => {
    return data.filter((item) => {
      // For auctions, we will filter based on the title and status fields
      const normalizedSearchQuery = searchQuery.toLowerCase();
      const normalizedTitle = item.title ? item.title.toLowerCase() : "";

      return normalizedTitle.includes(normalizedSearchQuery);

      return false; // Return false for other cases (if any)
    });
  };

  const formatDate = (timestamp) => {
    const date = FB.convertFirestoreTimestampToDate(timestamp);

    if (!date) {
      // Not a recognized timestamp, return a default value
      return "N/A";
    }

    // Use built-in toLocaleDateString to format the date.
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div>
      <Breadcrumb pageName="Dashboard" pageTitle="Dashboard" />
      <div
        style={{
          paddingTop: "65px",
          paddingBottom: "40px",
        }}
        className="container-fluid"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", margin: "0 auto" }}>
            <div className="button-group" style={{ marginTop: "20px" }}>
              <NavLink
                to="/admin"
                activeClassName="active-link"
                style={{ marginRight: "20px" }}
              >
                <button className="profile-btn">Users</button>
              </NavLink>
              <NavLink
                to={`/admin-auction`}
                activeClassName="active-link"
                style={{ marginRight: "20px" }}
              >
                <button className="profile-btn">Auctions</button>
              </NavLink>
              <NavLink
                to={`/admin-referrals`}
                activeClassName="active-link"
                style={{ marginRight: "20px" }}
              >
                <button className="profile-btn">Referrals</button>
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 0px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  {/* ... Other code ... */}
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      minWidth: "200px",
                      marginBottom: "10px",
                    }}
                  />
                </div>

                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div style={{ marginRight: "10px" }}>
                    Sort By:
                    <select
                      id="selection"
                      value={sortBy}
                      onChange={handleSortChange}
                      style={{
                        padding: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        minWidth: "100px",
                        marginLeft: "5px",
                      }}
                    >
                      {selectedValue === "users" ? (
                        <>
                          <option value="id">ID</option>
                          <option value="firstName">First Name</option>
                          <option value="lastName">Last Name</option>
                        </>
                      ) : (
                        <>
                          <option value="id">ID</option>
                          <option value="status">Status</option>
                        </>
                      )}

                      {/* Add more fields if needed */}
                    </select>
                  </div>
                  <div className="button-group">
                    Sort Order:
                    <button
                      className="profile-btn"
                      onClick={toggleSortOrder}
                      style={{
                        padding: "2px 5px",
                        minWidth: "75px",
                        marginLeft: "5px",
                      }}
                    >
                      {sortOrder === "asc" ? "Asc" : "Desc"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                <select
                  id="selection"
                  name="selection"
                  value={selectedValue}
                  onChange={handleSelectChange}
                  style={{
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    minWidth: "100px",
                  }}
                >
                  <option value="All">All</option>
                  <option value="Submitted">Submitted</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Live">Live</option>
                  <option value="Reserve Not Met">Reserve Not Met</option>
                  <option value="Sold">Sold</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <table style={{ paddingTop: "20px" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Bid</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {sortedAuctions.map((auction, index) => (
                <tr key={auction.id} className="table-row">
                  <td>
                    <Link to={`/auction-details/${auction.id}`}>
                      {auction.id.substring(0, 6)}
                    </Link>
                  </td>
                  <td>{auction.title}</td>
                  <td>{formatCurrency(auction.current_bid)}</td>
                  <td>{auction.status}</td>
                  <td>
                    <Link to={`/auction/${auction.id}/edit`}>Edit</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
