import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import AuctionDetailsWrap from "./AuctionDetailsWrap";
import { useParams } from "react-router-dom";
import FB from "../../../data/Firebase";

export const ListingContext = React.createContext(null);

function AuctionDetails() {
  const { id } = useParams();
  const [auction, setAuction] = useState([]);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(id);

    const fetchAuctionData = async () => {
      try {
        const auctionData = await FB.getAuctionInfo(id);
        if (auctionData) {
          setAuction(auctionData);
        }
      } catch (error) {
        console.error("Failed to fetch auction:", error);
        // Handle error (show message, redirect, etc.)
      }
    };

    fetchAuctionData();

    const unsubscribe = FB.getUserAuctionSubscription(id, (auction) => {
      setAuction(auction);
    });

    return () => unsubscribe();
  }, [id]);

  return (
    <>
      <ListingContext.Provider
        value={{ auction, isHeaderVisible, setIsHeaderVisible }}
      >
        {/* <Breadcrumb pageName="Auction Details" pageTitle="Auction Details" /> */}
        <AuctionDetailsWrap />
        {/* <AboutUsCounter/> */}
      </ListingContext.Provider>
    </>
  );
}

export default AuctionDetails;
