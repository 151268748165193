import React from "react";
import LiveAuctionLatest from "../LiveAuction/LiveAuctionLatest";

function LiveAuctionHome() {
  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center">
        <div style={{ paddingTop: "40px" }}>
          <div className="section-title4">
            <h2>Latest Auctions</h2>
          </div>
        </div>

        <LiveAuctionLatest />
      </div>
    </div>
  );
}

export default LiveAuctionHome;
