import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";

function ResendVerification() {
  const [verificationEmail, setVerificationEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
  });
  const location = useLocation();
  const { email } = location.state || {};
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    const checkEmailVerification = async () => {
      const user = FB.auth.currentUser;

      if (user) {
        await user.reload();
        if (user.emailVerified) {
          // Perform the action you want after email verification
          console.log("Email verified!");
          // Send welcome email here
          console.log("now let's send the welcome email");
        }
      }
    };

    const unsubscribe = FB.auth.onAuthStateChanged((user) => {
      if (user) {
        checkEmailVerification();
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="signup-section pt-160 pb-120">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div
              className="form-wrapper wow fadeInUp rounded p-4"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <div className="form-title">
                <div style={{ textAlign: "left" }}>
                  <h3>Please verify your email</h3>
                  <p>
                    Almost done! We sent a verification email to: <b>{email}</b>
                  </p>
                  <p>
                    Click the button in your email to activate your account.
                  </p>
                </div>
              </div>
              <div className="">
                <h4>Can't find the email?</h4>
                <p>
                  It may be in your spam folder. If you're still having trouble,
                  add "info@bidbikes.com" to your email contacts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResendVerification;
