import React, { useState, useEffect } from "react";
import ContentOfBidsWins from "./ContentOfBidsWins";
import ContentOfProfile from "./ContentOfProfile";
import ContentOfListings from "./ContentOfListings";
import ContentOfMessages from "./ContentOfMessages";
import ContentOfWatchList from "./ContentOfWatchList";
import ReferralInfo from "./ReferralInfo";
import ContentOfPrivateMessages from "./ContentOfPrivateMessages";
import DashbordMenu from "./DashboardMenu";
import FB from "../../../data/Firebase";

function DashbordWrap() {
  return (
    <>
      <div
        className="dashboard-section pt-0 pb-60"
        style={{ marginTop: "70px" }}
      >
        {/* <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        /> */}
        <div className="container-fluid">
          <div className="row g-4">
            <DashbordMenu />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <ContentOfDashboardTab /> */}
                <ContentOfProfile />
                <ContentOfBidsWins />
                <ContentOfListings />
                <ContentOfMessages />
                <ContentOfWatchList />
                <ReferralInfo />
                {/* <ContentOfPrivateMessages /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashbordWrap;
