import React, { useEffect, useState, createContext } from "react";
import { Link, useParams } from "react-router-dom";
import FB from "../../../data/Firebase";
import AboutUsCounter from "../../common/AboutUsCounter";
import Breadcrumb from "../../common/Breadcrumb";
import BlogDetailsWrap from "./BlogDetailsWrap";

const BlogContext = createContext();

function BlogDetails() {
  const { id } = useParams();
  const [post, setPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postData = await FB.fetchPostById(id);
        setPost(postData);
        setIsLoading(false);
      } catch (error) {
        // Handle the error
        console.error("Error fetching post:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <BlogContext.Provider value={post}>
        <Breadcrumb pageName="Blog Details" pageTitle="Blog Details" />
        <BlogDetailsWrap />
        {/* <AboutUsCounter/> */}
      </BlogContext.Provider>
    </>
  );
}

export default BlogDetails;
export { BlogContext };
