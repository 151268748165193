import React, { useState, useEffect } from "react";
import Pagination from "../../common/Pagination";
import LiveAuctionCard from "./LiveAuctionCard";
import FB from "../../../data/Firebase";
import LoadingSpinner from "../../common/LoadingSpinner";
import { Link } from "react-router-dom";

function LiveAuctionLatest() {
  const [auctionData, setAuctionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  //   const [currentListings, setCurrentListings] = useState([]);
  const [watchList, setWatchList] = useState([]);
  const [user, setUser] = useState(null);

  const [searchQuery, setSearchQuery] = useState({
    year: "",
    brand: "",
    type: "",
    condition: "",
  });

  // function handleImagesLoad() {
  //   if (loadedImagesCount < currentListings.length) {
  //     setLoadedImagesCount(loadedImagesCount + 1);
  //   }
  // }

  function handleImagesLoad() {}

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = FB.auth.onAuthStateChanged((authUser) => {
      if (isMounted && authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe(); // Unsubscribe from the auth state change listener
      }
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (user) {
      FB.getWatchlist().then((watchlist) => {
        if (isMounted) {
          setWatchList(watchlist);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  useEffect(() => {
    const unsubscribe = FB.getLiveAuctionListings(
      searchQuery,
      (updatedListings) => {
        const filteredListings = updatedListings
          .filter((auction) => auction.featured === "Yes")
          .sort((a, b) => {
            const timeA =
              new Date(a.bid_end_time.seconds * 1000).getTime() +
              a.bid_end_time.nanoseconds / 1000000;
            const timeB =
              new Date(b.bid_end_time.seconds * 1000).getTime() +
              b.bid_end_time.nanoseconds / 1000000;
            return timeB - timeA;
          });

        setAuctionData(filteredListings);
      }
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [searchQuery]);

  //   useEffect(() => {
  //     // Set up the real-time listener and get the unsubscribe function
  //     const unsubscribe = FB.getLiveAuctionListings(
  //       searchQuery,
  //       (updatedListings) => {
  //         // Callback function to handle the updated listings
  //         // console.log(updatedListings);

  //         const filteredListings = updatedListings.filter(
  //           (auction) => auction.featured === "Yes"
  //         );
  //         // console.log(filteredListings);

  //         setAuctionData(filteredListings);
  //       }
  //     );

  //     // Return a cleanup function to unsubscribe when the component unmounts
  //     return () => {
  //       if (unsubscribe) {
  //         unsubscribe(); // Unsubscribe from the real-time listener
  //       }
  //     };
  //   }, [searchQuery]);

  return (
    <>
      <div className="live-auction-container">
        {auctionData.map((auction) => (
          <div key={auction.id}>
            <LiveAuctionCard
              auction={auction}
              onImagesLoad={handleImagesLoad}
              watchList={watchList}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default LiveAuctionLatest;
