import React, { useState, useEffect, useContext, useRef } from "react";
import AuctionDetailsInfo from "./AuctionDetailsInfo";
import AuctionDetailsTab from "./AuctionDetailsTab";
import Counter from "../../common/Counter";
import { ListingContext } from "../auctionDetails/AuctionDetails";
import BidModal from "../../common/BidModal";
import FB from "../../../data/Firebase";
import { useHistory } from "react-router-dom";

function AuctionDetailsWrap() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { auction } = useContext(ListingContext);
  const bidSectionRef = useRef(null);
  const { isHeaderVisible } = useContext(ListingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recentBid, setRecentBid] = useState(0);
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const history = useHistory();

  function handleImagesLoad() {
    setIsLoaded(true);
  }

  const scrollToBidSection = () => {
    bidSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (user) {
      if (userDetails.stripeCustomerId) {
        handleOpenModal();
      } else {
        alert("Please register to bid via your user dashboard.");
        history.push(`/dashboard`); // Redirect to the dashboard where user can register to bid
      }
    } else {
      history.push(`/login`); // Redirect to the login page
    }
  };

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    FB.auth.onAuthStateChanged((authUser) => {
      if (authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      if (user) {
        const userDetails = await FB.getUserInfo(user.uid);
        setUserDetails(userDetails);
      }
    };

    getUserDetails();
  }, [user]);

  const handleSignIn = () => {
    history.push({
      pathname: "/login",
      state: { from: `/auction-details/${auction.id}` },
    });
  };

  const handleRegister = () => {
    history.push({
      pathname: "/register",
      state: { from: `/auction-details/${auction.id}` },
    });
  };

  const BidButton = ({ user, userDetails }) => {
    const handleClick = () => {
      if (!user) {
        handleSignIn();
      } else if (userDetails && userDetails.stripeCustomerId) {
        handleOpenModal();
      } else {
        handleRegister();
      }
    };

    return (
      <button
        className="form-button"
        onClick={handleClick}
        style={{ width: "120px" }}
      >
        Bid
      </button>
    );
  };

  useEffect(() => {
    if (auction && auction.current_bid) {
      const bid = auction.current_bid;
      setRecentBid(bid);
      //console.log(recentBid);
    }
  }, [auction]);

  return (
    <div>
      <div
        className="sticky-auction-status"
        style={{ display: isHeaderVisible ? "block" : "none" }}
      >
        <div className="sticky-auction-status-inner">
          <Counter auction={auction} />
          {auction.status === "Live" && (
            <BidButton
              auction={auction}
              user={user}
              userDetails={userDetails}
            />
          )}
        </div>
      </div>
      {isModalOpen && (
        <BidModal
          handleCloseModal={handleCloseModal}
          auctionId={auction.id}
          recentBid={recentBid}
          setRecentBid={setRecentBid}
          onClose={handleCloseModal}
          min={recentBid}
          max={20000}
        />
      )}
      <div className="auction-details-section">
        <AuctionDetailsInfo imagesDoneLoading={handleImagesLoad} />
        <AuctionDetailsTab bidSectionRef={bidSectionRef} />
      </div>
    </div>
  );
}

export default AuctionDetailsWrap;
