import React from "react";
import { Link } from "react-router-dom";

function Pagination({ totalListings, listingsPerPage, paginate }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalListings / listingsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="row">
      <nav className="pagination-wrap">
        <ul className="pagination d-flex justify-content-center gap-md-3 gap-2">
          <li className="page-item">
            <Link
              className="page-link"
              to={"#"}
              onClick={() => paginate(1)}
              tabIndex={-1}
            >
              Prev
            </Link>
          </li>
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <Link
                className="page-link"
                to={"#"}
                onClick={() => paginate(number)}
              >
                {number}
              </Link>
            </li>
          ))}
          <li className="page-item">
            <Link
              className="page-link"
              to={"#"}
              onClick={() => paginate(pageNumbers.length)}
            >
              Next
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
