import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import the plugin
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

registerPlugin(
  // previews dropped images
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit,
  FilePondPluginFileEncode
);

function EditAuction(props) {
  const [auction, setAuction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [localBidEndTime, setLocalBidEndTime] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);
  //const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB
  const history = useHistory();
  const { id } = useParams();

  const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [bikeTypes, setBikeTypes] = useState([]);
  const [selectedBikeType, setSelectedBikeType] = useState("");
  const [selectedFrameSize, setSelectedFrameSize] = useState("");
  const [selectedWheelSize, setSelectedWheelSize] = useState("");
  const [frameSizes, setFrameSizes] = useState([]);
  const [wheelSizes, setWheelSizes] = useState([]);

  // State to hold files
  const [files, setFiles] = useState([]);
  const [canReorder, setCanReorder] = useState(true);

  // Define the server object here
  const server = {
    // Process method for uploading files
    process: async (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort
    ) => {
      // Generate a unique ID and construct the storage path
      const uniqueId = uuidv4();
      const storagePath = `Auctions/${id}/${uniqueId}`;

      // Start the upload process
      const uploadTask = FB.uploadFile(storagePath, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const pct = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
        },
        (err) => {
          console.error(err);
          error(err.message);
          abort();
        },
        async () => {
          try {
            // Retrieve the download URL
            const downloadURL = await FB.getFileDownloadURL(storagePath);
            //console.log("process", downloadURL);

            // Here you store both the path and the URL in Firestore
            await FB.addFileToAuction(id, {
              path: storagePath,
              url: downloadURL,
            });

            //await FB.updateImageOrder(id, uniqueId, "add");

            // Use the uniqueId or the downloadURL to mark the process as complete
            load(uniqueId);
          } catch (err) {
            console.error("Error during Firestore update:", err);
            error(err.message);
          }
        }
      );

      // Return the abort function
      return () => uploadTask.cancel();
    },

    // This function handles the file deletion process
    revert: (uniqueFileId, load, error) => {
      //console.log(uniqueFileId);

      const storagePath = `Auctions/${id}/${uniqueFileId}`;
      //console.log(storagePath);
      FB.removeFileFromAuction(id, storagePath);
    },

    // Load method for retrieving and displaying existing files
    load: async (source, load, error, progress, abort) => {
      let downloadUrl = source;
      //console.log(downloadUrl);

      // If the source is an object, extract the downloadUrl; otherwise, use it directly
      if (typeof source === "object" && source.downloadUrl) {
        downloadUrl = source.downloadUrl;
      }

      try {
        const response = await fetch(downloadUrl);

        // Check for a successful response
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        load(blob);
      } catch (err) {
        error(err.message);
        // Optionally, you can use the abort() call here if needed
        // abort();
      }

      // Setup an abort function in case the loading needs to be cancelled
      return () => {
        console.log("Load request aborted");
        abort();
      };
    },

    // Method to handle file deletion
  };

  useEffect(() => {
    async function initFilePond() {
      const fileItems = await FB.fetchFilesForAuction(id); // This should now fetch the adjusted images array

      const filePondItems = fileItems.map((file) => ({
        source: file.url, // Use the file URL for FilePond's source
        options: {
          type: "local",
          metadata: {
            // Store the path or any other relevant data as metadata if needed
            path: file.path,
            size: file.size,
          },
        },
      }));
      setFiles(filePondItems);
    }
    initFilePond();
  }, [auction]);

  const handleReorderFiles = async (newFiles) => {
    // Construct a new order data array from the reordered FilePond files
    const newOrderData = newFiles
      .map((fileItem) => {
        // Attempt to safely extract metadata, which contains our file path
        const metadata = fileItem.getMetadata();

        // We expect 'path' to be stored in the metadata by the load method
        const path = metadata.path;

        // The serverId is used as a direct URL to the file for loading purposes
        const url = fileItem.serverId;
        return { path, url };
      })
      .filter((item) => item.path && item.url); // Filter out any items missing path or url

    //console.log("Updated Files Data for Firestore:", newOrderData);

    // Update the file order in Firestore
    try {
      await FB.updateImageOrderAndUrls(id, newOrderData);
      console.log("File order updated successfully");
    } catch (error) {
      console.error("Error updating file order: ", error);
    }
  };

  async function handleFileDelete(error, fileItem) {
    console.log(fileItem);

    try {
      // Decoding the filePath
      const decodedFilePath = decodeURIComponent(fileItem.filename);
      console.log(decodedFilePath);

      // Call the method to delete the file from storage and Firestore
      await FB.removeFileFromAuction(id, decodedFilePath);
      console.log("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  }

  // fetch all non-dependent data on component mount
  useEffect(() => {
    fetchBrands();
    fetchYears();
    fetchBikeTypes();
  }, []);

  const fetchYears = async () => {
    try {
      const yearsArray = await FB.fetchData("years", "yearList");
      if (yearsArray) {
        // Sort years from most recent to oldest
        const sortedYears = yearsArray.sort(
          (a, b) => parseInt(b) - parseInt(a)
        );
        setYears(sortedYears);
      } else {
        console.log("No years data found");
      }
    } catch (error) {
      console.error("Error fetching years data:", error);
    }
  };

  // For Brands
  const fetchBrands = async () => {
    try {
      const brandsData = await FB.fetchData("brands", "brandList");
      //   console.log(brandsData);
      if (brandsData) {
        // Sort the brandArray in ascending order
        brandsData.sort((a, b) => a.localeCompare(b));

        setBrands(brandsData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  const fetchBikeTypes = async () => {
    try {
      const bikeTypesData = await FB.fetchData("bikeTypes", "bikeTypeList");
      //   console.log(bikeTypesData);

      if (bikeTypesData) {
        setBikeTypes(bikeTypesData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    fetchFrameSizes(selectedBikeType);
    fetchWheelSizes(selectedBikeType);
  }, [selectedBikeType]);

  const fetchFrameSizes = async (bikeType) => {
    if (bikeType) {
      const frameSizes = await FB.fetchSizes(bikeType, "frameSizes");

      if (frameSizes) {
        setFrameSizes(frameSizes);
      }
    }
  };

  const fetchWheelSizes = async (bikeType) => {
    if (bikeType) {
      const wheelSizes = await FB.fetchSizes(bikeType, "wheelSizes");

      if (wheelSizes) {
        setWheelSizes(wheelSizes);
      }
    }
  };

  useEffect(() => {
    fetchAuctionInfo();
  }, [id]);

  const fetchAuctionInfo = async () => {
    try {
      if (!id || id.length !== 20) {
        setAuction(null);
        return;
      }
      const auction = await FB.getAuctionInfo(id);
      if (auction) {
        setAuction(auction);
        const bidEndTime = auction.bid_end_time;
        const formatBidEndTime = formatDateTime(bidEndTime);

        setLocalBidEndTime(formatBidEndTime);
        setAuction((prevAuction) => ({
          ...prevAuction,
          bid_end_time: bidEndTime,
        }));
      } else {
        console.log(`No auction found with ID ${id}`);
      }
    } catch (error) {
      console.error("Error fetching auction details:", error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      // Convert localBidEndTime to UTC timestamp
      const localDate = new Date(localBidEndTime);
      const bidEndTime = FB.dateToTimestamp(localDate);

      // Update the auction data using the Firebase class method
      setLoading(true);

      // Incorporate selected image data into auction data

      const auctionData = {
        ...auction,
        bid_end_time: bidEndTime,
        //additional_images: additionalImages,
        taskID: "",
      };

      await FB.updateAuction(id, auctionData);

      // Redirect the user back to the profile page
      history.push(`/auction/${id}`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function handleBack() {
    history.push("/admin-auction");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "bid_end_time") {
      setLocalBidEndTime(value);
    } else {
      setAuction((prevAuction) => ({
        ...prevAuction,
        [name]: value,
      }));
    }

    if (name === "type") {
      setSelectedBikeType(value);
    }
  };

  const formatDateTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const localDate = new Date(timestamp.seconds * 1000);
      const year = localDate.getFullYear();
      const month = `${(localDate.getMonth() + 1).toString().padStart(2, "0")}`;
      const day = `${localDate.getDate().toString().padStart(2, "0")}`;
      const hours = `${localDate.getHours().toString().padStart(2, "0")}`;
      const minutes = `${localDate.getMinutes().toString().padStart(2, "0")}`;

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    } else if (typeof timestamp === "string") {
      return timestamp;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (auction) {
      // Fetch frame sizes and wheel sizes based on the auction's bike type
      fetchFrameSizes(auction.type);
      fetchWheelSizes(auction.type);
    }
  }, [auction]);

  const handleUpdateDescription = async () => {
    setLoading(true);
    try {
      // Inside your component function
      const description = await FB.generateAuctionDescription(id);
      console.log(description);

      // Optionally, refresh the auction data to show the updated description
      await fetchAuctionInfo();
    } catch (error) {
      console.error("Error updating description: ", error);
    }
    setLoading(false);
  };

  if (!auction) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Breadcrumb
        pageName="Admin Edit Auction"
        pageTitle="Admin Edit Auction"
      />
      <div
        style={{
          paddingTop: "100px",
          paddingBottom: "20px",
        }}
      >
        <div className="form-wrapper">
          <form onSubmit={handleFormSubmit}>
            {/* Existing auction fields */}
            <label htmlFor="year">Year:</label>
            <select
              type="number"
              name="year"
              value={auction.year}
              onChange={handleInputChange}
              required
              className="form-input"
            >
              <option value="">Select Year</option>
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <label htmlFor="type">Type:</label>
            <select
              type="text"
              name="type"
              value={auction.type}
              onChange={handleInputChange}
              required
              className="form-input"
            >
              <option value="">Select Type</option>
              {bikeTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>

            <label htmlFor="brand">Brand:</label>
            <select
              type="text"
              name="brand"
              value={auction.brand}
              onChange={handleInputChange}
              required
              className="form-input"
            >
              <option value="">Select Brand</option>
              {brands.map((brand, index) => (
                <option key={index} value={brand}>
                  {brand}
                </option>
              ))}
            </select>

            <label htmlFor="model">Model:</label>
            <input
              type="text"
              name="model"
              value={auction.model}
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="Title">Title:</label>
            <input
              type="text"
              name="title"
              value={auction.title}
              onChange={handleInputChange}
              className="form-input"
            />

            <label htmlFor="serial_number">Serial Number:</label>
            <input
              type="text"
              name="serial_number"
              value={auction.serial_number}
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="reserve_requested">Reserve:</label>
            <input
              type="number"
              name="reserve_requested"
              value={auction.reserve_requested}
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="bid_end_time">Bid Ends:</label>
            <input
              type="datetime-local"
              name="bid_end_time"
              value={localBidEndTime} // needs to be local time
              defaultValue={localBidEndTime}
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="condition">Condition:</label>
            <select
              type="text"
              name="condition"
              value={auction.condition}
              onChange={handleInputChange}
              required
              className="form-input"
            >
              <option value="New">New</option>
              <option value="Used">Used</option>
            </select>
            <label htmlFor="description">Description:</label>
            <textarea
              type="text"
              name="description"
              value={auction.description}
              onChange={handleInputChange}
              className="form-input"
              style={{ minHeight: "800px" }}
            />
            <div
              className="button-group"
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "20px 0px",
              }}
            >
              <button
                type="button"
                className="profile-btn"
                onClick={handleUpdateDescription}
                disabled={loading}
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                Update Description
              </button>
            </div>

            <label htmlFor="short_description">Short Description:</label>
            <input
              type="text"
              name="short_description"
              value={auction.short_description}
              onChange={handleInputChange}
              className="form-input"
            />

            <label htmlFor="frame_size" className="form-label">
              Bicycle Size*:
            </label>
            <select
              type="text"
              name="frame_size"
              value={auction.frame_size}
              onChange={handleInputChange}
              required
              className="nice-select"
              disabled={loading}
            >
              <option value="">Select Type</option>
              {frameSizes.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <label htmlFor="wheel_size" className="form-label">
              Wheel Size*:
            </label>
            <select
              type="text"
              name="wheel_size"
              value={auction.wheel_size}
              onChange={handleInputChange}
              required
              className="nice-select"
              disabled={loading}
            >
              <option value="">Select Type</option>
              {wheelSizes.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <label htmlFor="frame_material">Frame Material:</label>
            <select
              type="text"
              name="frame_material"
              value={auction.frame_material}
              onChange={handleInputChange}
              className="form-input"
            >
              <option value="Carbon">Carbon</option>
              <option value="Aluminum">Aluminum</option>
              <option value="Steel">Steel</option>
              <option value="Titanium">Titanium</option>
            </select>
            <label className="form-label">Bicycle Components:</label>
            <input
              type="text"
              name="components"
              value={auction.components}
              placeholder="Please specify build details, component model, suspsension, etc."
              onChange={handleInputChange}
              className="form-input"
            />
            <label className="form-label">Color:</label>
            <input
              type="text"
              name="color"
              value={auction.color}
              placeholder="Please specify frame color"
              onChange={handleInputChange}
              className="form-input"
            />
            <label className="form-label">Mileage:</label>
            <input
              type="text"
              name="miles"
              value={auction.miles}
              placeholder="Please specify approximate mileage (exact if e-bike)"
              onChange={handleInputChange}
              className="form-input"
            />
            <label className="form-label">Weight:</label>
            <input
              type="text"
              name="weight"
              value={auction.weight}
              placeholder="Please specify approximate weight in lbs"
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="year" className="form-label">
              Original Owner?
            </label>
            <select
              type="text"
              name="original_owner"
              value={auction.original_owner}
              onChange={handleInputChange}
              className="form-input"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <label htmlFor="status" className="form-label">
              Status?
            </label>
            <select
              type="text"
              name="status"
              value={auction.status}
              onChange={handleInputChange}
              className="form-input"
            >
              <option value="Submitted">Submitted</option>
              <option value="Accepted">Accepted</option>
              <option value="Live">Live</option>
              <option value="Reserve Not Met">Reserve Not Met</option>
              <option value="Sold">Sold</option>
            </select>

            {/* Existing Images */}
            <label htmlFor="existing_images">Existing Images:</label>
            <div className="filepond-container">
              <FilePond
                files={files}
                onupdatefiles={setFiles}
                onreorderfiles={handleReorderFiles}
                onremovefile={handleFileDelete}
                allowMultiple={true}
                allowReorder={canReorder}
                maxFileSize="5MB"
                credits="false"
                labelMaxFileSizeExceeded="File is too large"
                labelMaxFileSize="Maximum file size is {filesize}"
                server={server}
              />
            </div>

            {/* Other form fields */}
            <div
              className="button-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "40px",
              }}
            >
              <button
                type="submit"
                onClick={handleBack}
                disabled={loading}
                className="profile-btn"
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                Back
              </button>
              <button
                type="submit"
                className="profile-btn"
                disabled={loading}
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Uploading{" "}
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditAuction;
