import React, { useState, useRef, useEffect, useContext } from "react";
import { ListingContext } from "../../components/page/auctionDetails/AuctionDetails";

const CustomImageGallery = ({ originalUrls, resizedUrls, onImagesLoad }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const { setIsHeaderVisible } = useContext(ListingContext);
  const galleryRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    // Reset the loaded images count when new URLs are received
    setLoadedImagesCount(0);
  }, [resizedUrls]);

  const handleImageLoad = () => {
    // Increment the loaded images count
    setLoadedImagesCount((prevCount) => prevCount + 1);

    // Check if all images are loaded
    if (loadedImagesCount + 1 === resizedUrls[400].length) {
      onImagesLoad();
    }
  };

  const handleThumbnailClick = (index) => {
    setSelectedIndex(index);
  };

  const handleImageClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsZoomed(true);
    setIsHeaderVisible(false);
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
  };

  const handleZoomImageClick = (e) => {
    if (e.target.classList.contains("zoom-overlay")) {
      setIsZoomed(false);
      setIsHeaderVisible(true);
      document.documentElement.style.overflow = "auto";
      document.body.style.touchAction = "auto";
    }
  };

  //   const goToPreviousImage = (event) => {
  //     event.preventDefault();
  //     const previousIndex = selectedIndex - 1;
  //     if (previousIndex >= 0) {
  //       setSelectedIndex(previousIndex);
  //     }
  //   };

  //   const goToNextImage = (event) => {
  //     event.preventDefault();
  //     const nextIndex = selectedIndex + 1;
  //     if (nextIndex < resizedUrls[400].length) {
  //       setSelectedIndex(nextIndex);
  //     }
  //   };

  const goToPreviousImage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const previousIndex = selectedIndex - 1;
    if (previousIndex >= 0) {
      setSelectedIndex(previousIndex);
    }
  };

  const goToNextImage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const nextIndex = selectedIndex + 1;
    if (nextIndex < resizedUrls[400].length) {
      setSelectedIndex(nextIndex);
    }
  };

  //   const handleTouchStart = (e) => {
  //     setTouchStart(e.targetTouches[0].clientX);
  //   };

  //   const handleTouchMove = (e) => {
  //     setTouchEnd(e.targetTouches[0].clientX);
  //   };

  //   const handleTouchEnd = () => {
  //     if (touchStart - touchEnd > 150) {
  //       // Swipe left, go to next image, but stop at the last image
  //       const nextIndex =
  //         selectedIndex + 1 < resizedUrls[400].length
  //           ? selectedIndex + 1
  //           : selectedIndex; // Prevent going beyond the last image
  //       setSelectedIndex(nextIndex);
  //     } else if (touchStart - touchEnd < -150) {
  //       // Swipe right, go to previous image, but stop at the first image
  //       const previousIndex =
  //         selectedIndex - 1 >= 0 ? selectedIndex - 1 : selectedIndex; // Prevent going before the first image
  //       setSelectedIndex(previousIndex);
  //     }

  //     // Reset touch positions
  //     setTouchStart(null);
  //     setTouchEnd(null);
  //   };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") {
        goToNextImage(e);
      } else if (e.key === "ArrowLeft") {
        goToPreviousImage(e);
      } else if (e.key === "Escape" && isZoomed) {
        setIsZoomed(false);
        setIsHeaderVisible(true);
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isZoomed, selectedIndex, goToNextImage, goToPreviousImage]);

  const renderThumbnails = () => {
    // Assuming resizedUrls[400] is always defined and its length matches the other sizes.
    // If not, additional checks are needed.
    const baseSizeUrls = resizedUrls[400] || [];

    return baseSizeUrls.map((url400, index) => {
      const url800 = resizedUrls[800]?.[index] || url400; // Fallback to 400px version if not available
      const url1200 = resizedUrls[1200]?.[index] || url800; // Fallback to 800px version if not available

      return (
        <div
          key={index}
          className={`thumbnail ${selectedIndex === index ? "selected" : ""}`}
          onClick={() => handleThumbnailClick(index)}
        >
          <img
            src={url1200} // Use the highest resolution available as the src
            srcSet={`${url400} 400w, ${url800} 800w, ${url1200} 1200w`}
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
            alt={`Thumbnail ${index}`}
            onLoad={handleImageLoad}
          />
        </div>
      );
    });
  };

  const renderMainImage = () => {
    // Fallback to the first available URL if the selected index is out of bounds
    const resized400Url =
      resizedUrls[400]?.[selectedIndex] || resizedUrls[400]?.[0] || "";
    const resized800Url =
      resizedUrls[800]?.[selectedIndex] ||
      resizedUrls[800]?.[0] ||
      resized400Url; // Fallback to 400px version if not available
    const resized1200Url =
      resizedUrls[1200]?.[selectedIndex] ||
      resizedUrls[1200]?.[0] ||
      resized800Url; // Fallback to 800px version if not available

    // Since we always ensure a URL is present, we can directly use them without checking for their existence
    return (
      <div
        className={`main-image ${isZoomed ? "zoomed" : ""}`}
        ref={galleryRef}
        // onTouchStart={isZoomed ? handleTouchStart : null}
        // onTouchMove={isZoomed ? handleTouchMove : null}
        // onTouchEnd={isZoomed ? handleTouchEnd : null}
      >
        <img
          src={resized1200Url} // Use the highest resolution available as the src
          srcSet={`${resized400Url} 400w, ${resized800Url} 800w, ${resized1200Url} 1200w`}
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          alt={`Main Image ${selectedIndex}`}
          onClick={handleImageClick}
        />
        {isZoomed && (
          <div className="image-number">
            Image {selectedIndex + 1} of {resizedUrls[400]?.length || 0}
          </div>
        )}
        {isZoomed && (
          <div className="zoom-overlay" onClick={handleZoomImageClick}>
            <div className="arrow-container left" onClick={goToPreviousImage}>
              &lt;
            </div>
            <div className="arrow-container right" onClick={goToNextImage}>
              &gt;
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="custom-image-gallery">
      {renderMainImage()}
      <div className="thumbnail-container">{renderThumbnails()}</div>
    </div>
  );
};

export default CustomImageGallery;
