import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import FB from "../../data/Firebase";
import Logo from "../../assets/images/bg/BB_Black3.png";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";

function EmailModal(props) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  function validateEmail() {
    if (!email) {
      return "Email is required.";
    }
    // You can add more advanced email validation if needed
    return "";
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");

    const validationError = validateEmail();
    if (validationError) {
      setErrorMessage(validationError);
      setLoading(false);
      return;
    }

    try {
      // Perform the necessary actions to join the email list
      // For example, make an API call to add the email to the list
      // or trigger a form submission to your backend server

      // Simulate a delay for API call (remove this in production)
      //   await new Promise((resolve) => setTimeout(resolve, 2000));
      await FB.addUserToSendGrid(email);
      logEvent(FB.analytics, "User joined email list");

      console.log("Joining email list:", email);
      setEmail("");
      setSuccessMessage(
        "Thank you! Your email has been added to our mailing list."
      );
      console.log("Thank you! Your email has been added to our mailing list.");
      //props.onClose();
    } catch (error) {
      console.error("Error joining email list:", error);
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title">Join our interest list</h5> */}
            <button
              type="button"
              className="close"
              style={{ marginLeft: "auto" }}
              onClick={props.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {errorMessage && <div className="text-danger">{errorMessage}</div>}

            <div className="container">
              <div className="footer-item">
                <Link to={`/`}>
                  <img
                    alt="images"
                    src={Logo}
                    style={{ paddingBottom: "20px" }}
                  />
                </Link>
                <p>
                  Get ahead of the crowd and be the first to know when BidBikes,
                  the ultimate online auction platform for bicycles, goes live!
                  Join our exclusive mailing list today and be among the
                  privileged few to receive early access, exciting updates, and
                  insider information on the upcoming launch.
                </p>

                <form onSubmit={handleFormSubmit}>
                  <div
                    className="input-with-btn d-flex"
                    style={{ marginBottom: "15px", marginTop: "25px" }}
                  >
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <button type="submit" disabled={loading}>
                      {loading ? (
                        <Spinner animation="border" role="status" />
                      ) : (
                        <img alt="images" src={"/images/icons/send-icon.svg"} />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {successMessage && (
              <div className="text-success">{successMessage}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailModal;
