import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import Layout from "./components/App";
import MainLayout from "./components/layout/Layout";
import ScrollToTopOnNavigate from "./ScrollToTopOnNavigate";

import "./index.css";
import Contact from "./components/page/contact/Contact";
import ErrorPage from "./components/page/error/ErrorPage";
import SignUp from "./components/page/signUp/SignUp";
import ResendVerification from "./components/page/signUp/ResendVerification";
import ResetPassword from "./components/page/login/ResetPassword";
import Welcome from "./components/page/signUp/Welcome";
import ActionURL from "./components/page/signUp/ActionURL";
import Faq from "./components/page/faq/Faq";
import Login from "./components/page/login/Login";
import EmailVerificationPage from "./components/page/login/EmailVerificationPage";
import AuctionDetails from "./components/page/auctionDetails/AuctionDetails";
import Dashboard from "./components/page/dashboard/Dashboard";
import Blog from "./components/page/blog/Blog";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import LiveAuction from "./components/page/LiveAuction/LiveAuction";
import HowItWork from "./components/page/HomePage/HowItWorks";
//import HowItWork from "./components/page/howItWork/HowItWork";
import About from "./components/page/about/About";
import Merchant from "./components/page/joinMerchant/Merchant";
import newListing from "./components/page/HomePage/AddNewAuctionListing";
import editListing from "./components/page/HomePage/EditAuctionListing";
import userEditAuction from "./components/page/dashboard/UserEditAuction";
import ReferralInfoDetails from "./components/page/AdminDashboard/ReferralInfoDetails";

import Admin from "./components/page/AdminDashboard/AdminDashboard";
import AdminAuction from "./components/page/AdminDashboard/AdminDashboardAuction";
import AdminReferrals from "./components/page/AdminDashboard/AdminReferrals";
import UserDetails from "./components/page/AdminDashboard/UserDetails";
import AuctionDetailsAdmin from "./components/page/AdminDashboard/AuctionDetails";
import EditUser from "./components/page/AdminDashboard/EditUser";
import EditAuction from "./components/page/AdminDashboard/EditAuction";
import EditUserNotAdmin from "./components/page/dashboard/EditUserNotAdmin";
import ForgotPassword from "./components/page/login/ForgotPassword";
import RegisterToBidPage from "./components/page/dashboard/RegistrationPage";
import TermsOfUsePage from "./components/common/Terms";
import PrivacyPage from "./components/common/Privacy";
import ReferralTermsPage from "./components/common/ReferralTermsPage";

import FB from "./data/Firebase";
import UserEditAuction from "./components/page/dashboard/UserEditAuction";

//Default Warniing Error Hide
//console.log = console.warn = console.error = () => {};

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Root() {
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    FB.auth.onAuthStateChanged((authUser) => {
      if (authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });
  }, []);

  return (
    <>
      <BrowserRouter basename="/">
        <ScrollToTopOnNavigate />
        <Switch>
          <Layout>
            <Route exact path={`/`} component={MainLayout} />
            <Route exact path={`/sell-your-bike`} component={About} />
            <Route exact path={`/new`} component={newListing} />
            <Route exact path={`/edit`} component={editListing} />
            <Route exact path={`/contact`} component={Contact} />
            <Route exact path={`/error`} component={ErrorPage} />
            <Route exact path={`/signup`} component={SignUp} />
            <Route exact path={`/welcome`} component={Welcome} />
            <Route exact path={"/reset-password"} component={ResetPassword} />
            <Route exact path={`/action-url`} component={ActionURL} />
            <Route exact path={"/terms"} component={TermsOfUsePage} />
            <Route exact path={"/privacy"} component={PrivacyPage} />
            <Route
              exact
              path={"/referral-terms"}
              component={ReferralTermsPage}
            />
            <Route
              exact
              path={`/emailverification`}
              component={ResendVerification}
            />
            <Route exact path={`/login`} component={Login} />
            <Route exact path={`/forgot-password`} component={ForgotPassword} />
            <Route path="/verify-email" component={EmailVerificationPage} />
            <Route exact path={`/auction-details`} component={AuctionDetails} />
            <Route path={`/auction-details/:id`} component={AuctionDetails} />

            <Elements stripe={stripePromise}>
              <ProtectedRoute
                exact
                path={`/register`}
                component={RegisterToBidPage}
                user={user}
              />
              <ProtectedRoute
                exact
                path={`/dashboard`}
                component={Dashboard}
                user={user}
              />
            </Elements>

            <ProtectedRoute
              exact
              path={`/admin`}
              component={Admin}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/admin-auction`}
              component={AdminAuction}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/admin-referrals`}
              component={AdminReferrals}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/user/:id`}
              component={UserDetails}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/user_admin/:id/edit`}
              component={EditUser}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/user/:id/edit`}
              component={EditUserNotAdmin}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/auction/:id`}
              component={AuctionDetailsAdmin}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/auction/:id/edit`}
              component={EditAuction}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/auction/:id/useredit`}
              component={userEditAuction}
              user={user}
            />
            <ProtectedRoute
              exact
              path={`/referral-info/:id`}
              component={ReferralInfoDetails}
              user={user}
            />
            <Route exact path={`/blog`} component={Blog} />
            <Route exact path={`/blog-details/:id`} component={BlogDetails} />
            <Route exact path={`/live-auction`} component={LiveAuction} />
            <Route exact path={`/how-works`} component={HowItWork} />
            <Route exact path={`/faq`} component={Faq} />
            <Route exact path={`/join-merchant`} component={Merchant} />
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Root;

function ProtectedRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location.pathname }, // Preserve the original URL
            }}
          />
        );
      }}
    />
  );
}

ReactDOM.render(
  //   <React.StrictMode>
  <Root />,
  //   </React.StrictMode>,
  document.getElementById("root")
);
