import React, { useContext } from "react";
import { Link } from "react-router-dom";
import BlogAuthorAndTag from "./BlogAuthorAndTag";
import BlogComments from "./BlogComments";
import BlogContent from "./BlogContent";
import BlogPostCategory from "./BlogPostCategory";
import BlogRecentPostWidget from "./BlogRecentPostWidget";
import BlogSearchWidget from "./BlogSearchWidget";
import BlogSidebarBanner from "./BlogSidebarBanner";
import BlogSocialLink from "./BlogSocialLink";
import CommentForm from "./CommentForm";
import { Calendar, Tags, Person } from "react-bootstrap-icons";

import { BlogContext } from "./BlogDetails";

function BlogDetailsWrap() {
  const post = useContext(BlogContext);

  return (
    <>
      <div
        className="blog-details-section pb-120"
        style={{ paddingTop: "30px" }}
      >
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-details-single">
              <div className="blog-img">
                <img
                  alt="images"
                  src={post.imageUrl}
                  className="img-fluid wow fadeInDown"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  style={{ borderRadius: "5px" }}
                />
              </div>
              <ul className="blog-meta gap-2">
                <li>
                  <span>
                    <Calendar /> {post.date}
                  </span>
                </li>
                <li>
                  <span>
                    <Tags /> Auction
                  </span>
                </li>
                <li>
                  <span>
                    <Person /> Posted by Admin
                  </span>
                </li>
              </ul>
              {/* <h3 className="blog-title">{post.title}</h3> */}
              <BlogContent />
              {/* <BlogAuthorAndTag/> */}
              {/* <BlogComments/> */}
              {/* <CommentForm/> */}
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="blog-sidebar">
              <BlogSearchWidget/>
              <BlogRecentPostWidget/>
              <BlogPostCategory/>
              <BlogSocialLink/>
              <BlogSidebarBanner/>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BlogDetailsWrap;
