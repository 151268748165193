import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import FB from "../../../data/Firebase";

function Welcome() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEmailVerified = queryParams.get("emailVerified");
  const userId = queryParams.get("userId");

  const sendWelcomeEmail = async () => {
    try {
      setLoading(true);
      await FB.sendWelcomeEmail(userId); // Pass the user ID to the function
      console.log("Welcome email sent successfully.");
      setLoading(false);
    } catch (error) {
      console.error("Error sending welcome email:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEmailVerified === "true" && userId) {
      sendWelcomeEmail();
    }
  }, [isEmailVerified, userId]);

  useEffect(() => {
    if (isEmailVerified === "true" && userId) {
      sendWelcomeEmail();
    }
  }, []);

  const handleLogin = (event) => {
    // setIsChecked(event.target.checked);
    history.push("/login");
  };
  return (
    <div className="signup-section pt-160 pb-120">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div
              className="form-wrapper wow fadeInUp rounded p-4"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <div className="form-title">
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <h3>Welcome to BidBikes!</h3>
                  <p>
                    Thank you for verifying your email. <br />
                    <br />
                    Please log in to get started.
                  </p>
                </div>

                <form className="w-100" onSubmit={handleLogin}>
                  <button
                    type="submit"
                    className="account-btn"
                    style={{ marginTop: "20px", maxWidth: "80%" }}
                  >
                    Login
                  </button>
                </form>
              </div>
              {/* <div className="">
                <h4>Can't find the email?</h4>
                <p>
                  It may be in your spam folder. If you're still having trouble,
                  add "mail@bidbikes.com" to your email contacts.
                </p>
                <p>
                  <Link
                    to="#"
                    onClick={handleResendEmailVerification}
                    className="underline"
                    style={{ textDecoration: "underline", color: "#60BD74" }}
                  >
                    Click here to re-send the verification email.
                  </Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
