import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";

function SignUpWrap() {
  const history = useHistory();
  const [openEye, setOpenEye] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    username: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
  });

  const handleEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1);
    } else {
      setOpenEye(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const signUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Validate if the username is an email address
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.username)) {
        alert(
          "Usernames cannot be email addresses. Please choose a different username."
        );
        setLoading(false);
        return;
      }

      // Check if the username is taken
      const usernameTaken = await FB.isUsernameTaken(formValues.username);
      if (usernameTaken) {
        alert("This username is already taken. Please choose a different one.");
        setLoading(false);
        return;
      }

      const signUpResult = await FB.signUp(formValues);
      if (signUpResult === false) {
        // The sign-up process encountered an error
        console.log("error during signup");
        setErrorMessage("Sign-up process encountered an error.");
        return;
      } else {
        //alert("Please check email for verification link");
        const userEmail = formValues.email;
        history.push("/emailverification", { email: userEmail });
      }
    } catch (error) {
      //   alert(error.message);
      setErrorMessage(error.message); // Set the error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="signup-section pt-160 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title">
                  {/* <h3>Sign Up</h3> */}
                  <p>
                    {" "}
                    Already have an account?{" "}
                    <Link
                      to={`/login`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                      style={{ color: "#60BD74" }}
                    >
                      Sign in here
                    </Link>
                  </p>
                  {/* <p>
                    <Link
                      to={`/emailverification`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                      style={{ color: "#60BD74" }}
                    >
                      Resend Verification Email
                    </Link>
                  </p> */}
                </div>
                <form className="w-100" onSubmit={signUp}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>Username *</label>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          value={formValues.username}
                          onChange={handleChange}
                          placeholder="Username"
                          required
                          title="Please enter your username"
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-inner">
                        <label>Last Name *</label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={formValues.lastName}
                          onChange={handleChange}
                          placeholder="Last Name"
                          required
                          title="Please enter your last name"
                        />
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>Enter Your Email *</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formValues.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                          required
                          title="Please enter your email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>Password *</label>
                        <input
                          type={openEye === 1 ? "password" : "text"}
                          name="password"
                          id="password"
                          value={formValues.password}
                          onChange={handleChange}
                          placeholder="Create a password"
                          required
                          title="Please enter a password"
                        />
                        <i
                          className={
                            openEye === 1
                              ? "bi bi-eye-slash"
                              : "bi bi-eye-slash bi-eye"
                          }
                          onClick={handleEyeIcon}
                          id="togglePassword"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id="html"
                            checked={isChecked}
                            onChange={(event) => handleCheckboxChange(event)}
                          />
                          <label htmlFor="html">
                            I agree to the{" "}
                            <Link to={"#"} style={{ color: "#60BD74" }}>
                              Terms &amp; Policy
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                  <button
                    type="submit"
                    disabled={!isChecked}
                    className="account-btn"
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Create Account"
                    )}
                  </button>
				  {errorMessage && (
                    <div className="error-message" style={{margin: "10px 0px 0px"}}>{errorMessage}</div>
                  )}
                </form>
                {/* <div className="alternate-signup-box">
                  <h6>or signup WITH</h6>
                  <div className="btn-group gap-4">
                    <Link
                      to={"#"}
                      className="eg-btn google-btn d-flex align-items-center"
                    >
                      <i className="bx bxl-google" />
                      <span>signup whit google</span>
                    </Link>
                    <Link
                      to={"#"}
                      className="eg-btn facebook-btn d-flex align-items-center"
                    >
                      <i className="bx bxl-facebook" />
                      signup whit facebook
                    </Link>
                  </div>
                </div> */}
                <div className="form-poicy-area">
                  <p>
                    By clicking the "Create Account" button you agree to
                    BidBikes's <Link to={"/terms"}>Terms &amp; Conditions</Link>{" "}
                    &amp; <Link to={"/privacy"}>Privacy Policy.</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpWrap;
