import React from "react";
import Breadcrumb from "../common/Breadcrumb";

const ReferralTermsPage = () => {
  return (
    <>
      <Breadcrumb
        pageName="Referral Marketing Terms"
        pageTitle="Referral Marketing Terms"
      />
      <div className="terms-section pt-160 pb-120">
        <div className="container">
          <p>
            Effective date: January 1st, 2024
            <br />
            <br />
            Thank you for participating in our Referral Marketing program. By
            participating, you agree to comply with the following terms and
            conditions:
            <br />
            <br />
            <b>
              <u>Referral Program Eligibility</u>
            </b>
            <br />
            <br />
            To be eligible for our Referral Marketing program, you must:
            <ul style={{ "list-style-type": "disc" }}>
              <li>Be a registered user of our platform.</li>
              <li>Comply with our Terms of Use and Privacy Policy.</li>
              <li>Not engage in any fraudulent or misleading activities.</li>
            </ul>
            <br />
            <b>
              <u>Referral Rewards</u>
            </b>
            <br />
            <br />
            When you refer new users to our platform, you may be eligible for
            rewards. The specific rewards, eligibility criteria, and reward
            terms will be outlined in the referral program details provided to
            you at the time of participation. Please note that rewards will be
            $15 USD per successful bike auction referral, provided that the
            auction meets the following criteria:
            <ul style={{ "list-style-type": "disc" }}>
              <li>The bike auction is successful.</li>
              <li>The final bid price is above the reserve price.</li>
            </ul>
            Auctions that do not meet the reserve price, and as a result, the
            bikes do not sell, are not eligible for referral rewards.
            <br />
            <br />
            The payment for successful referrals will be issued no earlier than
            30 days from the closing of the auction.
            <br />
            <br />
            <b>
              <u>Referral Links</u>
            </b>
            <br />
            <br />
            You will be provided with a unique referral code that you can share
            with potential new users. This code is essential for tracking your
            referrals. Please do not share your referral code through spam or
            unsolicited communications.
            <br />
            <br />
            <b>
              <u>Compliance with Laws</u>
            </b>
            <br />
            <br />
            You agree to comply with all applicable laws, regulations, and
            guidelines when participating in our Referral Marketing program.
            This includes, but is not limited to, anti-spam laws and data
            protection regulations.
            <br />
            <br />
            <b>
              <u>Termination</u>
            </b>
            <br />
            <br />
            We reserve the right to terminate your participation in the Referral
            Marketing program at any time if we believe you have violated these
            terms or engaged in fraudulent activities. Termination may result in
            the forfeiture of any earned rewards.
            <br />
            <br />
            <b>
              <u>Changes to Referral Terms</u>
            </b>
            <br />
            <br />
            We may modify these referral terms at any time, and any changes will
            be effective immediately upon posting. It is your responsibility to
            review these terms periodically.
            <br />
            <br />
            <b>
              <u>Contact Us</u>
            </b>
            <br />
            <br />
            If you have any questions or concerns regarding our Referral
            Marketing program or these terms, please contact us at:
            <br />
            <br />
            <ul style={{ "list-style-type": "disc" }}>
              <li>Email: info@bidbikes.com</li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
};

export default ReferralTermsPage;
