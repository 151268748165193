import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import FB from "../../../data/Firebase";
import Breadcrumb from "../../common/Breadcrumb";
// import { FileBarGraph } from "react-bootstrap-icons";

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [selectedValue, setSelectedValue] = useState("users");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState("id"); // Default sorting field is "id"
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order is ascending
  const [sortedUsers, setSortedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  //const db = FB.db;

  //   const migrateData = () => {
  //     const migrateFirestoreToTypesense = FB.functions.httpsCallable(
  //       "migrateFirestoreToTypesense"
  //     );

  //     migrateFirestoreToTypesense()
  //       .then((result) => {
  //         console.log(result.data.message);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   };

  // Function to handle sorting change
  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortBy(value);
    setSortedUsers(sortData(users));
  };

  // Function to toggle sorting order between "asc" and "desc"
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setSortedUsers(sortData(users));
  };

  // Function to sort users or auctions based on the selected field and order
  const sortData = (data) => {
    const sortedData = [...data].sort((a, b) => {
      if (sortBy === "status") {
        // Custom sorting for the "status" field
        // Check if the status field exists in both objects
        if (a.hasOwnProperty(sortBy) && b.hasOwnProperty(sortBy)) {
          if (sortOrder === "asc") {
            return a[sortBy].localeCompare(b[sortBy]);
          } else {
            return b[sortBy].localeCompare(a[sortBy]);
          }
        } else {
          // Handle the case where the status field is missing in one of the objects
          return 0;
        }
      } else {
        // Default sorting using localeCompare for strings (e.g., "id", "title")
        if (a.hasOwnProperty(sortBy) && b.hasOwnProperty(sortBy)) {
          if (sortOrder === "asc") {
            return a[sortBy].localeCompare(b[sortBy]);
          } else {
            return b[sortBy].localeCompare(a[sortBy]);
          }
        } else {
          // Handle the case where the sortBy field is missing in one of the objects
          return 0;
        }
      }
    });
    return sortedData;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        const initialUsers = await FB.getUsers();
        setUsers(initialUsers);
        setSortedUsers(initialUsers);
      } catch (error) {
        // Handle the error
        setError(error.message);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedValue]);

  useEffect(() => {
    setSortedUsers(sortData(users));
  }, [users, sortBy, sortOrder]);

  useEffect(() => {
    setSortedUsers(sortData(filterData(users)));
  }, [users, searchQuery]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    if (value === "") {
      setSortedUsers(users);
    } else {
      setSortedUsers(sortData(filterData(users)));
    }
  };

  // Function to filter users or auctions based on the search query
  const filterData = (data) => {
    return data.filter((item) => {
      return (
        // item.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        // item.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        // item.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.username?.toLowerCase().includes(searchQuery.toLowerCase())
      );

      return false; // Return false for other cases (if any)
    });
  };

  const formatDate = (timestamp) => {
    const date = FB.convertFirestoreTimestampToDate(timestamp);

    if (!date) {
      // Not a recognized timestamp, return a default value
      return "N/A";
    }

    // Use built-in toLocaleDateString to format the date.
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div>
      <Breadcrumb pageName="Dashboard" pageTitle="Dashboard" />
      <div
        style={{
          paddingTop: "65px",
          paddingBottom: "40px",
        }}
        className="container-fluid"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", margin: "0 auto" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <div>
                <div className="button-group">
                  <NavLink
                    to="/admin"
                    activeClassName="active-link"
                    style={{ marginRight: "20px" }}
                  >
                    <button className="profile-btn">Users</button>
                  </NavLink>
                  <NavLink
                    to={`/admin-auction`}
                    activeClassName="active-link"
                    style={{ marginRight: "20px" }}
                  >
                    <button className="profile-btn">Auctions</button>
                  </NavLink>
                  <NavLink
                    to={`/admin-referrals`}
                    activeClassName="active-link"
                    style={{ marginRight: "20px" }}
                  >
                    <button className="profile-btn">Referrals</button>
                  </NavLink>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  {/* ... Other code ... */}
                  {/* <button onClick={migrateData}>Start Migration</button> */}

                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      minWidth: "200px",
                      marginBottom: "10px",
                    }}
                  />
                </div>

                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div style={{ marginRight: "10px" }}>
                    Sort By:
                    <select
                      id="selection"
                      value={sortBy}
                      onChange={handleSortChange}
                      style={{
                        padding: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        minWidth: "100px",
                        marginLeft: "5px",
                      }}
                    >
                      {selectedValue === "users" ? (
                        <>
                          <option value="id">ID</option>
                          <option value="firstName">First Name</option>
                          <option value="lastName">Last Name</option>
                        </>
                      ) : (
                        <>
                          <option value="id">ID</option>
                          <option value="status">Status</option>
                        </>
                      )}

                      {/* Add more fields if needed */}
                    </select>
                  </div>
                  <div className="button-group">
                    Sort Order:
                    <button
                      className="profile-btn"
                      onClick={toggleSortOrder}
                      style={{
                        padding: "2px 5px",
                        minWidth: "75px",
                        marginLeft: "5px",
                      }}
                    >
                      {sortOrder === "asc" ? "Asc" : "Desc"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table style={{ paddingTop: "20px" }}>
          <thead>
            <tr>
              <th>ID</th>
              {/* <th>Username</th> */}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Joined</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.map((user, index) => (
              <tr key={user.id} className="table-row">
                <td>
                  <Link to={`/user/${user.id}`}>{user.id.substring(0, 6)}</Link>
                  {/* <Link to={`/user/${user.id}`}>{user.id}</Link> */}
                </td>
                {/* <td>{user.username}</td> */}
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{formatDate(user.createdAt)}</td>
                <td>
                  <Link to={`/user_admin/${user.id}/edit`}>Edit</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminDashboard;
