import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";

function EditUserNotAdmin(props) {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfo = await FB.getUserInfo(id);
        if (userInfo) {
          setUser(userInfo);
        }
      } catch (error) {
        // Handle the error
        console.error("Error getting user:", error);
      }
    };

    fetchUser();
  }, [id]);

  useEffect(() => {
    // Check if all the required fields are valid
    const isValid =
      user &&
      user.firstName &&
      user.lastName &&
      user.phoneNumber &&
      user.city &&
      user.state &&
      user.zip;

    setIsFormValid(isValid);
  }, [user]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Create a new user object with the 'isValid' field set to true
    const updatedUser = { ...user, isValid: true };

    try {
      await FB.updateUserInfo(id, updatedUser);

      // Redirect the user back to the profile page
      history.push(`/dashboard/`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleBack() {
    history.push("/dashboard");
  }

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const usStates = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Breadcrumb pageName="Edit User" pageTitle="Edit User" />
      <div
        style={{
          paddingTop: "100px",
          paddingBottom: "20px",
        }}
      >
        <div className="form-wrapper">
          <form onSubmit={handleFormSubmit}>
            <div className="form-inner">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="phoneNumber">Phone:</label>
              <input
                type="tel"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                name="city"
                value={user.city}
                onChange={handleInputChange}
              />
            </div>
            {/* <div className="form-inner">
              <label htmlFor="state">State:</label>
              <input
                type="text"
                name="state"
                value={user.state}
                onChange={handleInputChange}
              />
            </div> */}
            <div className="form-inner">
              <label htmlFor="state">State:</label>
              <select
                name="state"
                value={user.state}
                onChange={handleInputChange}
                className="nice-select"
              >
                <option value="">Select State</option>
                {usStates.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-inner">
              <label htmlFor="zip">Zip:</label>
              <input
                type="text"
                name="zip"
                value={user.zip}
                onChange={handleInputChange}
              />
            </div>

            <div
              className="button-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "40px",
              }}
            >
              <button
                type="submit"
                onClick={handleBack}
                className="profile-btn"
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                Back
              </button>
              <button
                type="submit"
                className="profile-btn"
                disabled={!isFormValid}
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Saving{" "}
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditUserNotAdmin;
