import React, { useContext, useState, useEffect } from "react";
//import { UserContext } from "./Dashboard";
import FB from "../../../data/Firebase";
import { useParams } from "react-router-dom"; // Import useParams to access route parameters
import { useHistory } from "react-router-dom";

function ReferralInfoDetails() {
  //const { userDetails } = useContext(UserContext);
  const [auctionData, setAuctionData] = useState([]);
  const [userData, setUserData] = useState([]);
  const { id } = useParams(); // Retrieve the userId from the route parameter
  const history = useHistory();

  useEffect(() => {
    const fetchAuctions = async () => {
      if (id) {
        try {
          const auctions = await FB.fetchAuctionsByUserId(id);
          setAuctionData(auctions);
          const userInfo = await FB.getUserInfo(id);
          setUserData(userInfo);
        } catch (error) {
          console.error("Error fetching auctions:", error);
        }
      }
    };

    fetchAuctions();
  }, [id, FB]);

  function formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      // Remove all non-digit characters from the phone number
      const cleaned = phoneNumber.replace(/\D/g, "");

      // Apply formatting based on the cleaned phone number
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }

      // Return the original phone number if it couldn't be formatted
      return phoneNumber;
    }
  }

  return (
    <div className="tab-pane fade show" style={{ minHeight: "800px" }}>
      <div className="dashboard-referral">
        {/* <div className="table-title-area">
          <h3>Referral Info for User {id}</h3>
        </div> */}
        <div className="form-wrapper">
          <div className="button-group" style={{ marginBottom: "20px", marginTop: "50px" }}>
            <button className="profile-btn" onClick={() => history.goBack()}>
              Back
            </button>{" "}
            {/* Back button */}
          </div>
          <div className="row">
            <p className="para">Account Information</p>
            <table>
              <tbody>
                <tr className="table-row">
                  <td>User ID:</td>
                  <td>{id}</td>
                </tr>
                <tr className="table-row">
                  <td>Username:</td>
                  <td>{userData?.username}</td>
                </tr>
                <tr className="table-row">
                  <td>Code:</td>
                  <td>{userData?.referralCode}</td>
                </tr>
                <tr className="table-row">
                  <td>First Name:</td>
                  <td>{userData?.firstName}</td>
                </tr>
                <tr className="table-row">
                  <td>Last Name:</td>
                  <td>{userData?.lastName}</td>
                </tr>
                <tr className="table-row">
                  <td>Phone:</td>
                  <td>{formatPhoneNumber(userData?.phoneNumber)}</td>
                </tr>
                <tr className="table-row">
                  <td>Email:</td>
                  <td>{userData?.email}</td>
                </tr>
                <tr className="table-row">
                  <td>Location:</td>
                  <td>
                    {userData?.city}, {userData?.state} {userData?.zip}
                  </td>
                </tr>
                {/* Display other user details here */}
              </tbody>
            </table>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <p className="para">Referral Auctions</p>
            <table>
              <thead>
                <tr>
                  <th>Auction</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {auctionData.map((auction, index) => (
                  <tr key={index} className="table-row">
                    <td>
                      <img
                        alt="images"
                        src={auction?.resized_image_urls[400][0]}
                        className="img-fluid"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </td>
                    <td>{auction?.title}</td>
                    <td>{auction?.status}</td>
                    <td>
                      <p>{auction?.createdAt.toDate().toLocaleDateString()}</p>
                      <p>{auction?.createdAt.toDate().toLocaleTimeString()}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralInfoDetails;
