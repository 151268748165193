import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import LiveAuctionWrap from "./LiveAuctionWrapSearch";

function LiveAuction() {
  return (
    <div className="live-auction pb-0">
      <Breadcrumb pageName="Auctions" pageTitle="Auctions" />
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div style={{ paddingTop: "60px" }}></div>
          <LiveAuctionWrap />
        </div>
      </div>
    </div>
  );
}

export default LiveAuction;
