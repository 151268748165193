import React, { useState } from "react";
import FB from "../../data/Firebase";
import { Spinner } from "react-bootstrap";

function ReferrerSignupModal(props) {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSignup() {
    setLoading(true);
    setErrorMessage("");

    if (!acceptTerms) {
      setErrorMessage("Please accept the terms and conditions.");
      setLoading(false);
      return;
    }

    try {
      const signupResponse = await FB.signupAsReferrer();

      if (signupResponse.success) {
        // Close the modal after successful signup
        props.onClose();
      } else {
        console.error("Error signing up as a referrer:", signupResponse.error);
        setErrorMessage(signupResponse.error);
      }
    } catch (error) {
      console.error("Error signing up as a referrer:", error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Sign Up as Referrer</h5>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            <p>
              By signing up as a referrer, you agree to the{" "}
              <span style={{ fontWeight: "bold", color: "#26A96C" }}>
                <a href="/referral-terms">terms and conditions</a>
              </span>
              .
            </p>
            <label>
              <input
                type="checkbox"
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              {"  "}I accept the terms and conditions
            </label>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSignup}
              disabled={loading}
              style={{ margin: "10px 0px", minWidth: "150px" }}
            >
              {loading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Signing Up{" "}
                </>
              ) : (
                "Sign Up as Referrer"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferrerSignupModal;
