import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";
// import EyeSVG from "../../../assets/images/icons/eye.svg";
// import EyeSlashSVG from "../../../assets/images/icons/eye_slash.svg";

function LoginWrap() {
  const history = useHistory();
  const location = useLocation();
  const [openEye, setOpenEye] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [showResendLink, setShowResendLink] = useState(false);
  const [customError, setCustomError] = useState(null); // Add custom error state

  useEffect(() => {
    const unsubscribe = FB.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1);
    } else {
      setOpenEye(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const signIn = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userCredential = await FB.login(
        formValues.email,
        formValues.password
      );
      const user = userCredential.user;
      //console.log(user);
      console.log(location.state);

      if (user.emailVerified) {
        const { from } = location.state || { from: { pathname: "/dashboard" } };

        history.push(from);
      } else {
        setMessage("Please verify your email before logging in.");
        setShowResendLink(true); // To show the resend verification link
      }
    } catch (error) {
      setMessage(error.message);
      setCustomError("Login failed. Please check your email and password.");
      console.error("Firebase login error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = FB.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        history.push("/dashboard"); // Redirect to dashboard if already logged in
      }
    });

    return () => {
      unsubscribe();
    };
  }, [history]);

  const resendVerificationEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      //const user = FB.getCurrentUser(); // Assuming you have a method to get the current logged-in user
      //console.log(user);

      const user = FB.auth.currentUser;
      //console.log(user);

      if (user && !user.emailVerified) {
        await FB.resendVerificationEmail(user.email); // Call the method from your Firebase class
        setMessage("Verification email resent. Please check your inbox.");
      } else if (user.emailVerified) {
        setMessage("Your email is already verified.");
      }
    } catch (error) {
      setMessage(
        "Error resending the verification email. Please try again later."
      );
      setCustomError(
        "Error resending the verification email. Please try again later."
      );
      console.error("Firebase resend verification email error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-section pt-160 pb-120">
        {/* <img
          alt="imges"
          src="assets/images/bg/section-bg.png"
          className="img-fluid section-bg-top"
        />
        <img
          alt="imges"
          src="assets/images/bg/section-bg.png"
          className="img-fluid section-bg-bottom"
        /> */}
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title">
                  {/* <h3>Log In</h3> */}
                  <p>
                    New Member?{" "}
                    <Link
                      to={`/signup`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                      style={{ color: "#60BD74" }}
                    >
                      signup here
                    </Link>
                  </p>
                </div>

                <form className="w-100" onSubmit={signIn}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-inner">
                        <label>Email *</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formValues.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                          required
                          title="Please enter your email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-inner">
                        <label>Password *</label>
                        <input
                          type={openEye === 1 ? "password" : "text"}
                          name="password"
                          id="password"
                          value={formValues.password}
                          onChange={handleChange}
                          placeholder="Enter your password"
                          required
                          title="Please enter a password"
                        />
                        <i
                          className={
                            openEye === 1
                              ? "bi bi-eye-slash"
                              : "bi bi-eye-slash bi-eye"
                          }
                          onClick={handleEyeIcon}
                          id="togglePassword"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <Link
                          to={"#"}
                          className="forgot-pass"
                          style={{ color: "#60BD74" }}
                          onClick={() => history.push("/forgot-password")}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <div>
                        {customError && (
                          <p className="text-danger">{customError}</p>
                        )}{" "}
                        {/* Display custom error message */}
                        {/* {message && <p>{message}</p>} */}
                        {showResendLink && (
                          <button
                            className="account-btn"
                            type="button"
                            onClick={resendVerificationEmail}
                            style={{ marginBottom: "0px" }}
                          >
                            {loading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Resend Verification Email"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {!showResendLink && (
                    <button className="account-btn" type="submit">
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Log in"
                      )}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginWrap;
