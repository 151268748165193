import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import TimeCounter from "../../common/TimeCounter";
import FB from "../../../data/Firebase";

function LiveAuctionCard({ auction, onImagesLoad, watchList }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [watchState, setWatchState] = useState(false);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("");
  const [detailStatus, setDetailStatus] = useState("");

  // Function to check if the auction has a "No Reserve" tag
  const isNoReserve = () => {
    return auction.reserve_requested === 0;
  };

  useEffect(() => {
    if (watchList) {
      if (watchList.includes(auction.id)) {
        setWatchState(true);
      } else {
        setWatchState(false);
      }
    }
  }, [watchList, auction.id]);

  // check user authentication status on mount
  useEffect(() => {
    let isMounted = true;
    FB.auth.onAuthStateChanged((authUser) => {
      if (isMounted && authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
    onImagesLoad();
  };

  useEffect(() => {
    if (auction.id) {
      const unsubscribe = FB.subscribeToStatus(auction.id, (status) => {
        // Handle the status update here
        // console.log("Auction status updated to: ", status);
      });
      return () => unsubscribe();
    }
  }, [auction.id]);

  const handleWatchClick = async (event) => {
    event.preventDefault();
    if (user) {
      try {
        await FB.toggleWatchlist(auction.id, !watchState);
        setWatchState(!watchState);
      } catch (error) {
        alert("Error toggling watchlist: " + error.message);
      }
    } else {
      alert("Please sign in to add to watch list.");
      return;
    }
  };

  const handleClick = async () => {
    if (user) {
      try {
        await FB.incrementViewCount(auction.id);
      } catch (error) {
        alert("Error incrementing view count: " + error.message);
      }
    }
    //window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  }

  // Get the resized image URLs for a specific width
  const getResizedImageUrls = (width) => {
    if (auction.resized_image_urls && auction.resized_image_urls[width]) {
      return auction.resized_image_urls[width];
    } else {
      return [];
    }
  };

  // Generate the srcSet string for all widths
  const generateSrcSet = () => {
    const widths = [400, 800, 1200];
    const srcSetArray = [];

    for (const width of widths) {
      const resizedImageUrls = getResizedImageUrls(width);

      if (resizedImageUrls.length > 0) {
        const srcSetString = resizedImageUrls
          .map((url) => `${url} ${width}w`)
          .join(", ");
        srcSetArray.push(srcSetString);
      }
    }

    return srcSetArray.join(", ");
  };

  return (
    <>
      <Link to={`/auction-details/${auction.id}`} onClick={handleClick}>
        <div
          data-wow-duration="1.5s"
          data-wow-delay="0.2s"
          className={`eg-card auction-card1 wow fadeInDown`}
        >
          <div className="auction-img">
            <img
              src={
                auction.resized_image_urls?.["400"]?.[0] ||
                "path/to/default/image.jpg"
              }
              srcSet={generateSrcSet()}
              alt="Resized"
              sizes="(max-width: 1200px) 100vw, 1200px"
              onLoad={handleImageLoad}
              className="responsive-image"
            />

            <TimeCounter auction={auction} />
            {user && auction?.status === "Live" && (
              <div className="watch-area">
                <div className="watch-emo">
                  <i
                    className={watchState ? "bi bi-star-fill" : "bi bi-star"}
                    onClick={handleWatchClick}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
              </div>
            )}
            {auction.reserve_requested === 0 && (
              <span className="no-reserve-tag">No Reserve</span>
            )}
          </div>
          <div className="auction-content" style={{ margin: "0px" }}>
            <h4>{auction.title}</h4>
            <div className="auction-card-bttm">
              <div className="auction-details">
                <p>{auction.short_description}</p>
                <p style={{ color: "gray" }}>
                  {auction?.city || auction?.bike_city},{" "}
                  {auction?.state || auction?.bike_state}{" "}
                  {auction?.zip || auction?.bike_zip}
                </p>
              </div>
              {/* <div className="share-area">
                <ul className="social-icons d-flex">
                  <li>
                    <Link to={"#"}>
                      <i className="bx bxl-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      <i className="bx bxl-instagram" />
                    </Link>
                  </li>
                </ul>
                <div>
                  <Link to={"#"} className="share-btn">
                    <i className="bx bxs-share-alt" />
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default LiveAuctionCard;
