import React, { useState } from "react";

function FeedbackButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    // phone: "",
    subject: "",
    message: "",
  });

  const toggleFeedbackForm = () => setIsOpen(!isOpen);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendMessageClick = () => {
    const { name, email, phone, subject, message } = formData;
    const emailSubject = encodeURIComponent(subject);
    const emailBody = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nMessage:\n${message}`
    );
    // const emailBody = encodeURIComponent(
    // 	`Name: ${name}\nEmail: ${email}\nPhone: ${phone}\n\nMessage:\n${message}`
    //   );
    const mailtoURL = `mailto:info@bidbikes.com?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoURL;
  };

  return (
    <>
      <button
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "60px",
          height: "60px",
          padding: "0",
          fontSize: "24px",
          lineHeight: "60px",
          textAlign: "center",
          borderRadius: "50%",
          backgroundColor: "#26a96c",
          color: "white",
          border: "none",
          cursor: "pointer",
          zIndex: 1000,
          transition: "transform 0.3s ease",
        }}
        onMouseOver={() => {
          document.getElementById("feedbackBtn").style.transform = "scale(1.1)";
        }}
        onMouseOut={() => {
          document.getElementById("feedbackBtn").style.transform = "scale(1)";
        }}
        onClick={toggleFeedbackForm}
        id="feedbackBtn"
      >
        <i className="bi bi-chat-right-text-fill"></i>
      </button>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            zIndex: 1001,
            width: "90%", // Increased width for mobile
            maxWidth: "500px", // Limits width on larger devices
          }}
        >
          <button
            onClick={toggleFeedbackForm}
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              border: "none",
              background: "transparent",
              cursor: "pointer",
              fontSize: "18px",
              color: "#333",
            }}
          >
            <i className="bi bi-x-lg"></i>
          </button>
          <h2>Submit Your Feedback</h2>
          <form className="faq-form" style={{ width: "100%" }}>
            <div
              className="form-inner"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <input
                type="text"
                placeholder="Your Name:"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                }}
              />
            </div>
            <div
              className="form-inner"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <input
                type="email"
                placeholder="Your Email:"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                }}
              />
            </div>
            {/* <div className="form-inner" style={{ width: '100%', marginBottom: '10px' }}>
                            <input
                                type="phone"
                                placeholder="Your Phone:"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                            />
                        </div> */}
            <div
              className="form-inner"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <input
                type="text"
                placeholder="Subject:"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                }}
              />
            </div>
            <div
              className="form-inner"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <textarea
                placeholder="Message:"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                rows={5}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                }}
              />
            </div>
            <button
              type="button"
              className="form-button"
              onClick={handleSendMessageClick}
              style={{
                display: "block",
                width: "100%",
                backgroundColor: "#26a96c",
                color: "white",
                padding: "10px 0",
                fontSize: "16px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Send Message
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default FeedbackButton;
