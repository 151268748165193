import React, { useEffect, useRef, useState } from "react";
import { Timestamp } from "firebase/firestore";
import { BsClockFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

function Counter({ auction }) {
  const [endDate, setEndDate] = useState(null);
  const [timer, setTimer] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  let interval = useRef();
  const [backgroundStyle, setBackgroundStyle] = useState("#113939");
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false); // New state for spinner control

  useEffect(() => {
    // Check if the auction has ended but status is still 'Live'
    if (remainingSeconds <= 0 && auction.status === "Live") {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [remainingSeconds, auction.status]);

  useEffect(() => {
    setEndDate(auction.bid_end_time);
  }, [auction.bid_end_time]);

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(interval.current);
    };
  }, [endDate]);

  const startTimer = () => {
    const countdownDate = getEndDate(); // your function to get end date
    interval.current = setInterval(() => {
      const now = new Date();
      updateTimer(countdownDate);
    }, 1000);
  };

  const getEndDate = () => {
    let countdownDate;
    if (endDate) {
      const timeStmp = new Timestamp(endDate.seconds, endDate.nanoseconds);
      countdownDate = new Date(timeStmp.toDate());
    } else {
      countdownDate = new Date("Mar 23, 2023, 00:00:00");
    }
    return countdownDate;
  };

  const updateTimer = (countdownDate) => {
    const now = new Date();
    const distance = countdownDate - now;
    const totalSeconds = Math.floor(distance / 1000);
    setRemainingSeconds(totalSeconds); // Update the state

    if (distance < 0) {
      clearInterval(interval.current);
      return;
    } else {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimer({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  const backgroundGradient = () => {
    let totalSeconds =
      timer.days * 24 * 60 * 60 +
      timer.hours * 60 * 60 +
      timer.minutes * 60 +
      timer.seconds;

    let remainingSeconds = Math.max(totalSeconds, 0);

    // 12 hours in seconds
    const twelveHoursInSeconds = 12 * 60 * 60;

    let position = Math.max((1 - remainingSeconds / 120) * 100, 0);

    if (remainingSeconds > twelveHoursInSeconds) {
      return "#113939";
    } else {
      if (remainingSeconds < 120) {
        return `linear-gradient(to left, #113939 ${position}%, #f08700 ${position}%)`;
      } else {
        return "#f08700";
      }
    }
  };

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  }

  return (
    <div
      className={`auction-gallery-timer${
        auction.status === "Live" ? " live" : ""
      }`}
      style={{
        background: backgroundGradient(),
        display: "-ms-grid",
        gridTemplateColumns: showSpinner ? "1fr" : "1fr 1fr",
      }}
    >
      {showSpinner && (
        <div>
          <Spinner animation="border" variant="light" size="sm" />
        </div>
      )}

      {!showSpinner && (
        <>
          {auction.status === "Sold" ? (
            <div className="status mx-2">
              <h5>Sold for {formatCurrency(auction.current_bid)}</h5>
            </div>
          ) : auction.status === "Live" ? (
            <>
              <div className="status-col">
                <h5>
                  <BsClockFill style={{ marginRight: "10px" }} />
                  {timer.days >= 1 ? (
                    <span>{timer.days + 1} days</span>
                  ) : (
                    <span>
                      {timer.hours.toString().padStart(2, "0")}:
                      {timer.minutes.toString().padStart(2, "0")}:
                      {timer.seconds.toString().padStart(2, "0")}
                    </span>
                  )}
                </h5>
              </div>
              <div className="status-col">
                <h5>
                  <FaMoneyBillWave style={{ marginRight: "10px" }} />
                  {auction.current_bid
                    ? formatCurrency(auction.current_bid)
                    : "$0"}
                </h5>
              </div>
            </>
          ) : auction.status === "Reserve Not Met" ? (
            <div className="status mx-2">
              <h5>Bid to {formatCurrency(auction.current_bid)}</h5>
            </div>
          ) : (
            <div className="status mx-2">
              <h5>{auction.status}</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Counter;
