import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
import FB from "../../../data/Firebase";

function AddCardModal(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const CARD_ELEMENT_OPTIONS = {
    // Customize the appearance and behavior of the CardElement
    // For example:
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };

  async function handleFormSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");

    // Example validation (adjust accordingly)
    const cardElement = elements.getElement(CardElement);

    try {
      // Logic for adding card via Stripe and Firebase goes here...
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        // Handle frontend error
        setErrorMessage(error.message);
        setLoading(false);
        return;
      }

      // Use the addCard function from FB class to add the card
      const addCardResult = await FB.addCard(paymentMethod.id);

      if (!addCardResult.success) {
        // Handle error from addCard function
        setErrorMessage(addCardResult.error);
        setLoading(false);
        return;
      }

      props.onClose();
    } catch (error) {
      console.error("Error adding card:", error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add a New Card</h5>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Adding
                  </>
                ) : (
                  "Add Card"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCardModal;
