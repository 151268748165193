import React, { useEffect, useState } from "react";
import FB from "../../../data/Firebase";

function EmailVerificationPage() {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get("oobCode");

    if (oobCode) {
      FB.auth()
        .applyActionCode(oobCode)
        .then(() => {
          setIsVerified(true);
        })
        .catch(() => {
          setIsVerified(false);
        });
    }
  }, []);

  return (
    <>
      <div className="login-section pt-160 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                {isVerified ? (
                  <div>
                    <h1>Thank you!</h1>
                    <p>Your email has been successfully verified.</p>
                  </div>
                ) : (
                  <div>
                    <h1>Oops!</h1>
                    <p>
                      There was an issue verifying your email. Please try again
                      or contact support.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVerificationPage;
