import React, { useEffect, useReducer, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FB from "../../data/Firebase";
import BBLogo from "../../assets/images/bg/BB_Logo4.png";
import { FileX } from "react-bootstrap-icons";

function Header() {
  const history = useHistory();
  const [search, setSearch] = useState();
  const [sidebar, setSidebar] = useState(false);
  const [user, setUser] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const unsubscribe = FB.auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Sticky Menu Area
  //   useEffect(() => {
  //     window.addEventListener("scroll", isSticky);
  //     return () => {
  //       window.removeEventListener("scroll", isSticky);
  //     };
  //   });

//   useEffect(() => {
//     window.addEventListener("scroll", isSticky);
//     document.addEventListener("fullscreenchange", handleFullScreenChange);

//     return () => {
//       window.removeEventListener("scroll", isSticky);
//       document.removeEventListener("fullscreenchange", handleFullScreenChange);
//     };
//   });

  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  /* Method that will fix header after a specific scrollable */
  const scrollTop = () => {
    setSidebar(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //   const isSticky = (e) => {
  //     try {
  //       const header = document.querySelector(".header-area");
  //       const scrollTop = window.scrollY;
  //       scrollTop >= 20
  //         ? header.classList.add("sticky")
  //         : header.classList.remove("sticky");
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

//   const isSticky = () => {
//     const header = document.querySelector(".header-area");
//     const scrollTop = window.scrollY;

//     if (isFullScreen || scrollTop >= 20) {
//       header.classList.add("sticky");
//     } else {
//       header.classList.remove("sticky");
//     }
//   };

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  const searchFullScreen = () => {
    if (search === false || search === 0) {
      setSearch(1);
    } else {
      setSearch(false);
    }
  };

  function handleSignIn() {
    if (user) {
      FB.logout();
      history.push(`/`);
    } else history.push(`/login`);
  }

  function handleSignUp() {
    setSidebar(false);
    history.push(`/signup`);
  }

  const handleIconClick = () => {
    const user = FB.auth.currentUser;
    if (user) {
      setSidebar(false);
      FB.logout();
      history.push(`/`);
    } else {
      // Redirect to login
      setSidebar(false);
      history.push("/login");
    }
  };

  return (
    <>
      <div className={search === 1 ? "mobile-search slide" : "mobile-search"}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>What bicycle are you looking for?</label>
              <input type="text" placeholder="Search Category, Brand, etc." />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div
                className="search-cross-btn style-3"
                onClick={searchFullScreen}
              >
                {/* <i class="bi bi-search me-4"></i> */}
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="header-area style-3" style={{ listStyleType: "none" }}>
        <div className="header-logo">
          <li>
            <Link to={`/`}>
              <img
                alt="logo"
                src={BBLogo}
                style={{ height: "40px", width: "auto", objectFit: "contain" }}
              />
            </Link>
          </li>
        </div>
        <div className={sidebar === 1 ? "main-menu show-menu" : "main-menu"}>
          <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
            <li>
              <div className="mobile-logo-wrap ">
                <Link to={`/`}>
                  <img alt="logo" src={BBLogo} style={{ height: "40px", width: "auto", objectFit: "contain" }} />
                </Link>
              </div>
            </li>
            <div className="menu-close-btn" onClick={handleSidbarMenu}>
              <i className="bi bi-x-lg" />
            </div>
          </div>
          <ul className="menu-list">
            <li>
              <Link to={`/sell-your-bike`} onClick={scrollTop}>
                Sell Your Bike
              </Link>
            </li>
            <li>
              <Link to={`/how-works`} onClick={scrollTop}>
                How BidBikes Works
              </Link>
            </li>
            <li>
              <Link to={`/live-auction`} onClick={scrollTop}>
                Browse Auctions
              </Link>
            </li>
            <li>
              <Link to={`/faq`} onClick={scrollTop}>
                FAQs
              </Link>
            </li>
            {user && (
              <li>
                <Link to={`/dashboard`} onClick={scrollTop}>
                  My Account
                </Link>
              </li>
            )}
          </ul>

          <div
            className="mobile-menu-btn d-lg-none d-block"
            style={{ margin: "0px" }}
          >
            {user ? (
              <button
                className="form-button"
                onClick={handleIconClick}
                style={{
                  margin: "20px 0px 0px 0px",
                  fontSize: "14px",
                  minWidth: "80px",
                }}
              >
                Sign out
              </button>
            ) : (
              <>
                <button
                  className="form-button"
                  onClick={handleSignUp}
                  style={{
                    margin: "20px 20px 0px 0px",
                    fontSize: "14px",
                    minWidth: "80px",
                  }}
                >
                  Sign up
                </button>
                <button
                  className="form-button"
                  onClick={handleIconClick}
                  style={{
                    margin: "20px 20px 20px 0px",
                    fontSize: "14px",
                    minWidth: "80px",
                  }}
                >
                  Sign in
                </button>
              </>
            )}
          </div>
        </div>

        <div className="nav-right d-flex align-items-center flex-row">
          {/* <div
            className="mobile-menu-btn"
            onClick={searchFullScreen}
            style={{ margin: "10px", fontSize: "16px" }}
          >
            <i className="bx bx-search" />
          </div> */}

          <div
            className="mobile-menu-btn d-lg-none d-block"
            style={{ margin: "10px", fontSize: "16px" }}
            onClick={handleSidbarMenu}
          >
            <i className="bx bx-menu" />
          </div>

          <div className="mobile-menu-btn d-none d-lg-flex">
            {user ? (
              <button
                className="form-button"
                onClick={handleIconClick}
                style={{
                  margin: "0px 1px 0px 0px",
                  fontSize: "14px",
                  minWidth: "80px",
                }}
              >
                Sign out
              </button>
            ) : (
              <>
                <button
                  className="btn btn-primary" //"form-button"
                  onClick={handleIconClick}
                  style={{
                    margin: "0px 15px 0px 0px",
                    fontSize: "14px",
                    minWidth: "80px",
                  }}
                >
                  Sign in
                </button>
                <button
                  className="form-button"
                  onClick={handleSignUp}
                  style={{
                    margin: "0px 15px 0px 0px",
                    fontSize: "14px",
                    minWidth: "80px",
                  }}
                >
                  Sign up
                </button>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
