import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import LoginWrap from "./LoginWrap";

function Login() {
  return (
    <>
      <Breadcrumb pageName="Log In" pageTitle="Log In" />
      <LoginWrap />
    </>
  );
}

export default Login;
