import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import FB from "../../../data/Firebase";
import { UserContext } from "./Dashboard";

function ContentOfListings() {
  const [auctions, setAuctions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [auctionsPerPage, setAuctionsPerPage] = useState(10);
  const { user, userDetails } = useContext(UserContext);
  const [pageSize, setPageSize] = useState(10);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchAuctions = async () => {
      if (user) {
        const myAuctions = await FB.getUserAuctions(user.uid);
        setAuctions(myAuctions);
      }
    };
    fetchAuctions();
  }, [user]);

  function formatCurrency(value) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    window.scrollTo(0, 0);
  };

  const handlePrevPageClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const displayedAuctions = auctions.slice(startIndex, endIndex);

  const numPages = Math.ceil(auctions.length / pageSize);
  const pages = [];
  for (let i = 0; i < numPages; i++) {
    pages.push(i);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    // console.log(value);
    setPageSize(value);
  }

  function handleEditClick(event) {}

  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-purchase"
        role="tabpanel"
        aria-labelledby="v-pills-purchase-tab"
        style={{ minHeight: "800px" }}
      >
        {/* table title*/}
        <div className="table-title-area">
          <h3>Listings History</h3>
          <select id="order-category" onChange={handleChange}>
            <option value={10}>Show: Last 10</option>
            <option value={100}>Show: Last 100</option>
          </select>
        </div>
        {/* Button to add a new listing */}
        <div
          className="add-listing-button"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Link to="/new">
            <button className="btn btn-primary">Add New Listing</button>
          </Link>
        </div>
        {/* table */}
        <div className="table-wrapper">
          <table className="">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                {/* <th>Bidding ID</th> */}
                <th>Bid Amount(USD)</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {displayedAuctions.map((auction) => (
                <tr key={auction.id}>
                  <td>
                    <img
                      alt="images"
                      src={auction.resized_image_urls?.['400']?.[0] || 'path/to/default/image.jpg'}
                      className="img-fluid"
                      style={{
                        maxWidth: "100px",
                        width: "100%",
                        maxHeight: "100px",
                        borderRadius: "5px",
                      }}
                    />
                  </td>
                  <td>
                    <Link
                      to={`/auction-details/${auction.id}`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      {auction.year} {auction.brand} {auction.model}
                    </Link>
                  </td>
                  {/* <td data-label="Bidding ID">{auction.biddingId}</td> */}
                  <td>{formatCurrency(auction.current_bid || 0)}</td>
                  <td
                    className={`text-${
                      auction.status === "Reserve Not Met"
                        ? "red"
                        : auction.status === "Sold"
                        ? "green"
                        : "black"
                    }`}
                  >
                    {auction.status}
                    {auction.status === "Accepted" && (
                      <div>
                        <br></br>
                        <Link
                          to={`/auction/${auction.id}/useredit`}
                          className="button-link"
                        >
                          Update
                        </Link>
                      </div>
                    )}
                  </td>
                  {/* <td data-label="Action">
                    <button className="eg-btn action-btn green">
                      <img
                        alt="perchesImage"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/aiction-icon.svg"
                        }
                      />
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination area */}
        <div className="table-pagination">
          <p>
            Showing {startIndex + 1} to{" "}
            {Math.min(startIndex + pageSize, auctions.length)} of{" "}
            {auctions.length} entries
          </p>
          <nav className="pagination-wrap">
            <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
              <li
                className={`page-item${currentPage === 0 ? " disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={handlePrevPageClick}
                  disabled={currentPage === 0}
                >
                  Prev
                </button>
              </li>
              {pages.map((pageIndex) => (
                <li
                  key={pageIndex}
                  className={`page-item${
                    currentPage === pageIndex ? " active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(pageIndex)}
                  >
                    {pageIndex + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item${
                  currentPage === numPages - 1 ? " disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={handleNextPageClick}
                  disabled={currentPage === numPages - 1}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default ContentOfListings;
