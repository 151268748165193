import React from "react";
import { Link } from "react-router-dom";

function WhyCHooseUs() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <div className="choose-us-section" style={{ padding: "60px 0px 40px" }}>
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="section-bg-bottom"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>Why Choose Us</h2>
                {/* <p className="mb-0">
                  Explore on the world's best &amp; largest Bidding marketplace
                  with our beautiful Bidding products. We want to be a part of
                  your smile, success and future growth.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-feature hover-border1 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span className="sn">01</span>
                <div className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    width="71px"
                    height="71px"
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                  >
                    <g id="SVGRepo_iconCarrier">
                      <path d="M15.537,9.612L24.147,1H31v6.853l-8.612,8.61c-0.195,0.195-0.195,0.512,0,0.707 c0.098,0.098,0.226,0.146,0.354,0.146s0.256-0.049,0.354-0.146l8.758-8.756C31.947,8.32,32,8.193,32,8.06V0.5 C32,0.224,31.776,0,31.5,0h-7.56c-0.133,0-0.26,0.053-0.354,0.146L14.83,8.905c-0.195,0.195-0.195,0.512,0,0.707 S15.342,9.807,15.537,9.612z"></path>{" "}
                      <path d="M16.959,28.646c0.095,0.095,0.222,0.146,0.354,0.146c0.037,0,0.074-0.004,0.11-0.012 c0.167-0.038,0.304-0.159,0.361-0.32l3.336-9.382c0.092-0.26-0.043-0.546-0.304-0.639c-0.261-0.094-0.546,0.043-0.639,0.304 l-3.071,8.637l-5.808-5.808l5.59-5.777c0.192-0.198,0.187-0.515-0.012-0.707c-0.199-0.191-0.515-0.188-0.707,0.012l-5.579,5.765 L4.62,14.893l8.638-3.071c0.26-0.093,0.396-0.378,0.304-0.639c-0.093-0.261-0.379-0.396-0.639-0.304L3.54,14.216 c-0.162,0.058-0.283,0.194-0.32,0.361c-0.038,0.167,0.013,0.342,0.134,0.463L16.959,28.646z"></path>{" "}
                      <path d="M0.5,32c0.069,0,0.138-0.014,0.203-0.043l9.645-4.289c0.252-0.112,0.366-0.408,0.254-0.66 c-0.112-0.252-0.408-0.365-0.66-0.254l-8.456,3.76l3.761-8.455c0.112-0.252-0.001-0.548-0.254-0.66 c-0.252-0.112-0.547,0.001-0.66,0.254l-4.29,9.644c-0.084,0.189-0.043,0.41,0.104,0.557C0.242,31.949,0.37,32,0.5,32z"></path>{" "}
                      <path d="M28.596,5.389c0-1.161-0.944-2.105-2.105-2.105s-2.105,0.944-2.105,2.105c0,1.161,0.944,2.105,2.105,2.105 S28.596,6.55,28.596,5.389z M25.385,5.389c0-0.609,0.496-1.105,1.105-1.105s1.105,0.496,1.105,1.105S27.1,6.495,26.491,6.495 S25.385,5.999,25.385,5.389z"></path>{" "}
                    </g>
                  </svg>
                </div>
                <div className="content">
                  <h5>
                    <Link to={`#`}>Maximize your price</Link>
                  </h5>
                  <p className="para">
                    BidBikes connects you with a wide audience of passionate
                    cyclists willing to pay top dollar for quality bikes. List
                    your bike, set your reserve, and watch the bids roll in.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-feature hover-border1 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                <span className="sn">02</span>
                <div className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    width="69px"
                    height="69px"
                    viewBox="0 0 23 32"
                    enableBackground="new 0 0 23 32"
                    fill="#000000"
                  >
                    <g id="SVGRepo_iconCarrier">
                      <path d="M19,14h-8c-0.276,0-0.5,0.224-0.5,0.5S10.724,15,11,15h8c0.276,0,0.5-0.224,0.5-0.5S19.276,14,19,14z"></path>{" "}
                      <path d="M19,19h-8c-0.276,0-0.5,0.224-0.5,0.5S10.724,20,11,20h8c0.276,0,0.5-0.224,0.5-0.5S19.276,19,19,19z"></path>{" "}
                      <path d="M19,24h-8c-0.276,0-0.5,0.224-0.5,0.5S10.724,25,11,25h8c0.276,0,0.5-0.224,0.5-0.5S19.276,24,19,24z"></path>{" "}
                      <path d="M21.5,4H19V3.5C19,2.673,18.327,2,17.5,2H15V0.5C15,0.224,14.776,0,14.5,0h-6C8.224,0,8,0.224,8,0.5V2H5.5 C4.673,2,4,2.673,4,3.5V4H1.5C0.673,4,0,4.673,0,5.5v25C0,31.327,0.673,32,1.5,32h20c0.827,0,1.5-0.673,1.5-1.5v-25 C23,4.673,22.327,4,21.5,4z M5,3.5C5,3.225,5.225,3,5.5,3h3C8.776,3,9,2.776,9,2.5V1h5v1.5C14,2.776,14.224,3,14.5,3h3 C17.775,3,18,3.225,18,3.5v2C18,5.775,17.775,6,17.5,6h-12C5.225,6,5,5.775,5,5.5V3.5z M22,30.5c0,0.275-0.225,0.5-0.5,0.5h-20 C1.225,31,1,30.775,1,30.5v-25C1,5.225,1.225,5,1.5,5H4v0.5C4,6.327,4.673,7,5.5,7h12C18.327,7,19,6.327,19,5.5V5h2.5 C21.775,5,22,5.225,22,5.5V30.5z"></path>{" "}
                      <path d="M8.166,13.605L6.73,15.041l-0.613-0.614c-0.195-0.195-0.512-0.195-0.707,0s-0.195,0.512,0,0.707 l0.967,0.968c0.094,0.094,0.221,0.146,0.354,0.146s0.26-0.053,0.354-0.146l1.789-1.789c0.195-0.195,0.195-0.512,0-0.707 S8.361,13.41,8.166,13.605z"></path>{" "}
                      <path d="M8.166,17.885L6.73,19.32l-0.613-0.614c-0.195-0.195-0.512-0.195-0.707,0s-0.195,0.512,0,0.707 l0.967,0.968c0.094,0.094,0.221,0.146,0.354,0.146s0.26-0.053,0.354-0.146l1.789-1.789c0.195-0.195,0.195-0.512,0-0.707 S8.361,17.689,8.166,17.885z"></path>{" "}
                      <path d="M8.166,23.164L6.73,24.6l-0.613-0.614c-0.195-0.195-0.512-0.195-0.707,0s-0.195,0.512,0,0.707l0.967,0.968 c0.094,0.094,0.221,0.146,0.354,0.146s0.26-0.053,0.354-0.146l1.789-1.789c0.195-0.195,0.195-0.512,0-0.707 S8.361,22.969,8.166,23.164z"></path>{" "}
                    </g>
                  </svg>
                </div>
                <div className="content">
                  <h5>
                    <Link to={"#"}>Easy Listing Process</Link>
                  </h5>
                  <p className="para">
                    Our user-friendly platform makes it a breeze to create a
                    stunning listing for your bike. Add detailed descriptions,
                    and high-quality photos with just a few clicks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-feature hover-border1 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".6s"
              >
                <span className="sn">03</span>
                <div className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    width="95px"
                    height="69px"
                    viewBox="0 0 53 32"
                    enableBackground="new 0 0 53 32"
                  >
                    <path d="M42.5,11.151c-1.189,0-2.327,0.208-3.394,0.574l-0.823-1.971c4.061-1.454,8.543-0.875,12.068,1.605 c0.087,0.062,0.188,0.091,0.287,0.091c0.157,0,0.312-0.074,0.41-0.212c0.159-0.227,0.104-0.538-0.122-0.697 c-3.805-2.676-8.649-3.296-13.029-1.71L34.629,1H39c0.276,0,0.5-0.224,0.5-0.5S39.276,0,39,0h-5.122 c-0.167,0-0.323,0.084-0.416,0.223c-0.093,0.14-0.11,0.315-0.045,0.47L36.468,8H20.055C20.036,8,20.019,8.011,20,8.013V4h2 c0.276,0,0.5-0.224,0.5-0.5S22.276,3,22,3h-5c-0.276,0-0.5,0.224-0.5,0.5S16.724,4,17,4h2v4.996c0,0.019,0.013,0.035,0.015,0.053 l-0.95,1.26C12.464,6.615,5.04,7.32,0.246,12.07c-0.196,0.194-0.197,0.512-0.003,0.707c0.098,0.099,0.226,0.148,0.355,0.148 c0.127,0,0.254-0.048,0.352-0.145c4.442-4.402,11.311-5.067,16.511-1.672l-1.294,1.716c-1.637-1.055-3.579-1.674-5.667-1.674 c-5.79,0-10.5,4.71-10.5,10.5s4.71,10.5,10.5,10.5c5.672,0,10.296-4.523,10.482-10.151h5.989c0,0,0,0,0,0s0,0,0,0 c0.001,0,0.002-0.001,0.003-0.001c0.084-0.001,0.163-0.025,0.235-0.065c0.006-0.003,0.012-0.002,0.018-0.005 c0.05-0.03,0.089-0.071,0.124-0.113c0.005-0.006,0.014-0.009,0.019-0.016l0.004-0.005c0,0,0,0,0-0.001l9.56-12.676l1.242,2.974 C34.54,13.745,32,17.404,32,21.651c0,5.79,4.71,10.5,10.5,10.5s10.5-4.71,10.5-10.5S48.29,11.151,42.5,11.151z M19.532,10.024 L26.09,21h-5.123c-0.19-3.077-1.704-5.796-3.986-7.594L19.532,10.024z M19.967,21h-8.714l5.126-6.796 C18.42,15.818,19.779,18.249,19.967,21z M10.5,31.151c-5.238,0-9.5-4.262-9.5-9.5s4.262-9.5,9.5-9.5 c1.862,0,3.595,0.546,5.062,1.475l-5.712,7.573c-0.114,0.151-0.133,0.354-0.048,0.523C9.887,21.893,10.061,22,10.25,22h9.732 C19.797,27.076,15.621,31.151,10.5,31.151z M27.02,20.606L20.182,9.162L20.304,9h15.469L27.02,20.606z M42.5,31.151 c-5.238,0-9.5-4.262-9.5-9.5c0-3.833,2.286-7.134,5.562-8.635l3.714,8.896c0.08,0.192,0.266,0.308,0.461,0.308 c0.064,0,0.13-0.013,0.193-0.039c0.254-0.105,0.375-0.398,0.269-0.653l-3.708-8.881c0.947-0.317,1.956-0.496,3.008-0.496 c5.238,0,9.5,4.262,9.5,9.5S47.738,31.151,42.5,31.151z"></path>
                  </svg>
                </div>
                <div className="content">
                  <h5>
                    <Link to={`#`}>Dedicated Bike Enthusiasts</Link>
                  </h5>
                  <p className="para">
                    At BidBikes, we're riders ourselves. Our community consists
                    of bike enthusiasts who understand and appreciate the value
                    of premium bikes. Your bike will be in good hands here.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-feature hover-border1 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".8s"
              >
                <span className="sn">04</span>
                <div className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    width="68px"
                    height="68px"
                    viewBox="0 0 30 32"
                    enableBackground="new 0 0 30 32"
                  >
                    <path d="M14.898,8.269c-4.149,0-7.524,3.375-7.524,7.524c0,4.148,3.375,7.523,7.524,7.523 c4.148,0,7.523-3.375,7.523-7.523C22.421,11.644,19.046,8.269,14.898,8.269z M14.898,22.316c-3.597,0-6.524-2.926-6.524-6.523 s2.927-6.524,6.524-6.524s6.523,2.927,6.523,6.524S18.495,22.316,14.898,22.316z"></path>{" "}
                    <path d="M26.239,24.755C28.77,21.795,30,18.87,30,15.809V0.535c0-0.201-0.12-0.382-0.305-0.46 c-0.184-0.077-0.398-0.039-0.542,0.101c-2.001,1.933-4.58,2.998-7.26,2.998c-2.391,0-4.772-0.88-6.531-2.414 c-0.188-0.164-0.469-0.164-0.657,0c-1.758,1.534-4.16,2.414-6.591,2.414c-3.81,0-7.247-3.016-7.281-3.047 c-0.147-0.13-0.358-0.162-0.537-0.083C0.116,0.125,0,0.303,0,0.5v15.309c0,4.603,2.541,8.863,7.349,12.319 c3.589,2.581,7.123,3.795,7.271,3.845C14.672,31.991,14.727,32,14.781,32c0.051,0,0.102-0.008,0.15-0.023 C15.218,31.886,22.005,29.703,26.239,24.755z M14.787,30.971C13.257,30.408,1,25.582,1,15.809V1.532 c1.322,0.965,4.06,2.641,7.113,2.641c2.521,0,5.018-0.865,6.919-2.386c1.901,1.521,4.376,2.386,6.86,2.386 c2.575,0,5.06-0.892,7.107-2.532v14.168c0,2.812-1.152,5.525-3.521,8.295C21.779,28.429,15.825,30.614,14.787,30.971z"></path>{" "}
                    <path d="M17.39,12.993l-3.371,3.805l-1.593-2.057c-0.169-0.219-0.483-0.263-0.701-0.094 c-0.218,0.169-0.258,0.472-0.089,0.691l1.962,2.51C13.69,17.966,13.829,18,13.978,18c0.005,0,0.011,0,0.016,0 c0.143,0,0.279-0.019,0.375-0.127l3.771-4.216c0.183-0.207,0.164-0.523-0.043-0.706C17.891,12.769,17.575,12.787,17.39,12.993z"></path>
                  </svg>
                </div>
                <div className="content">
                  <h5>
                    <Link to={"#"}>Secure Transactions</Link>
                  </h5>
                  <p className="para">
                    We prioritize your safety and security. Our platform ensures
                    secure transactions, protecting both buyers and sellers
                    throughout the process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-feature hover-border1 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
              >
                <span className="sn">05</span>
                <div className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    width="69px"
                    height="69px"
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                  >
                    <path d="M7,17.421c0.104,0,0.209-0.032,0.298-0.1l6.631-4.946l3.023,4.578c0.08,0.121,0.208,0.201,0.352,0.221 c0.143,0.021,0.289-0.025,0.397-0.122L25,10.589V12c0,0.276,0.224,0.5,0.5,0.5S26,12.276,26,12V9.5C26,9.224,25.776,9,25.5,9H23 c-0.276,0-0.5,0.224-0.5,0.5S22.724,10,23,10h1.157l-6.687,5.922l-2.997-4.54c-0.076-0.115-0.196-0.193-0.332-0.217 c-0.134-0.024-0.275,0.009-0.385,0.092l-7.055,5.264c-0.222,0.165-0.267,0.479-0.102,0.699C6.697,17.352,6.848,17.421,7,17.421z"></path>
                    <path d="M0,4.5C0,5.879,1.122,7,2.5,7H3v16H2c-0.276,0-0.5,0.224-0.5,0.5S1.724,24,2,24h13v2.166L3.308,31.038 c-0.255,0.106-0.375,0.399-0.269,0.654c0.107,0.256,0.399,0.372,0.654,0.27L15,27.25V30c0,0.276,0.224,0.5,0.5,0.5S16,30.276,16,30 v-2.772l12.32,4.739C28.379,31.989,28.44,32,28.5,32c0.201,0,0.39-0.122,0.467-0.32c0.099-0.258-0.03-0.547-0.287-0.646L16,26.156 V24h14c0.276,0,0.5-0.224,0.5-0.5S30.276,23,30,23h-1V7h0.5C30.878,7,32,5.879,32,4.5S30.878,2,29.5,2H16V1 c0-0.276-0.224-0.5-0.5-0.5S15,0.724,15,1v1H2.5C1.122,2,0,3.121,0,4.5z M28,23H4V7h24V23z M2.5,3h27C30.327,3,31,3.673,31,4.5 S30.327,6,29.5,6h-27C1.673,6,1,5.327,1,4.5S1.673,3,2.5,3z"></path>
                  </svg>
                </div>
                <div className="content">
                  <h5>
                    <Link to={`#`}>Competitive Bidding</Link>
                  </h5>
                  <p className="para">
                    Let buyers compete for your bike! Our auction-style format
                    encourages healthy competition, driving up the value of your
                    listing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-feature hover-border1 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1.2s"
              >
                <span className="sn">06</span>
                <div className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    width="68px"
                    height="68px"
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                  >
                    <path d="M4.52,24.536c0.817,0,1.48-0.667,1.48-1.488v-8.022c0-0.932-0.892-1.662-1.811-1.451 c-2.504,0.563-4.185,2.759-4.185,5.462c0,2.704,1.682,4.899,4.186,5.463C4.299,24.524,4.41,24.536,4.52,24.536z M1.005,19.036 c0-2.225,1.369-4.027,3.407-4.487c0.036-0.008,0.071-0.012,0.108-0.012c0.266,0,0.48,0.219,0.48,0.488v8.022 c0,0.307-0.295,0.538-0.59,0.477C2.372,23.064,1.005,21.262,1.005,19.036z"></path>{" "}
                    <path d="M31.995,19.036c0-2.704-1.68-4.899-4.184-5.462C26.89,13.36,26,14.093,26,15.025v8.022 c0,0.821,0.663,1.488,1.48,1.488c0.11,0,0.22-0.012,0.33-0.037C30.314,23.936,31.995,21.74,31.995,19.036z M27.59,23.524 c-0.295,0.062-0.59-0.17-0.59-0.477v-8.022c0-0.27,0.214-0.488,0.48-0.488c0.037,0,0.073,0.004,0.11,0.013 c2.037,0.458,3.407,2.261,3.407,4.486S29.628,23.064,27.59,23.524z"></path>{" "}
                    <path d="M29.495,26.925c-0.276,0-0.5,0.261-0.5,0.538c0,0.869-0.69,1.538-1.641,1.538h-4.433 c-0.261-1-1.247-1.962-2.427-1.962c-1.379,0-2.5,1.102-2.5,2.481s1.121,2.528,2.5,2.528c1.233,0,2.254-1.047,2.457-2.047h4.402 c1.506,0,2.641-1.113,2.641-2.538C29.995,27.186,29.771,26.925,29.495,26.925z M20.495,31c-0.827,0-1.5-0.673-1.5-1.5 s0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5S21.322,31,20.495,31z"></path>{" "}
                    <path d="M16,0C9.935,0,5,4.71,5,10.5C5,10.776,5.224,11,5.5,11S6,10.776,6,10.5C6,5.262,10.486,1,16,1 s10,4.262,10,9.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5C27,4.71,22.065,0,16,0z"></path>{" "}
                  </svg>
                </div>
                <div className="content">
                  <h5>
                    <Link to={"#"}>Seller Support</Link>
                  </h5>
                  <p className="para">
                    Have questions or need assistance? Our support team is here
                    to help you every step of the way, from listing creation to
                    closing the deal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "30px 0px 50px",
        }}
      >
        <Link
          to="/sell-your-bike"
          onClick={scrollTop}
          style={{ maxWidth: "300px", width: "300px" }}
        >
          <button className="form-button">Sell Your Bike</button>
        </Link>
      </div>
    </>
  );
}

export default WhyCHooseUs;
