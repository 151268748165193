import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FB from "../../../data/Firebase";

function FaqWrap() {
  const [faqData, setFaqData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      //   const topics = ["buyer", "seller", "signin", "shipping"];
      const topics = ["buyer", "seller", "shipping"];
      const data = {};

      for (const topic of topics) {
        const faqData = await FB.getFAQ(topic);
        data[topic] = faqData;
      }

      setFaqData(data);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  }

  return (
    <>
      <div className="faq-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center gy-4">
            <div className="col-lg-4 col-md-12 order-lg-1 order-2">
              <div
                className="faq-form-area wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <h5>Ask us a question</h5>
                <p className="para">Required fields are marked *</p>
                <form className="faq-form">
                  <div className="form-inner">
                    <label>Your Full Name *</label>
                    <input type="text" placeholder="Enter your name" />
                  </div>
                  <div className="form-inner">
                    <label>Your Email *</label>
                    <input type="text" placeholder="Enter your email" />
                  </div>
                  <div className="form-inner">
                    <label>Subject *</label>
                    <input type="text" placeholder="Subject" />
                  </div>
                  <div className="form-inner">
                    <label>Your Message *</label>
                    <textarea
                      placeholder="Your message"
                      rows={5}
                      defaultValue={""}
                    />
                  </div>
                  <Link to={"#"} className="eg-btn btn--primary btn--md mt-1">
                    Send Now
                  </Link>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 text-center order-lg-2 order-1">
              {Object.entries(faqData).map(([topic, faqItems]) => (
                <div
                  key={topic}
                  className="faq-wrap wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                >
                  <h5 className="section-title3">{`${topic
                    .charAt(0)
                    .toUpperCase()}${topic.slice(1)} FAQs`}</h5>
                  <div className="accordion">
                    {faqItems.map((faqItem) => (
                      <div className="accordion-item" key={faqItem.id}>
                        <h2
                          className="accordion-header"
                          id={`heading-${faqItem.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${faqItem.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${faqItem.id}`}
                          >
                            {faqItem.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse-${faqItem.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading-${faqItem.id}`}
                          data-bs-parent=".accordion"
                        >
                          <div className="accordion-body">{faqItem.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqWrap;
