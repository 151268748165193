import React, { useState, useEffect, createContext } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import DashbordWrap from "./DashboardWrap";
import FB from "../../../data/Firebase";

export const UserContext = createContext(null);

function Dashboard() {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    // Ensure Firebase is initialized
    if (!FB || !FB.auth) {
      console.error("Firebase is not initialized");
      return;
    }

    const unsubscribe = FB.auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && firebaseUser.emailVerified) {
        try {
          const user1 = {
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            firstName: firebaseUser.firstName || "",
          };
          setUser(user1);

          // Fetch user details from Firebase
          const details = await FB.getUserInfo(user1.uid);
          // Fetch referral data if available
          const referralData = await FB.getUserReferralInfoById(user1.uid);

          // Combine user details with referral data
          const combinedDetails = {
            ...details,
            referrals: referralData,
          };

          setUserDetails(combinedDetails);
        } catch (error) {
          console.error("Error fetching user details or referral data:", error);
          // Handle the error appropriately
          // e.g., show an error message, log out the user, etc.
        }
      } else {
        setUser(null);
        setUserDetails(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []); // Keep the dependency array empty if there are no external dependencies

  // Method to update userDetails in the context
  const updateUserDetails = async () => {
    if (user && user.uid) {
      try {
        const details = await FB.getUserInfo(user.uid);
        const referralData = await FB.getUserReferralInfoById(user.uid);
        const combinedDetails = { ...details, referrals: referralData };

        setUserDetails(combinedDetails);
      } catch (error) {
        console.error("Error updating user details: ", error);
      }
    }
  };

  return (
    <UserContext.Provider value={{ user, userDetails, updateUserDetails }}>
      <Breadcrumb pageName="Dashboard" pageTitle="Dashboard" />
      <DashbordWrap />
    </UserContext.Provider>
  );
}

export default Dashboard;
