import React from "react";
import { useState, useEffect } from "react";
// import Footer from "../common/Footer";
// import Header from "../common/Header";
//import Header3 from "../page/HomePage/Header";
import HomePage from "../page/HomePage/HomePage";
import Preloader from "./Preloader";

function Layout() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1); //3000
  }, []);

  return (
    <>
      <>
        {loading ? (
          <Preloader styles="preloader style-3" />
        ) : (
          <>
            {/* <Header3 /> */}
            <HomePage />
            {/* <Footer /> */}
          </>
        )}
      </>
    </>
  );
}

export default Layout;
