import React, { useState } from "react";

function ContactWrapper() {
  // Initialize form state with default values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  // Function to handle changes in form fields
  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  // Function to handle the "Send Message" button click
  function handleSendMessageClick() {
    // Use the formData object to construct the email
    const { name, email, phone, subject, message } = formData;

    // Construct the email URL
    const emailSubject = encodeURIComponent(subject);
    const emailBody = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\n\nMessage:\n${message}`
    );
    const mailtoURL = `mailto:info@bidbikes.com?subject=${emailSubject}&body=${emailBody}`;

    // Open the default email client
    window.location.href = mailtoURL;
  }

  return (
    <>
      <div className="contact-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row pb-120 mb-70 g-4 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="bi bi-geo-alt" />
                </div>
                <div className="text">
                  <h4>Mail</h4>
                  <p>
                    5760 Lindero Canyon Rd. #1042 Westlake Village, CA 91362
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                <div className="icon">
                  <i className="bx bx-phone-call" />
                </div>
                <div className="text">
                  <h4>Phone</h4>
                  <a href="tel:+880171-770000">+1 (805) 410-4305</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".6s"
              >
                <div className="icon">
                  <i className="bx bx-envelope" />
                </div>
                <div className="text">
                  <h4>Email</h4>
                  <a href="mailto:info@example.com">info@bidbikes.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-lg-6">
              <div
                className="form-wrapper wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title2">
                  <h3>Get in Touch</h3>
                  <p className="para">
                    Feel free to reach out with any topic, question or general
                    inquiry you may have.
                  </p>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <input
                          type="text"
                          placeholder="Your Name:"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <input
                          type="email"
                          placeholder="Your Email:"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <input
                          type="phone"
                          placeholder="Your Phone:"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <input
                          type="text"
                          placeholder="Subject:"
                          name="subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <textarea
                          type="text"
                          placeholder="Message:"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          rows={12}
                          defaultValue={""}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <button
                        type="button" // Use "button" type to prevent form submission
                        className="form-button"
                        onClick={handleSendMessageClick} // Call the handleSendMessageClick function
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div
                className="map-area wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                <iframe
                  title="google map"
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.7364295498833!2d-118.79804562255698!3d34.15308471239586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e8246a02c0e60d%3A0x8e34d49ec5b47ed2!2s5760%20Lindero%20Canyon%20Rd%20%231042%2C%20Westlake%20Village%2C%20CA%2091362!5e0!3m2!1sen!2sus!4v1688778253264!5m2!1sen!2sus"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactWrapper;
