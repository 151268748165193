import { initializeApp, getApp, getApps } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  confirmPasswordReset,
  applyActionCode,
} from "firebase/auth";
import {
  getFirestore,
  Timestamp,
  increment,
  FieldPath,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  limit,
  runTransaction,
  documentId,
  arrayUnion,
} from "firebase/firestore";
import {
  getStorage,
  deleteObject,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

class Firebase {
  //BidBikes
  constructor() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (getApps().length === 0) {
      this.app = initializeApp(firebaseConfig);
      this.analytics = getAnalytics(this.app);
    } else {
      this.app = getApp();
    }

    this.auth = getAuth(this.app);
    this.db = getFirestore(this.app);
    this.store = getStorage(this.app);
    this.functions = getFunctions(this.app);
  }

  // ************************************************************

  async addFileToAuction(auctionId, fileInfo) {
    const auctionRef = doc(this.db, "auctions", auctionId);

    try {
      await runTransaction(this.db, async (transaction) => {
        const docSnap = await transaction.get(auctionRef);

        if (!docSnap.exists()) {
          throw new Error("Auction does not exist");
        }

        let auctionData = docSnap.data();
        // let images = auctionData.images || [];
        // images.push(fileInfo);

        // Extract the UUID from the fileInfo.path
        const pathSegments = fileInfo.path.split("/");
        const imageUUID = pathSegments[pathSegments.length - 1];

        // Ensure imageOrder is treated as an array and append the new image's UUID
        let imageOrder = Array.isArray(auctionData.imageOrder)
          ? auctionData.imageOrder.slice()
          : [];
        imageOrder.push(imageUUID);

        // Prepare updated data for transaction
        //const updatedData = { images, imageOrder };generateAuctionDescription
        const updatedData = { imageOrder };

        // Set the updated document data within the transaction
        transaction.set(auctionRef, updatedData, { merge: true });
      });

      console.log(
        "Image info and order updated in auction successfully within a transaction."
      );
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  }

  async removeFileFromAuction(auctionId, filePath) {
    const uuid = filePath.split("/").pop(); // Extracts UUID from the filePath

    try {
      // Delete the original file from Firebase Storage
      const originalFileRef = storageRef(this.store, filePath);
      await deleteObject(originalFileRef);
      console.log("Original file deleted from storage successfully");

      // Define the sizes array for your resized images
      const sizes = [400, 800, 1200];

      // Delete resized images from Firebase Storage
      for (const size of sizes) {
        const resizedFilePath = `${filePath}_resized_${size}`;
        const resizedFileRef = storageRef(this.store, resizedFilePath);
        await deleteObject(resizedFileRef);
      }
      console.log("Resized files deleted from storage successfully");

      const auctionRef = doc(this.db, "auctions", auctionId);
      const docSnap = await getDoc(auctionRef);

      if (!docSnap.exists()) {
        throw new Error("Auction document does not exist");
      }

      const auctionData = docSnap.data();
      let images = auctionData.images || [];
      let imageOrder = auctionData.imageOrder || [];
      let resizedImageUrls = auctionData.resized_image_urls || {};

      // Ensure imageOrder is an array
      if (!Array.isArray(imageOrder)) {
        console.error("imageOrder is not an array:", imageOrder);
        imageOrder = [];
      }

      // Filter the images and imageOrder array to remove the image with the matching UUID
      images = images.filter((image) => !image.path.endsWith(uuid));
      imageOrder = imageOrder.filter((imageUUID) => imageUUID !== uuid);

      // Remove the URLs of the resized images from the resized_image_urls field
      for (const size of sizes) {
        if (resizedImageUrls[size]) {
          resizedImageUrls[size] = resizedImageUrls[size].filter(
            (url) => !url.includes(uuid)
          );
        }
      }

      await updateDoc(auctionRef, {
        images,
        imageOrder,
        resized_image_urls: resizedImageUrls,
      });

      console.log(
        "Image references, order, and resized URLs updated in Firestore successfully"
      );
    } catch (error) {
      console.error(
        "Error removing file and resized images from auction:",
        error
      );
    }
  }

  async fetchFilesForAuction(auctionId) {
    const auctionRef = doc(this.db, "auctions", auctionId);

    try {
      const docSnap = await getDoc(auctionRef);
      if (!docSnap.exists()) {
        console.log("No such auction!");
        return [];
      }

      const auctionData = docSnap.data();
      const { imageOrder } = auctionData;

      if (imageOrder && imageOrder.length > 0) {
        // Construct URLs based on the order specified in imageOrder
        const orderedImages = await Promise.all(
          imageOrder.map(async (uuid) => {
            const imagePath = `Auctions/${auctionId}/${uuid}`;
            // console.log(imagePath);

            // Here you would construct the URL. This example uses a hypothetical function that you would need to implement.
            // For Firebase Storage, you would typically use `getDownloadURL()` with a reference to the file.
            const url = await this.getDownloadURLFromPath(imagePath);
            return { path: imagePath, url };
          })
        );

        return orderedImages;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching auction images:", error);
      return [];
    }
  }

  // Method to upload a file
  uploadFile(path, file) {
    const fileRef = storageRef(this.store, path);
    return uploadBytesResumable(fileRef, file);
  }

  // Method to get a file's download URL
  async getFileDownloadURL(path) {
    // const fileRef = storageRef(this.store, path);
    // return getDownloadURL(fileRef);
    const encodedPath = encodeURIComponent(path); // Ensure the path is URL-safe
    const bucketName = process.env.REACT_APP_STORAGE_BUCKET; // Your Firebase Storage bucket name
    return `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedPath}?alt=media`;
  }

  async getDownloadURLFromPath(imagePath) {
    //const initFileRef = storageRef(this.store, imagePath); // Assume 'storage' is your initialized Firebase Storage service
    try {
      const encodedPath = encodeURIComponent(imagePath); // Ensure the path is URL-safe
      const bucketName = process.env.REACT_APP_STORAGE_BUCKET; // Your Firebase Storage bucket name
      return `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedPath}?alt=media`;
      //return await getDownloadURL(initFileRef);
    } catch (error) {
      console.error("Error getting download URL:", error);
      return null; // Handle the error or return a default image path
    }
  }

  async updateFileOrder(auctionId, newOrderData) {
    const auctionRef = doc(this.db, "auctions", auctionId);

    try {
      await runTransaction(this.db, async (transaction) => {
        const auctionDoc = await transaction.get(auctionRef);
        if (!auctionDoc.exists()) {
          throw new Error("Document does not exist");
        }

        // Extract UUIDs from the paths
        const newImageOrder = newOrderData.map(({ path }) => {
          const pathSegments = path.split("/");
          return pathSegments[pathSegments.length - 1]; // Extract UUID
        });

        // Update the document with the new order
        transaction.update(auctionRef, { imageOrder: newImageOrder });
      });

      console.log("Image order updated successfully");
    } catch (error) {
      console.error("Error updating file order: ", error);
    }
  }

  async updateImageOrderAndUrls(auctionId, newOrderData) {
    const auctionRef = doc(this.db, "auctions", auctionId);

    try {
      await runTransaction(this.db, async (transaction) => {
        const auctionDoc = await transaction.get(auctionRef);
        if (!auctionDoc.exists()) {
          throw new Error("Document does not exist");
        }

        const auctionData = auctionDoc.data();
        // Extract 'resized_image_urls' directly from the document data
        const resized_image_urls = auctionData.resized_image_urls || {};

        // Extract UUIDs from the paths for the new image order
        const newImageOrder = newOrderData.map(({ path }) => {
          const pathSegments = path.split("/");
          return pathSegments[pathSegments.length - 1]; // Extract UUID
        });

        // Reorder resized_image_urls based on the new image order
        const newResizedImageUrls = {};
        Object.keys(resized_image_urls).forEach((size) => {
          newResizedImageUrls[size] = newImageOrder
            .map((uuid) =>
              resized_image_urls[size].find((url) => url.includes(uuid))
            )
            .filter((url) => url); // Exclude undefined entries if any UUIDs don't match
        });

        // Update the document with the new image order and reordered URLs
        transaction.update(auctionRef, {
          imageOrder: newImageOrder,
          resized_image_urls: newResizedImageUrls,
        });
      });

      console.log("Image order and URLs updated successfully");
    } catch (error) {
      console.error("Error updating image order and URLs: ", error);
    }
  }

  // ************************************************************

  async login(email, password) {
    try {
      await setPersistence(this.auth, browserLocalPersistence);
      return await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      // Handle or throw error
      throw error;
    }
  }

  logout() {
    return this.auth.signOut();
  }

  async sendPasswordResetEmail(email) {
    try {
      const sendPasswordResetEmail = httpsCallable(
        this.functions,
        "sendPasswordResetEmail"
      );
      const response = await sendPasswordResetEmail({ email }); // Replace with the user's email
      if (response.data.success) {
        console.log("Password reset email sent successfully.");
        return { success: true };
      } else {
        console.error("Failed to send password reset email.");
        return {
          success: false,
          error: "Failed to send password reset email.",
        };
      }
    } catch (error) {
      console.error("Error calling sendPasswordResetEmail function:", error);
      return { success: false, error: error.message };
    }
  }

  // Method to reset password
  async resetPassword(oobCode, newPassword) {
    try {
      await confirmPasswordReset(this.auth, oobCode, newPassword);
      // Additional logic or return statement if needed
    } catch (error) {
      throw error; // or handle it as per your design
    }
  }

  async verifyEmail(oobCode) {
    try {
      await applyActionCode(this.auth, oobCode);
      // Additional logic or a return statement if needed
      // e.g., return a success message or status
    } catch (error) {
      // Handle or throw the error as per your design
      throw error;
    }
  }
  async sendWelcomeEmail(userId) {
    try {
      const sendWelcomeEmail = httpsCallable(
        this.functions,
        "sendWelcomeEmail"
      );
      await sendWelcomeEmail({ userId: userId }); // Pass the user ID as an argument
      console.log("Welcome email sent successfully.");
    } catch (error) {
      console.error("Error sending welcome email:", error);
      throw error;
    }
  }

  async addCard(paymentMethodId) {
    try {
      const addCardFunction = httpsCallable(this.functions, "addNewCard");
      const response = await addCardFunction({
        paymentMethodId: paymentMethodId,
      });

      if (response.data.error) {
        // Handle the case where there is an error from the Firebase callable function
        return { success: false, error: response.data.error };
      }

      // Handle success
      return { success: true };
    } catch (error) {
      // Handle other errors
      console.error("Error adding card:", error);
      return { success: false, error: error.message };
    }
  }

  async deleteSavedCard(cardId) {
    try {
      const response = await httpsCallable(
        this.functions,
        "deleteSavedCard"
      )({
        cardId,
      });

      if (response.data.error) {
        // Handle the case where there is an error from the Firebase callable function
        return { success: false, error: response.data.error };
      }

      // Handle success
      return { success: true };
    } catch (error) {
      // Handle other errors
      console.error("Error deleting card:", error);
      return { success: false, error: error.message };
    }
  }

  async signupAsReferrer() {
    try {
      const generateUserCode = httpsCallable(
        this.functions,
        "generateUserCode"
      );
      const result = await generateUserCode();

      if (result.data && result.data.userCode) {
        console.log("User code generated:", result.data.userCode);
        // You can do additional handling here if needed
      } else {
        console.error("No user code received from the Cloud Function.");
        return { success: false, error: "Failed to sign up as a referrer." };
      }

      return { success: true };
    } catch (error) {
      console.error("Error signing up as a referrer:", error);
      return { success: false, error: error.message };
    }
  }

  async addUserToSendGrid(email) {
    const addUserToContactList = httpsCallable(
      this.functions,
      "addUserToContactListFromForm"
    );
    try {
      const result = await addUserToContactList({
        email: email,
      });
      console.log("User added to contact list successfully");
      // Handle success
    } catch (error) {
      console.error("Error adding user to contact list:", error);
      // Handle error
    }
  }

  async createPrivateMessages(message) {
    const createPrivateMessage = httpsCallable(
      this.functions,
      "createPrivateMessage"
    );

    try {
      await createPrivateMessage(message);
    } catch (error) {
      throw new Error("Error creating private message: " + error.message);
    }
  }

  async getReferralAuctionIds(userId) {
    const db = getFirestore(this.app);
    const userRef = doc(db, "users", userId);
    const referralCollectionRef = collection(userRef, "referrals");

    const snapshot = await getDocs(referralCollectionRef);

    const auctionIds = snapshot.docs.map((doc) => doc.data().auctionId);
    return auctionIds;
  }

  //   async validateReferralCode(code) {
  //     try {
  //       const validateReferralCodeFunction = httpsCallable(
  //         this.functions,
  //         "validateReferralCode"
  //       );
  //       const response = await validateReferralCodeFunction({
  //         code: code,
  //       });

  //       // Handle the response as needed
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error validating referral code:", error);
  //       return { error: error.message };
  //     }
  //   }

  async validateReferralCode(code) {
    try {
      const normalizedCode = code.toUpperCase(); // Match the transformation in the Cloud Function
      const validateReferralCodeFunction = httpsCallable(
        this.functions,
        "validateReferralCode"
      );
      const response = await validateReferralCodeFunction({
        code: normalizedCode,
      });

      return response.data;
    } catch (error) {
      console.error("Error validating referral code:", error);
      return { error: error.message };
    }
  }

  async fetchSizes(bikeType, sizeType) {
    try {
      const docRef = doc(this.db, sizeType, bikeType);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const sizes = docSnap.data().size;
        return sizes;
      } else {
        console.log(
          `No such document for ${sizeType} and bikeType ${bikeType}!`
        );
        return [];
      }
    } catch (error) {
      console.log(
        `Error getting ${sizeType} data for bikeType ${bikeType}:`,
        error
      );
      return [];
    }
  }

  async fetchData(collectionName, documentName) {
    try {
      const docRef = doc(this.db, collectionName, documentName);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const dataArray = docSnap.data()[`${collectionName}Array`];
        return dataArray;
      } else {
        console.log(`No such document for ${collectionName}!`);
        return [];
      }
    } catch (error) {
      console.log(`Error getting ${collectionName} data:`, error);
      return [];
    }
  }

  async generateAuctionDescription(auctionId) {
    try {
      const generateDescription = httpsCallable(
        this.functions,
        "generateAuctionDescription"
      );
      const result = await generateDescription({ auctionId });
      return result.data.message;
    } catch (error) {
      console.error("Error generating auction description:", error);
      return null;
    }
  }

  async performAuctionSearch(searchTerm, filters) {
    try {
      return await httpsCallable(
        this.functions,
        "searchAuctions"
      )({
        searchTerm,
        filterBy: filters,
      });
    } catch (error) {
      console.error("Error performing auction search:", error);
      throw error;
    }
  }

  async fetchAllFacets(searchTerm, filters) {
    try {
      const facetsResult = await httpsCallable(
        this.functions,
        "fetchAllFacets"
      )({
        searchTerm,
        filters,
      });
      return facetsResult.data;
    } catch (error) {
      console.error("Error fetching facets:", error);
      return null;
    }
  }

  async fetchSavedCards() {
    try {
      const getSavedCards = httpsCallable(this.functions, "getSavedCards");
      //   const getSavedCards = this.functions.httpsCallable("getSavedCards");
      const result = await getSavedCards(); // Call the httpsCallable function
      return result.data; // Assuming the data is in result.data
    } catch (error) {
      console.error("Error fetching saved cards:", error);
      return [];
    }
  }

  async fetchPosts() {
    try {
      const snapshot = await getDocs(collection(this.db, "posts"));

      const postsData = snapshot.docs.map((doc) => {
        const data = doc.data();
        return { id: doc.id, ...data };
      });

      return postsData;
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw error;
    }
  }

  async fetchPostById(id) {
    try {
      const docRef = doc(this.db, "posts", id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const postData = docSnapshot.data();
        return postData;
      } else {
        throw new Error("Post not found");
      }
    } catch (error) {
      console.error("Error fetching post:", error);
      throw error;
    }
  }

  getPrivateMessages = async () => {
    try {
      //   const user = firebase.auth().currentUser;
      const user = getAuth();
      const snapshot = await this.db
        .collection("users")
        .doc(user.uid)
        .collection("privateMessages")
        .get();

      const messages = snapshot.docs.map((doc) => ({
        id: doc.id,
        sender: doc.data().senderUsername,
        receiver: doc.data().receiverUsername,
        subject: doc.data().subject,
        content: doc.data().content,
        createdAt: doc.data().createdAt.toDate(),
      }));

      return messages;
    } catch (error) {
      throw new Error("Error fetching private messages: " + error.message);
    }
  };

  async resendVerificationEmail(email) {
    const resendVerificationFunction = httpsCallable(
      this.functions,
      "resendVerificationEmail"
    );

    try {
      const response = await resendVerificationFunction({ email: email });

      // If the function returns a status, you can handle it here
      if (response.data.status === "already_verified") {
        console.warn("The email is already verified.");
        // You can also return the response to further handle it on the front-end if required
        return response.data;
      }

      if (response.data.status === "success") {
        console.log("Verification email sent successfully.");
        return response.data;
      }

      // Handle other statuses if they exist...
    } catch (error) {
      console.error("Error sending verification email:", error);
      throw error;
    }
  }

  async resendEmailVerification(email) {
    const resendEmailVerification = httpsCallable(
      this.functions,
      "resendEmailVerification"
    );

    try {
      await resendEmailVerification({ email: email });
      console.log("Verification email sent successfully.");
    } catch (error) {
      console.error("Error sending verification email:", error);
      throw error;
    }
  }

  async isUsernameTaken(username) {
    // Create a reference to the document
    const docRef = doc(this.db, "usernames", username);

    // Get the document
    const docSnap = await getDoc(docRef);

    // Return true if the document exists (username is taken)
    return docSnap.exists();
  }

  // Add a method to fetch usernames
  async fetchUsernames() {
    try {
      const usernamesRef = collection(this.db, "usernames");
      const snapshot = await getDocs(usernamesRef);
      const usernames = snapshot.docs.map((doc) => ({
        username: doc.id,
        userId: doc.data().userId,
      }));
      return usernames;
    } catch (error) {
      console.error("Error fetching usernames:", error);
      throw error;
    }
  }

  dateToTimestamp(date) {
    if (date) {
      //   return firebase.firestore.Timestamp.fromDate(date);
      return Timestamp.fromDate(date);
    }
  }

  isFirestoreTimestamp(timestamp) {
    return timestamp instanceof Timestamp;
  }

  convertFirestoreTimestampToDate(timestamp) {
    if (this.isFirestoreTimestamp(timestamp)) {
      return timestamp.toDate();
    } else if (
      timestamp &&
      typeof timestamp.seconds === "number" &&
      typeof timestamp.nanoseconds === "number"
    ) {
      // If it's a Firestore-like object with 'seconds' and 'nanoseconds'
      return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
    }
    return null;
  }

  async signUp(formValues) {
    try {
      const signUpCloudFunction = httpsCallable(this.functions, "signUp2");
      const result = await signUpCloudFunction({ formValues });

      if (result.data && result.data.success) {
        // Sign up successful
        return true;
      } else if (result.data && !result.data.success) {
        // Sign up failed, throw an error with the error message
        throw new Error(result.data.errorMessage);
      } else {
        // Unexpected response from Cloud Function
        throw new Error("Unexpected response from the Cloud Function");
      }
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  }

  async callRegisterToBidFunction(paymentMethodId, formValues) {
    try {
      // Invoke the Cloud Function to create a customer and attach the payment method
      const createCustomerFunction = httpsCallable(
        this.functions,
        "registerToBid"
      );
      const response = await createCustomerFunction({
        paymentMethodId: paymentMethodId,
        userDetail: formValues,
      });
      console.log(response);
      return response;
    } catch (error) {
      console.error("Error calling registerToBid function:", error);
      throw error;
    }
  }

  async getUserInfo(id) {
    try {
      // Create a reference to the user document
      const userRef = doc(this.db, "users", id);

      // Get the document
      const userDoc = await getDoc(userRef);

      // Check if the document exists
      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        throw new Error("User document does not exist");
      }
    } catch (error) {
      console.log("Failed to fetch user data:", error);
      throw error;
    }
  }

  async updateUserInfo(id, user) {
    try {
      const updateUserInfo = httpsCallable(this.functions, "updateUserInfo");
      await updateUserInfo({ id, user }); // Pass the id and user as arguments to the function
      console.log("User info updated successfully.");
    } catch (error) {
      console.error("Error updating user info:", error);
      throw error;
    }
  }

  async getAuctionInfo(auctionId) {
    try {
      if (!auctionId) {
        // Handle the case where auctionId is undefined or null
        return null;
      }
      // Create a reference to the document
      const auctionRef = doc(this.db, "auctions", auctionId);

      // Get the document
      const snapshot = await getDoc(auctionRef);

      if (snapshot.exists()) {
        const auction = {
          id: snapshot.id,
          ...snapshot.data(),
        };
        return auction;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting auction info:", error);
      throw error;
    }
  }

  async getUserAuctions(userId) {
    const auctionsRef = collection(this.db, "auctions");
    const q = query(auctionsRef, where("seller_id", "==", userId));
    const querySnapshot = await getDocs(q);
    const auctions = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return auctions;
  }

  getUserAuctionSubscription(auctionId, callback) {
    const auctionRef = doc(this.db, "auctions", auctionId);
    const unsubscribe = onSnapshot(auctionRef, (doc) => {
      if (doc.exists()) {
        const auction = {
          id: doc.id,
          ...doc.data(),
        };
        callback(auction);
      }
    });
    return unsubscribe;
  }

  async getSellerInfo(seller_id) {
    const userRef = doc(this.db, "users", seller_id);
    const userDoc = await getDoc(userRef);
    return userDoc.data();
  }

  subscribeToBidEndDate(auctionId, callback) {
    const auctionRef = doc(this.db, "auctions", auctionId);
    const unsubscribe = onSnapshot(
      auctionRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          if (data && data.bid_end_time) {
            // Assuming bid_end_time is a Firestore Timestamp
            const bidEndDate = data.bid_end_time; // Convert to JavaScript Date
            callback(bidEndDate);
          } else {
            console.error("Bid end time is undefined in auction data.");
          }
        } else {
          console.error("Auction document does not exist.");
        }
      },
      (error) => {
        console.error("Error in subscribeToBidEndDate: ", error);
      }
    );

    return unsubscribe;
  }

  subscribeToStatus(auctionId, callback) {
    const auctionRef = doc(this.db, "auctions", auctionId);
    const unsubscribe = onSnapshot(auctionRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const data = snapshot.data();
          if (data && data.status) {
            const status = data.status;
            callback(status);
          } else {
            console.error("Auction data or status is undefined.");
          }
        } else {
          console.error("Auction snapshot does not exist.");
        }
      } catch (error) {
        console.error("Error handling auction status:", error);
      }
    });

    return unsubscribe;
  }

  getStatus(auctionId) {
    return new Promise(async (resolve, reject) => {
      try {
        const auctionRef = doc(this.db, "auctions", auctionId);
        const snapshot = await getDoc(auctionRef);
        if (snapshot.exists()) {
          const data = snapshot.data();
          if (data) {
            const status = data.status;
            resolve(status);
          } else {
            reject(new Error("Auction data not found"));
          }
        } else {
          reject(new Error("Auction not found"));
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  //   async addAuction(auctionInfo) {
  //     // Helper function to convert an image file to a base64 string
  //     const convertImageToBase64 = (image) =>
  //       new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(image);
  //         reader.onloadend = () => resolve(reader.result);
  //         reader.onerror = (error) => reject(error);
  //       });

  //     try {
  //       const addAuctionCallable = httpsCallable(this.functions, "addAuction");

  //       // Convert images to base64 strings
  //       const promises = Object.entries(auctionInfo.images).map(
  //         async ([key, image]) => {
  //           //   console.log("key, type", key, image.type);
  //           const base64String = await convertImageToBase64(image);
  //           auctionInfo.images[key] = {
  //             name: key,
  //             type: image.type,
  //             data: base64String,
  //           };
  //         }
  //       );

  //       await Promise.all(promises);

  //       //console.log(auctionInfo.images);
  //       const result = await addAuctionCallable({ auctionInfo });
  //       //console.log(result.data.message);
  //       return result.data.message;
  //     } catch (error) {
  //       console.error("Error adding new auction:", error);
  //       return error;
  //       throw error;
  //     }
  //   }

  async addAuction(auctionInfo) {
    try {
      const addAuctionCallable = httpsCallable(this.functions, "addAuction");

      const result = await addAuctionCallable({ auctionInfo });
      console.log(result);

      // Assuming your Cloud Function returns an object with success, message, and id
      if (result.data.success) {
        console.log(result.data.message); // Optionally log the success message
        return {
          success: true,
          message: result.data.message,
          id: result.data.id, // Include the auction ID in the return object
        };
      } else {
        // Handle the case where the Cloud Function executed but did not succeed
        return {
          success: false,
          message: result.data.message || "Unknown error occurred",
        };
      }
    } catch (error) {
      console.error("Error adding new auction:", error);
      // Depending on your error handling, you might want to just throw the error or handle it differently
      return {
        success: false,
        message: error.message || "Error adding auction",
      };
    }
  }

  async getFAQ(topic) {
    try {
      const faqDocRef = doc(this.db, "faq", topic);
      const faqCollectionRef = collection(faqDocRef, "faqItem");
      const faqSnapshot = await getDocs(faqCollectionRef);

      const fetchedFaqData = faqSnapshot.docs.map((doc) => ({
        id: doc.id,
        question: doc.data().question,
        answer: doc.data().answer,
      }));

      return fetchedFaqData;
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      return [];
    }
  }

  async updateAuction(auctionId, auctionData) {
    // Helper function to convert an image file to a base64 string
    // const convertImageToBase64 = (image) =>
    //   new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(image);
    //     reader.onloadend = () => resolve(reader.result);
    //     reader.onerror = (error) => reject(error);
    //   });

    try {
      const updateAuctionCallable = httpsCallable(
        this.functions,
        "updateAuction_v2"
      );

      //   const promises = auctionData.additional_images.map(async (image) => {
      //     // console.log(image.data);
      //     const base64String = await convertImageToBase64(image.data);
      //     return {
      //       name: image.name,
      //       type: image.type,
      //       data: base64String,
      //     };
      //   });

      //   auctionData.additional_images = await Promise.all(promises);

      const result = await updateAuctionCallable({
        auctionId,
        auctionData,
      });

      //   console.log(result.data.message);
    } catch (error) {
      console.error("Error updating auction:", error);
      throw error;
    }
  }

  async updateMessage(auctionId, newChatMessage) {
    const updateMessageFunction = httpsCallable(
      this.functions,
      "updateMessage"
    );

    // Call the Cloud Function
    const result = await updateMessageFunction({
      auctionId: auctionId,
      newChatMessage: newChatMessage,
    });
    console.log(result.data);
  }

  async setAuctionBid(auctionId, newBid) {
    const setAuctionBid = httpsCallable(this.functions, "setAuctionBid");

    try {
      const response = await setAuctionBid({
        auctionId: auctionId,
        newBid: newBid,
      });

      // Access the response data if needed
      const { recentBid, lastBidder } = response.data;

      return { recentBid, lastBidder };
    } catch (error) {
      // Handle the error
      console.error("Error placing bid:", error);
      throw error; // Rethrow the error to the calling code
    }
  }

  async getWatchlist() {
    try {
      const currentUser = this.auth.currentUser;
      if (!currentUser) {
        throw new Error("User must be signed in to view watchlist");
      }
      const userRef = doc(this.db, "users", currentUser.uid);
      const watchlistRef = collection(userRef, "watchlist");
      const querySnapshot = await getDocs(watchlistRef);
      const auctionIds = querySnapshot.docs.map((doc) => doc.data().auctionId);
      return auctionIds;
    } catch (error) {
      console.error("Watchlist error:", error);
      return [];
    }
  }

  async toggleWatchlist(auctionId, isWatching) {
    try {
      const currentUser = this.auth.currentUser;
      if (!currentUser) {
        throw new Error("Please sign in to create watch list.");
      }
      const auctionRef = doc(this.db, "auctions", auctionId);
      const userRef = doc(this.db, "users", currentUser.uid);
      const watchlistRef = collection(userRef, "watchlist");
      const q = query(watchlistRef, where("auctionId", "==", auctionId));
      const querySnapshot = await getDocs(q);

      if (isWatching) {
        await updateDoc(auctionRef, { watch_count: increment(1) });

        if (querySnapshot.empty) {
          await addDoc(watchlistRef, {
            createdAt: new Date(),
            auctionId: auctionId,
            // Add title if necessary
          });
        }
      } else {
        await updateDoc(auctionRef, { watch_count: increment(-1) });
        querySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }
    } catch (error) {
      console.error("Toggle watchlist error:", error);
    }
  }

  async incrementViewCount(auctionId) {
    try {
      const auctionRef = doc(this.db, "auctions", auctionId);
      await updateDoc(auctionRef, { view_count: increment(1) });
    } catch (error) {
      console.error("View count increment error:", error);
    }
  }

  async getAuctionBids(id, callback) {
    try {
      const auctionRef = doc(this.db, "auctions", id);
      const bidsRef = collection(auctionRef, "bids");
      const q = query(bidsRef, orderBy("createdAt", "desc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const bidData = snapshot.docs.map((doc) => doc.data());
        const latestBid = bidData[0] || null;

        callback({
          numBids: snapshot.size,
          lastBidder: latestBid ? latestBid.username : "TBD",
          recentBid: latestBid ? latestBid.bid : "$0",
        });
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching auction bids:", error);
    }
  }

  getAuctionBids = async (auctionId) => {
    try {
      const bidsRef = collection(this.db, "auctions", auctionId, "bids");
      const snapshot = await getDocs(bidsRef);

      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error getting auction bids:", error);
      throw error;
    }
  };

  getChatMessages(id, setChatMessages) {
    const auctionRef = doc(this.db, "auctions", id);
    const messagesRef = collection(auctionRef, "messages");
    const q = query(messagesRef, orderBy("createdAt", "desc"));

    return onSnapshot(q, (snapshot) => {
      const messages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      }));
      setChatMessages(messages);
    });
  }

  getUserChatMessages(id, setUserChatMessages) {
    const userRef = doc(this.db, "users", id);
    const commentsRef = collection(userRef, "comments");
    const q = query(commentsRef, orderBy("createdAt", "desc"));

    return onSnapshot(q, (snapshot) => {
      const messages = snapshot.docs.map((doc) => ({
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      }));
      setUserChatMessages(messages);
    });
  }

  getBidMessages(id, setBidMessages) {
    const auctionRef = doc(this.db, "auctions", id);
    const bidsRef = collection(auctionRef, "bids");
    const q = query(bidsRef, orderBy("createdAt", "desc"));

    return onSnapshot(q, (snapshot) => {
      const messages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      }));
      setBidMessages(messages);
    });
  }

  async getUserBidMessages(id) {
    try {
      const userRef = doc(this.db, "users", id);
      const bidsRef = collection(userRef, "bids");
      const q = query(bidsRef, orderBy("createdAt", "desc"));
      const snapshot = await getDocs(q);

      const messages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      }));

      return messages;
    } catch (error) {
      console.error("Error fetching user bid messages:", error);
      return [];
    }
  }

  async getAuctionsByIds(auctionIds) {
    try {
      if (auctionIds.length > 0) {
        const auctionsRef = collection(this.db, "auctions");
        const auctions = [];

        for (let i = 0; i < auctionIds.length; i += 10) {
          const batchIds = auctionIds.slice(i, i + 10);
          const q = query(
            auctionsRef,
            where(documentId(), "in", batchIds) // Use fieldValue.documentId()
          );
          const auctionDocs = await getDocs(q);

          const batchAuctions = auctionDocs.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          auctions.push(...batchAuctions);
        }

        return auctions;
      }
    } catch (error) {
      console.error("Error getting auctions by IDs:", error);
      return [];
    }
  }

  getAuctionComments = async (auctionId) => {
    try {
      const messagesRef = collection(
        this.db,
        "auctions",
        auctionId,
        "messages"
      );
      const snapshot = await getDocs(messagesRef);

      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error getting auction comments:", error);
      throw error;
    }
  };

  async getUserBids(id) {
    try {
      const snapshot = await this.db
        .collection("users")
        .doc(id)
        .collection("bids")
        .get();
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getUsers = async ({ limit = null, searchTerm = null } = {}) => {
    try {
      const usersRef = collection(this.db, "users");
      let q = query(usersRef);

      if (searchTerm) {
        q = query(usersRef, where("name", "==", searchTerm));
      }
      if (limit) {
        q = query(q, limit(limit));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error getting users:", error);
      throw error;
    }
  };

  getAuctions = async ({ limit = null, searchTerm = null } = {}) => {
    try {
      const auctionsRef = collection(this.db, "auctions");
      let q = query(auctionsRef);

      if (searchTerm) {
        q = query(auctionsRef, where("status", "==", searchTerm));
      }
      if (limit) {
        q = query(q, limit(limit));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error getting auctions:", error);
      throw error;
    }
  };

  getReferralData = async () => {
    try {
      const referralCodesRef = collection(this.db, "referralCodes");
      const querySnapshot = await getDocs(referralCodesRef);

      const usersWithReferralCodes = [];
      for (const docSnapshot of querySnapshot.docs) {
        const data = docSnapshot.data();
        const userId = data.userId;

        if (userId) {
          const userRef = doc(this.db, "users", userId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            usersWithReferralCodes.push({
              userId: userId,
              referralCode: docSnapshot.id, // Use docSnapshot.id here
              ...userData,
            });
          }
        }
      }
      return usersWithReferralCodes;
    } catch (error) {
      console.error("Error retrieving users with referral codes:", error);
      throw error;
    }
  };

  getUserReferralInfoById = async (userId) => {
    try {
      const userRef = doc(this.db, "users", userId); // Construct a reference to the user's document
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        return userData;
      } else {
        console.error(`User with ID ${userId} does not exist.`);
        return null; // Return null if the user doesn't exist
      }
    } catch (error) {
      console.error(`Error retrieving user info for ID ${userId}:`, error);
      throw error;
    }
  };

  async incrementCommentLikes(auctionId, commentId) {
    try {
      const commentRef = doc(
        this.db,
        "auctions",
        auctionId,
        "messages",
        commentId
      );
      return runTransaction(this.db, async (transaction) => {
        const commentDoc = await transaction.get(commentRef);
        if (!commentDoc.exists()) {
          throw new Error("Comment does not exist!");
        }

        const currentLikes = commentDoc.data().likes || 0;
        transaction.update(commentRef, { likes: currentLikes + 1 });
        return currentLikes + 1;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateCommentFlag(auctionId, commentId, flagged) {
    try {
      const commentRef = doc(
        this.db,
        "auctions",
        auctionId,
        "messages",
        commentId
      );
      await updateDoc(commentRef, { flagged });
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getUserComments(id) {
    try {
      const commentsRef = collection(this.db, "users", id, "comments");
      const snapshot = await getDocs(commentsRef);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.log("Error fetching user comments:", error);
      throw error;
    }
  }

  async getUserWatchlist(id) {
    try {
      const watchlistRef = collection(this.db, "users", id, "watchlist");
      const snapshot = await getDocs(watchlistRef);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.log("Error fetching user watchlist:", error);
      throw error;
    }
  }

  getLiveAuctionListings = (searchQuery, callback) => {
    let queryRef = query(collection(this.db, "auctions"));

    if (searchQuery) {
      const { year, brand, type, condition, status } = searchQuery;

      // Apply filter for year if provided
      if (year) {
        let numYear = Number(year);
        queryRef = query(queryRef, where("year", "==", numYear));
      }
      // Apply filter for brand if provided
      if (brand) {
        queryRef = query(queryRef, where("brand", "==", brand));
      }
      // Apply filter for type if provided
      if (type) {
        queryRef = query(queryRef, where("type", "==", type));
      }
      // Apply filter for condition if provided
      if (condition) {
        queryRef = query(queryRef, where("condition", "==", condition));
      }
      // Apply filter for status if provided, especially for 'Live'
      if (status && status.length > 0) {
        queryRef = query(queryRef, where("status", "in", status));
      }
    }

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      const updatedListings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(updatedListings);
    });

    return unsubscribe; // Return the unsubscribe function
  };

  registerUserToBid = async () => {
    try {
      const registerToBid = httpsCallable(this.functions, "registerToBid");
      const response = await registerToBid();
    } catch (error) {
      console.error("Error registering to bid:", error);
      throw error;
    }
  };

  async fetchAuctionsByUserId(userId) {
    try {
      const userRef = doc(this.db, "users", userId);
      const userDoc = await getDoc(userRef);
      console.log(userDoc.data());

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const referrals = userData.referrals || [];

        const auctionPromises = referrals.map((referral) =>
          doc(this.db, "users", userId, "referrals", referral.auctionId)
        );

        const auctionDocs = await Promise.all(auctionPromises);
        const auctions = [];

        for (const auctionDoc of auctionDocs) {
          const auctionData = auctionDoc.data();
          if (auctionData) {
            auctions.push({
              id: auctionDoc.id,
              ...auctionData,
            });
          }
        }

        return auctions;
      }

      return [];
    } catch (error) {
      console.error("Error fetching auctions:", error);
      throw error;
    }
  }

  //   registerUserToBid = async () => {
  //     try {
  //       // Get a reference to the Cloud Function
  //       const registerToBid = httpsCallable(this.functions, "registerToBid");

  //       // Call the Cloud Function with the necessary data
  //       const response = await registerToBid();

  //       // Handle the response
  //     //   console.log(response.data.message);
  //       // Perform any additional actions based on the response
  //     } catch (error) {
  //       // Handle errors
  //       console.error("Error registering to bid:", error);
  //       // Display an error message to the user or take appropriate action
  //     }
  //   };

  //   getUserComments = async (id) => {
  //     try {
  //       const commentsRef = collection(this.db, "users", id, "comments");
  //       const snapshot = await getDocs(commentsRef);

  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching user comments:", error);
  //       throw error;
  //     }
  //   };

  //   getUserWatchlist = async (id) => {
  //     try {
  //       const watchlistRef = collection(this.db, "users", id, "watchlist");
  //       const snapshot = await getDocs(watchlistRef);

  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching user watchlist:", error);
  //       throw error;
  //     }
  //   };

  //   async getAuctionsByIds(auctionIds) {
  //     try {
  //       if (auctionIds.length > 0) {
  //         const auctionsRef = collection(this.db, "auctions");
  //         const auctions = [];

  //         for (let i = 0; i < auctionIds.length; i += 10) {
  //           const batchIds = auctionIds.slice(i, i + 10);
  //           const q = query(
  //             auctionsRef,
  //             where(FieldPath.documentId(), "in", batchIds)
  //           );
  //           const auctionDocs = await getDocs(q);

  //           const batchAuctions = auctionDocs.docs.map((doc) => ({
  //             id: doc.id,
  //             ...doc.data(),
  //           }));

  //           auctions.push(...batchAuctions);
  //         }

  //         return auctions;
  //       }
  //     } catch (error) {
  //       console.error("Error getting auctions by IDs:", error);
  //       return [];
  //     }
  //   }
  //   subscribeToBidEndDate(auctionId, callback) {
  //     const auctionRef = doc(this.db, "auctions", auctionId);
  //     const unsubscribe = onSnapshot(auctionRef, (snapshot) => {
  //       const data = snapshot.data();
  //       if (data) {
  //         const bidEndDate = data.bid_end_time;
  //         callback(bidEndDate);
  //       }
  //     });
  //     return unsubscribe;
  //   }

  //   subscribeToStatus(auctionId, callback) {
  //     const auctionRef = doc(this.db, "auctions", auctionId);
  //     const unsubscribe = onSnapshot(auctionRef, (snapshot) => {
  //       if (snapshot.exists()) {
  //         const data = snapshot.data();
  //         if (data) {
  //           const status = data.status;
  //           callback(status);
  //         }
  //       }
  //     });

  //     return unsubscribe;
  //   }
  //   getLiveAuctionListingsNoQuery = (callback) => {
  //     try {
  //       const queryRef = query(collection(this.db, "auctions"));

  //       const unsubscribe = onSnapshot(queryRef, (snapshot) => {
  //         const updatedListings = snapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         callback(updatedListings);
  //       });

  //       return unsubscribe;
  //     } catch (error) {
  //       console.error("Error fetching live auction listings:", error);
  //       throw error;
  //     }
  //   };

  //   getLiveAuctionListings(searchQuery, callback) {
  //     let query = this.db.collection("auctions");

  //     if (searchQuery.status && searchQuery.status.includes("Live")) {
  //       query = query.where("status", "==", "Live");
  //     }

  //     // other query filters...

  //     const unsubscribe = query.onSnapshot((snapshot) => {
  //       const updatedListings = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       callback(updatedListings);
  //     });

  //     return unsubscribe;
  //   }

  //   getLiveAuctionListings = (searchQuery, callback) => {
  //     try {
  //       let queryRef = query(collection(this.db, "auctions"));

  //       if (searchQuery) {
  //         const { year, brand, type, condition, status } = searchQuery;
  //         let numYear = Number(year);

  //         // Apply filters based on searchQuery
  //         // Use query() and where() to build the query
  //         // Example: queryRef = query(queryRef, where("year", "==", numYear));
  //       }

  //       const unsubscribe = onSnapshot(queryRef, (snapshot) => {
  //         const updatedListings = snapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         callback(updatedListings);
  //       });

  //       return unsubscribe;
  //     } catch (error) {
  //       console.error("Error fetching live auction listings:", error);
  //       throw error;
  //     }
  //   };

  // Modify the getLiveAuctionListings function to return the unsubscribe function
  //   getLiveAuctionListings = (searchQuery, callback) => {
  //     let queryRef = query(collection(this.db, "auctions"));

  //     if (searchQuery) {
  //       const { year, brand, type, condition, status } = searchQuery;
  //       let numYear = Number(year);

  //       // Apply filters based on searchQuery
  //       // Example: queryRef = query(queryRef, where("year", "==", numYear));
  //     }

  //     const unsubscribe = onSnapshot(queryRef, (snapshot) => {
  //       const updatedListings = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       callback(updatedListings);
  //     });

  //     return unsubscribe; // Return the unsubscribe function
  //   };

  // import firebase from "firebase/compat/app";
  // import "firebase/compat/firestore";
  // import { Timestamp } from "firebase/firestore";
  // import "firebase/compat/auth";
  // import "firebase/compat/storage";
  // import "firebase/compat/functions";
  // import { getAnalytics } from "firebase/analytics";
  // 	constructor() {
  //     const app = firebase.initializeApp(firebaseConfig);

  //     this.analytics = getAnalytics(app);
  //     this.auth = firebase.auth();
  //     this.db = firebase.firestore();
  //     this.store = firebase.storage();
  //     this.auctions = [];
  //     // Initialize Firebase Cloud Functions
  //     this.functions = firebase.functions();
  //   }

  //   async login(email, password) {
  //     try {
  //       await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  //       return await this.auth.signInWithEmailAndPassword(email, password);
  //     } catch (error) {
  //       // Handle or throw error
  //       throw error;
  //     }
  //   }

  //   login(email, password) {
  //     return this.auth.signInWithEmailAndPassword(email, password);
  //   }
  //   async createPrivateMessage(message) {
  //     try {
  //       const docRef = await this.db
  //         .collection("users")
  //         .doc(message.sender)
  //         .collection("privateMessages")
  //         .add(message);
  //       console.log("Private message created with ID:", docRef.id);
  //     } catch (error) {
  //       throw new Error("Error creating private message: " + error.message);
  //     }
  //   }
  //   async isUsernameTaken(username) {
  //     const docRef = this.db.collection("usernames").doc(username);
  //     const doc = await docRef.get();
  //     return doc.exists; // Return true if the document exists (username is taken)
  //   }
  //   async getUserInfo(id) {
  //     try {
  //       const userRef = this.db
  //         .collection("users")
  //         // .doc(this.auth.currentUser.uid);
  //         .doc(id);

  //       const userDoc = await userRef.get();

  //       if (userDoc.exists) {
  //         return userDoc.data();
  //       } else {
  //         throw new Error("User document does not exist");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       throw new Error("Failed to fetch user data");
  //     }
  //   }
  //   async getAuctionInfo(auctionId) {
  //     try {
  //       const auctionRef = this.db.collection("auctions").doc(auctionId);
  //       const snapshot = await auctionRef.get();

  //       if (snapshot.exists) {
  //         const auction = {
  //           id: snapshot.id,
  //           ...snapshot.data(),
  //         };
  //         return auction;
  //       } else {
  //         return null;
  //       }
  //     } catch (error) {
  //       console.error("Error getting auction info:", error);
  //       throw error;
  //     }
  //   }
  //   async getUserAuctions(userId) {
  //     const querySnapshot = await this.db
  //       .collection("auctions")
  //       .where("seller_id", "==", userId)
  //       .get();
  //     const auctions = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     return auctions;
  //   }
  //   getUserAuctionSubscription(auctionId, callback) {
  //     const unsubscribe = this.db
  //       .collection("auctions")
  //       .doc(auctionId)
  //       .onSnapshot((doc) => {
  //         if (doc.exists) {
  //           const auction = {
  //             id: doc.id,
  //             ...doc.data(),
  //           };
  //           callback(auction);
  //         }
  //       });
  //     return unsubscribe;
  //   }
  //   async getSellerInfo(seller_id) {
  //     const userRef = this.db.collection("users").doc(seller_id);
  //     const userDoc = await userRef.get();
  //     return userDoc.data();
  //   }
  //   subscribeToBidEndDate(auctionId, callback) {
  //     const docRef = this.db.collection("auctions").doc(auctionId);
  //     const unsubscribe = docRef.onSnapshot((snapshot) => {
  //       const data = snapshot.data();
  //       if (data) {
  //         const bidEndDate = data.bid_end_time;
  //         callback(bidEndDate);
  //       }
  //     });

  //     return unsubscribe;
  //   }
  //   subscribeToStatus(auctionId, callback) {
  //     const docRef = this.db.collection("auctions").doc(auctionId);
  //     const unsubscribe = docRef.onSnapshot((snapshot) => {
  //       const data = snapshot.data();
  //       if (data) {
  //         const status = data.status;
  //         callback(status);
  //       }
  //     });

  //     return unsubscribe;
  //   }
  //   getStatus(auctionId) {
  //     return new Promise((resolve, reject) => {
  //       const docRef = this.db.collection("auctions").doc(auctionId);

  //       docRef
  //         .get()
  //         .then((snapshot) => {
  //           const data = snapshot.data();
  //           if (data) {
  //             const status = data.status;
  //             resolve(status);
  //           } else {
  //             reject(new Error("Auction not found"));
  //           }
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     });
  //   }

  //   async getFAQ() {
  //     try {
  //       const faqCollectionRef = this.db.collection("faq");
  //       const faqSnapshot = await faqCollectionRef.get();

  //       const fetchedFaqData = [];
  //       faqSnapshot.forEach((doc) => {
  //         const faqItem = {
  //           id: doc.id,
  //           question: doc.data().question,
  //           answer: doc.data().answer,
  //         };
  //         fetchedFaqData.push(faqItem);
  //       });

  //       return fetchedFaqData;
  //     } catch (error) {
  //       console.error("Error fetching FAQ data:", error);
  //       return [];
  //     }
  //   }

  //   async getFAQ(topic) {
  //     try {
  //       const faqCollectionRef = this.db
  //         .collection("faq")
  //         .doc(topic)
  //         .collection("faqItem");
  //       const faqSnapshot = await faqCollectionRef.get();
  //       const fetchedFaqData = [];
  //       faqSnapshot.forEach((doc) => {
  //         const faqItem = {
  //           id: doc.id,
  //           question: doc.data().question,
  //           answer: doc.data().answer,
  //         };
  //         fetchedFaqData.push(faqItem);
  //       });

  //       return fetchedFaqData;
  //     } catch (error) {
  //       console.error("Error fetching FAQ data:", error);
  //       return [];
  //     }
  //   }
  //   async addAuction(auctionInfo) {
  //     // Helper function to convert an image file to a base64 string
  //     const convertImageToBase64 = (image) =>
  //       new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(image);
  //         reader.onloadend = () => resolve(reader.result);
  //         reader.onerror = (error) => reject(error);
  //       });

  //     try {
  //       const addAuctionCallable = this.functions.httpsCallable("addAuction");

  //       // Convert images to base64 strings
  //       const promises = Object.entries(auctionInfo.images).map(
  //         async ([key, image]) => {
  //           const base64String = await convertImageToBase64(image);
  //           return {
  //             name: key,
  //             type: image.type,
  //             data: base64String,
  //           };
  //         }
  //       );

  //       const images = await Promise.all(promises);
  //       const newAuctionInfo = { ...auctionInfo, images };

  //       const result = await addAuctionCallable({ auctionInfo: newAuctionInfo });
  //       console.log(result.data.message);
  //       return result.data.message;
  //     } catch (error) {
  //       console.error("Error adding new auction:", error);
  //       throw error;
  //     }
  //   }
  //   async updateMessage(auctionId, newChatMessage) {
  //     const userRef = this.db.collection("users").doc(this.auth.currentUser.uid);
  //     const userCommentRef = userRef.collection("comments");

  //     // get user display name
  //     let sellerInfo = await this.getSellerInfo(this.auth.currentUser.uid);
  //     const displayName = sellerInfo.username;

  //     const messageData = {
  //       text: newChatMessage,
  //       createdAt: new Date(),
  //       userId: this.auth.currentUser.uid,
  //       username: displayName,
  //     };

  //     await userCommentRef.add({
  //       text: newChatMessage,
  //       createdAt: new Date(),
  //       auctionId: auctionId,
  //     });

  //     return this.db
  //       .collection("auctions")
  //       .doc(auctionId)
  //       .collection("messages")
  //       .add(messageData);
  //   }
  //   async setAuctionBid(auctionId, newBid) {
  //     // Get current authenticated user
  //     const currentUser = this.auth.currentUser;
  //     if (!currentUser) {
  //       throw new Error("User must be signed in to place bid");
  //     }

  //     // get user display name
  //     const userInfo = await this.getSellerInfo(this.auth.currentUser.uid);
  //     const displayName = userInfo.username;

  //     const auctionRef = this.db.collection("auctions").doc(auctionId);
  //     const bidsRef = auctionRef.collection("bids");

  //     const userRef = this.db.collection("users").doc(this.auth.currentUser.uid);
  //     const userBidsRef = userRef.collection("bids");

  //     const latestBidSnapshot = await bidsRef
  //       .orderBy("createdAt", "desc")
  //       .limit(1)
  //       .get();

  //     let recentBid;
  //     if (latestBidSnapshot.docs.length > 0) {
  //       const latestBid = latestBidSnapshot.docs[0].data();
  //       //   lastBidder = latestBid.username;
  //       recentBid = latestBid.bid;
  //     }

  //     if (newBid <= recentBid) {
  //       throw new Error("Your bid is too low. Please increase and try again.");
  //     }

  //     const auctionDoc = await auctionRef.get();
  //     const bidEndDate = auctionDoc.data().bid_end_time.toDate(); // assuming bid_end_date is a Firebase Timestamp
  //     let bidEndTimeStamp;
  //     let auctionData;
  //     const now = new Date();

  //     if (now >= bidEndDate) {
  //       throw new Error("Bidding has already ended for this auction.");
  //     }

  //     const diffInSeconds = Math.floor((bidEndDate - now) / 1000); // difference in seconds

  //     const newBidData = {
  //       bid: Number(newBid),
  //       username: displayName,
  //       userId: currentUser.uid,
  //       createdAt: now,
  //     };

  //     await bidsRef.add(newBidData);

  //     await userBidsRef.add({
  //       bid: Number(newBid),
  //       createdAt: now,
  //       auctionId: auctionId,
  //     });

  //     if (diffInSeconds <= 60) {
  //       //	  throw new Error("You cannot place a bid within 60 seconds of the bidding deadline.");
  //       const newBidEndDate = new Date(now.getTime() + 62 * 1000); // not sure why I need 2 extra seconds to reset the timer but it works
  //       bidEndTimeStamp = firebase.firestore.Timestamp.fromDate(newBidEndDate);
  //       auctionData = {
  //         current_bid: Number(newBid),
  //         lastBidder: displayName,
  //         bid_end_time: bidEndTimeStamp,
  //       };
  //     } else {
  //       auctionData = {
  //         current_bid: Number(newBid),
  //         lastBidder: displayName,
  //       };
  //     }

  //     await auctionRef.update(auctionData);

  //     return { recentBid: newBid, lastBidder: displayName };
  //   }

  //   async getWatchlist() {
  //     try {
  //       const currentUser = this.auth.currentUser;
  //       if (!currentUser) {
  //         throw new Error("User must be signed in to view watchlist");
  //       }
  //       const userRef = this.db.collection("users").doc(currentUser.uid);
  //       const watchlistRef = userRef.collection("watchlist");
  //       const querySnapshot = await watchlistRef.get();
  //       const auctionIds = querySnapshot.docs.map((doc) => doc.data().auctionId);
  //       return auctionIds;
  //     } catch (error) {
  //       alert("watchlist" + error);
  //     }
  //   }
  //   async toggleWatchlist(auctionId, isWatching) {
  //     try {
  //       const currentUser = this.auth.currentUser;

  //       if (!currentUser) {
  //         throw new Error("Please sign in to create watch list.");
  //       }
  //       const auctionRef = this.db.collection("auctions").doc(auctionId);

  //       const userRef = this.db.collection("users").doc(currentUser.uid);
  //       const watchlistRef = userRef.collection("watchlist");
  //       const query = watchlistRef.where("auctionId", "==", auctionId);
  //       const querySnapshot = await query.get();

  //       if (isWatching) {
  //         // Increment the view count
  //         await auctionRef.update({
  //           watch_count: increment(1),
  //         });

  //         const auctionDoc = await auctionRef.get();
  //         const auctionData = auctionDoc.data();
  //         const title = auctionData.title;

  //         // Add the auction ID to the user's watchlist subcollection
  //         if (querySnapshot.empty) {
  //           await watchlistRef.add({
  //             createdAt: new Date(),
  //             auctionId: auctionId,
  //             title: title,
  //           });
  //         }
  //       } else {
  //         // Deccrement the view count
  //         await auctionRef.update({
  //           watch_count: increment(-1),
  //         });
  //         // Remove the auction ID from the user's watchlist subcollection
  //         querySnapshot.forEach(async (doc) => {
  //           await doc.ref.delete();
  //         });
  //       }
  //     } catch (error) {
  //       alert("toggle watchlist" + error);
  //     }
  //   }

  //   async incrementViewCount(auctionId) {
  //     try {
  //       const auctionRef = this.db.collection("auctions").doc(auctionId);

  //       // Increment the view count
  //       await auctionRef.update({
  //         view_count: increment(1),
  //       });
  //     } catch (error) {
  //       alert("viewcount " + error);
  //     }
  //   }
  //   async getAuctionBids(id, callback) {
  //     try {
  //       const docRef = this.db.collection("auctions").doc(id);
  //       const bidsRef = docRef.collection("bids");

  //       // Set up real-time updates
  //       const unsubscribe = bidsRef
  //         .orderBy("createdAt", "desc")
  //         .onSnapshot((snapshot) => {
  //           const numBids = snapshot.size; // Get the number of documents in the collection
  //           const bidData = []; // Array to store bid information

  //           snapshot.forEach((doc) => {
  //             const bid = doc.data();
  //             bidData.push(bid);
  //           });

  //           const latestBid = bidData.length > 0 ? bidData[0] : null; // Get the latest bid

  //           if (latestBid) {
  //             const lastBidder = latestBid.username;
  //             const recentBid = latestBid.bid;
  //             callback({
  //               numBids: numBids,
  //               lastBidder: lastBidder,
  //               recentBid: recentBid,
  //             });
  //           } else {
  //             // If there are no bids, set numBids to 0
  //             callback({
  //               numBids: 0,
  //               lastBidder: "TBD",
  //               recentBid: "$0",
  //             });
  //           }
  //         });

  //       return unsubscribe;
  //     } catch (error) {
  //       alert("Error fetching auction bids: " + error);
  //     }
  //   }
  //   getChatMessages(id, setChatMessages) {
  //     return this.db
  //       .collection("auctions")
  //       .doc(id)
  //       .collection("messages")
  //       .orderBy("createdAt", "desc")
  //       .onSnapshot((snapshot) => {
  //         const messages = snapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           const { createdAt } = data;
  //           return {
  //             id: doc.id,
  //             ...data,
  //             createdAt: createdAt.toDate(),
  //           };
  //         });
  //         setChatMessages(messages);
  //       });
  //   }
  //   getUserChatMessages(id, setUserChatMessages) {
  //     return this.db
  //       .collection("users")
  //       .doc(id)
  //       .collection("comments")
  //       .orderBy("createdAt", "desc")
  //       .onSnapshot((snapshot) => {
  //         const messages = snapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           const { createdAt } = data;
  //           return {
  //             ...data,
  //             createdAt: createdAt.toDate(),
  //           };
  //         });
  //         setUserChatMessages(messages);
  //       });
  //   }
  //   getBidMessages(id, setBidMessages) {
  //     return this.db
  //       .collection("auctions")
  //       .doc(id)
  //       .collection("bids")
  //       .orderBy("createdAt", "desc")
  //       .onSnapshot((snapshot) => {
  //         const messages = snapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           const { createdAt } = data;
  //           return {
  //             id: doc.id,
  //             ...data,
  //             createdAt: createdAt.toDate(),
  //           };
  //         });
  //         setBidMessages(messages);
  //       });
  //   }
  //   getUserBidMessages(id, setUserBidMessages) {
  //     return this.db
  //       .collection("users")
  //       .doc(id)
  //       .collection("bids")
  //       .orderBy("createdAt", "desc")
  //       .onSnapshot((snapshot) => {
  //         const messages = snapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           const { createdAt } = data;
  //           return {
  //             id: doc.id,
  //             ...data,
  //             createdAt: createdAt.toDate(),
  //           };
  //         });
  //         setUserBidMessages(messages);
  //       });
  //   }

  //   getUserBidMessages(id, callback) {
  //     return this.db
  //       .collection("users")
  //       .doc(id)
  //       .collection("bids")
  //       .orderBy("createdAt", "desc")
  //       .onSnapshot((snapshot) => {
  //         const messages = snapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           const { createdAt } = data;
  //           return {
  //             id: doc.id,
  //             ...doc.data(),
  //             createdAt: createdAt.toDate(),
  //           };
  //         });
  //         callback(messages); // Call the callback function with messages
  //       });
  //   }

  //   async getUserBidMessages(id) {
  //     try {
  //       const snapshot = await this.db
  //         .collection("users")
  //         .doc(id)
  //         .collection("bids")
  //         .orderBy("createdAt", "desc")
  //         .get();

  //       const messages = snapshot.docs.map((doc) => {
  //         const data = doc.data();
  //         const { createdAt } = data;
  //         return {
  //           id: doc.id,
  //           ...data,
  //           createdAt: createdAt.toDate(),
  //         };
  //       });
  //       return messages;
  //     } catch (error) {
  //       console.error("Error fetching user bid messages:", error);
  //     }
  //   }

  //   async getAuctionsByIds(auctionIds) {
  //     try {
  //       if (auctionIds.length > 0) {
  //         const auctionsRef = this.db.collection("auctions");
  //         const auctions = [];

  //         for (let i = 0; i < auctionIds.length; i += 10) {
  //           const batchIds = auctionIds.slice(i, i + 10);
  //           const auctionsQuery = auctionsRef.where(
  //             FieldPath.documentId(),
  //             "in",
  //             batchIds
  //           );
  //           const auctionDocs = await auctionsQuery.get();
  //           const batchAuctions = auctionDocs.docs.map((doc) => ({
  //             id: doc.id,
  //             ...doc.data(),
  //           }));
  //           auctions.push(...batchAuctions);
  //         }

  //         return auctions;
  //       }
  //     } catch (error) {
  //       console.error("Error getting auctions by IDs:", error);
  //     }
  //   }
  //   getUsers = async () => {
  //     try {
  //       const snapshot = await this.db.collection("users").get();
  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       // Handle the error
  //       console.error("Error getting users:", error);
  //       throw error;
  //     }
  //   };

  //   getUsers = async ({ limit = null, searchTerm = null } = {}) => {
  //     try {
  //       let query = this.db.collection("users");

  //       // If there's a search term provided, filter by it.
  //       // Assuming you're searching by 'name', adjust accordingly.
  //       if (searchTerm) {
  //         query = query.where("name", "==", searchTerm);
  //       }

  //       // If limit is provided, apply it.
  //       if (limit) {
  //         query = query.limit(limit);
  //       }

  //       const snapshot = await query.get();

  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       // Handle the error
  //       console.error("Error getting users:", error);
  //       throw error;
  //     }
  //   };

  //   getAuctions = async ({ limit = null, searchTerm = null } = {}) => {
  //     try {
  //       let query = this.db.collection("auctions");

  //       // If there's a search term provided, filter by it.
  //       // Assuming you're searching by 'name', adjust accordingly.
  //       if (searchTerm) {
  //         query = query.where("status", "==", searchTerm);
  //       }

  //       // If limit is provided, apply it.
  //       if (limit) {
  //         query = query.limit(limit);
  //       }

  //       const snapshot = await query.get();

  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       // Handle the error
  //       console.error("Error getting users:", error);
  //       throw error;
  //     }
  //   };

  //   getReferralData = async () => {
  //     try {
  //       const referralCodesCollection = this.db.collection("referralCodes");

  //       const querySnapshot = await referralCodesCollection.get();

  //       const usersWithReferralCodes = [];

  //       querySnapshot.forEach(async (doc) => {
  //         const data = doc.data();
  //         const userId = data.userId;

  //         if (userId) {
  //           // Retrieve the user information based on userId and add to the list
  //           const userRef = this.db.collection("users").doc(userId);
  //           const userDoc = await userRef.get();

  //           if (userDoc.exists) {
  //             const userData = userDoc.data();
  //             usersWithReferralCodes.push({
  //               userId: userId,
  //               referralCode: doc.id, // Assuming the referral code is the document ID
  //               firstName: userData.firstName,
  //               lastName: userData.lastName,
  //               email: userData.email,
  //               // Add more user data as needed
  //             });
  //           }
  //         }
  //       });

  //       return usersWithReferralCodes;
  //     } catch (error) {
  //       console.error("Error retrieving users with referral codes:", error);
  //       throw error;
  //     }
  //   };

  //   getAuctionBids = async (auctionId) => {
  //     try {
  //       const snapshot = await this.db
  //         .collection("auctions")
  //         .doc(auctionId)
  //         .collection("bids")
  //         .get();

  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       console.error("Error getting auction bids:", error);
  //       throw error;
  //     }
  //   };
  //   getAuctionComments = async (auctionId) => {
  //     try {
  //       const snapshot = await this.db
  //         .collection("auctions")
  //         .doc(auctionId)
  //         .collection("messages")
  //         .get();

  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       console.error("Error getting auction comments:", error);
  //       throw error;
  //     }
  //   };
  //   async getUserComments(id) {
  //     try {
  //       const snapshot = await this.db
  //         .collection("users")
  //         .doc(id)
  //         .collection("comments")
  //         .get();
  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //       throw error;
  //     }
  //   }

  //   async getUserWatchlist(id) {
  //     try {
  //       const snapshot = await this.db
  //         .collection("users")
  //         .doc(id)
  //         .collection("watchlist")
  //         .get();
  //       return snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //       throw error;
  //     }
  //   }

  //   async getLiveAuctionListingsNoQuery(callback) {
  //     try {
  //       const query = this.db.collection("auctions");

  //       const unsubscribe = query.onSnapshot((snapshot) => {
  //         const updatedListings = snapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         callback(updatedListings);
  //       });

  //       return unsubscribe;
  //     } catch (error) {
  //       console.error("Error fetching live auction listings:", error);
  //       throw error;
  //     }
  //   }

  //   async getLiveAuctionListings(searchQuery, callback) {
  // 	try {
  // 	  let queryRef = query(collection(this.db, "auctions"));

  // 	  if (searchQuery) {
  // 		const { year, brand, type, condition, status } = searchQuery;
  // 		let numYear = Number(year);

  // 		if (year) {
  // 		  queryRef = query(queryRef, where("year", "==", numYear));
  // 		}

  // 		if (brand) {
  // 		  queryRef = query(queryRef, where("brand", "==", brand));
  // 		}

  // 		if (type) {
  // 		  queryRef = query(queryRef, where("type", "==", type));
  // 		}

  // 		if (condition) {
  // 		  queryRef = query(queryRef, where("condition", "==", condition));
  // 		}

  // 		if (status && status.length > 0) {
  // 		  queryRef = query(queryRef, where("status", "in", status));
  // 		}
  // 	  }

  // 	  const unsubscribe = onSnapshot(queryRef, (snapshot) => {
  // 		const updatedListings = snapshot.docs.map((doc) => ({
  // 		  id: doc.id,
  // 		  ...doc.data(),
  // 		}));
  // 		callback(updatedListings);
  // 	  });

  // 	  return unsubscribe;
  // 	} catch (error) {
  // 	  console.error("Error fetching live auction listings:", error);
  // 	  throw error;
  // 	}
  //   }

  //   async getLiveAuctionListings(searchQuery, callback) {
  //     try {
  //       let query = this.db.collection("auctions");

  //       if (searchQuery) {
  //         const { year, brand, type, condition, status } = searchQuery;
  //         let numYear = Number(year);

  //         if (year) {
  //           query = query.where("year", "==", numYear);
  //         }

  //         if (brand) {
  //           query = query.where("brand", "==", brand);
  //         }

  //         if (type) {
  //           query = query.where("type", "==", type);
  //         }

  //         if (condition) {
  //           query = query.where("condition", "==", condition);
  //         }

  //         // if (status) {
  //         //   query = query.where("status", "==", status);
  //         // }
  //         if (status && status.length > 0) {
  //           // Use where-in query to filter by multiple status values
  //           query = query.where("status", "in", status);
  //         }
  //       }

  //       const unsubscribe = query.onSnapshot((snapshot) => {
  //         const updatedListings = snapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         callback(updatedListings);
  //       });

  //       return unsubscribe;
  //     } catch (error) {
  //       console.error("Error fetching live auction listings:", error);
  //       throw error;
  //     }
  //   }

  //   // Function to increment the like count for a comment in the Firebase database
  //   async incrementCommentLikes(auctionId, commentId) {
  //     try {
  //       const commentRef = this.db
  //         .collection("auctions")
  //         .doc(auctionId)
  //         .collection("messages")
  //         .doc(commentId);

  //       // Use the 'increment' operation to increment the 'likes' field by 1
  //       return this.db.runTransaction(async (transaction) => {
  //         const commentDoc = await transaction.get(commentRef);
  //         if (!commentDoc.exists) {
  //           throw new Error("Comment does not exist!");
  //         }

  //         const currentLikes = commentDoc.data().likes || 0;
  //         transaction.update(commentRef, { likes: currentLikes + 1 });

  //         return currentLikes + 1; // Return the updated like count
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       throw error;
  //     }
  //   }

  //   async updateCommentFlag(auctionId, commentId, flagged) {
  //     try {
  //       const commentRef = this.db
  //         .collection("auctions")
  //         .doc(auctionId)
  //         .collection("messages")
  //         .doc(commentId);

  //       // Update the 'flagged' field to the specified value
  //       await commentRef.update({ flagged });

  //       return true; // Indicate successful update
  //     } catch (error) {
  //       console.log(error);
  //       throw error;
  //     }
  //   }
}

export default new Firebase();
