import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import FB from "../../../data/Firebase";
import Breadcrumb from "../../common/Breadcrumb";

function AdminReferrals() {
  // Define state variables for referrals data
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true); // Initially set loading to true
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch referrals data from your database/API here
    const fetchData = async () => {
      try {
        // Call the getReferralData function to fetch referral data
        const referralData = await FB.getReferralData();
        // console.log(referralData);

        setReferrals(referralData);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        setError(error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumb pageName="Referrals" pageTitle="Referrals" />
      <div
        style={{
          paddingTop: "65px",
          paddingBottom: "40px",
        }}
        className="container-fluid"
      >
        <div style={{ paddingTop: "20px" }}>
          <div className="button-group" style={{ marginBottom: "20px" }}>
            <NavLink
              to="/admin"
              activeClassName="active-link"
              style={{ marginRight: "20px" }}
            >
              <button className="profile-btn">Users</button>
            </NavLink>
            <NavLink
              to={`/admin-auction`}
              activeClassName="active-link"
              style={{ marginRight: "20px" }}
            >
              <button className="profile-btn">Auctions</button>
            </NavLink>
            <NavLink
              to={`/admin-referrals`}
              activeClassName="active-link"
              style={{ marginRight: "20px" }}
            >
              <button className="profile-btn">Referrals</button>
            </NavLink>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error.message}</p>
          ) : (
            <table>
              {/* Define the structure of your referral table */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Email</th>
                  {/* Add more fields as needed */}
                </tr>
              </thead>
              <tbody>
                {referrals.map((referral, index) => (
                  <tr key={index}>
                    <td>
                      {/* Create a link to a new route or popup here */}
                      <Link to={`/referral-info/${referral.userId}`}>
                        {referral.userId.slice(0, 6)}
                      </Link>
                    </td>
                    <td>{referral.referralCode}</td>
                    <td>
                      {referral.firstName} {referral.lastName}
                    </td>
                    <td>{referral.email}</td>
                    {/* Add more fields as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminReferrals;
