import React, { useState, useEffect } from "react";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";
// import IP from "../../common/ImagePicker";
import { v4 as uuidv4 } from "uuid";
// import Resizer from "react-image-file-resizer";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import the plugin
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

registerPlugin(
  // previews dropped images
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit,
  FilePondPluginFileEncode
);

const apiKey = process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY;

setDefaults({
  key: apiKey,
  language: "en",
  region: "us",
});

function AddNewAuctionListing() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [progress, setProgress] = useState(0);
  const [serverResponse, setServerResponse] = useState(null);
  const [referralCodeError, setReferralCodeError] = useState("");
  const [isCodeValidating, setIsCodeValidating] = useState(false);
  const [validationMessage, setValidationMessage] = useState({
    type: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  // Adjusting state variables for FilePond
  const [filesDriveSide, setFilesDriveSide] = useState([]);
  const [filesNonDriveSide, setFilesNonDriveSide] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [auctionId, setAuctionId] = useState(null);

  // FilePond server configuration for uploading files (example)
  const pondServerConfig = {
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      if (!auctionId) {
        console.error("Auction ID is not set yet.");
        error("Auction ID is not set yet.");
        return;
      }

      // Proceed with the upload using the known auction ID
      const uniqueId = uuidv4();
      const storagePath = `Auctions/${auctionId}/${uniqueId}`;

      const uploadTask = FB.uploadFile(storagePath, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const pct = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
        },
        (err) => {
          console.error(err);
          error(err.message);
          abort();
        },
        async () => {
          try {
            const downloadURL = await FB.getFileDownloadURL(storagePath);
            await FB.addFileToAuction(auctionId, {
              path: storagePath,
              url: downloadURL,
            });
            load(uniqueId);
          } catch (err) {
            console.error("Error during Firestore update:", err);
            error(err.message);
          }
        }
      );

      return () => uploadTask.cancel();
    },

    // Ensure the revert and load functions are appropriately defined
    // ...
  };

  /*********************************************************************************************** */
  // Load Select Options from Firebase
  const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [bikeTypes, setBikeTypes] = useState([]);
  const [selectedBikeType, setSelectedBikeType] = useState("");
  const [selectedFrameSize, setSelectedFrameSize] = useState("");
  const [selectedWheelSize, setSelectedWheelSize] = useState("");
  const [frameSizes, setFrameSizes] = useState([]);
  const [wheelSizes, setWheelSizes] = useState([]);

  // fetch all non-dependent data on component mount
  useEffect(() => {
    fetchBrands();
    fetchYears();
    fetchBikeTypes();
  }, []);

  const fetchYears = async () => {
    try {
      const yearsArray = await FB.fetchData("years", "yearList");
      if (yearsArray) {
        // Sort years from most recent to oldest
        const sortedYears = yearsArray.sort(
          (a, b) => parseInt(b) - parseInt(a)
        );
        setYears(sortedYears);
      } else {
        console.log("No years data found");
      }
    } catch (error) {
      console.error("Error fetching years data:", error);
    }
  };

  // For Brands
  const fetchBrands = async () => {
    try {
      const brandsData = await FB.fetchData("brands", "brandList");
      if (brandsData) {
        // Sort the brandArray in ascending order
        brandsData.sort((a, b) => a.localeCompare(b));

        setBrands(brandsData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  const fetchBikeTypes = async () => {
    try {
      const bikeTypesData = await FB.fetchData("bikeTypes", "bikeTypeList");

      if (bikeTypesData) {
        setBikeTypes(bikeTypesData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  useEffect(() => {
    fetchFrameSizes(selectedBikeType);
    fetchWheelSizes(selectedBikeType);
  }, [selectedBikeType]);

  const fetchFrameSizes = async (bikeType) => {
    if (bikeType) {
      const frameSizes = await FB.fetchSizes(bikeType, "frameSizes");

      if (frameSizes) {
        setFrameSizes(frameSizes);
      }
    }
  };

  const fetchWheelSizes = async (bikeType) => {
    if (bikeType) {
      const wheelSizes = await FB.fetchSizes(bikeType, "wheelSizes");

      if (wheelSizes) {
        setWheelSizes(wheelSizes);
      }
    }
  };

  /*********************************************************************************************** */

  const fetchCityAndState = (zipCode) => {
    fromAddress(zipCode).then(
      (response) => {
        // console.log(response.results[0].address_components);

        if (response.results && response.results.length > 0) {
          const addressComponents = response.results[0].address_components;

          const cityComponent = addressComponents.find(
            (component) =>
              component.types.includes("neighborhood") ||
              component.types.includes("locality")
          );

          const stateComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          const city = cityComponent ? cityComponent.long_name : null;
          const state = stateComponent ? stateComponent.short_name : null;

          if (city && state) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              city: city,
              state: state,
            }));
          } else {
            // Handle the case where city or state is not found
            console.log("City or State not found for this ZIP code.");
          }
        } else {
          console.log("No results found for this ZIP code.");
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const validateField = (name, value) => {
    switch (name) {
      case "year":
        if (!value || value < 1900 || value > new Date().getFullYear()) {
          return "Invalid year";
        }
        break;
      case "reserve_requested":
        if (!value || value < 0 || value > 20000) {
          return "Invalid reserve price";
        }
        break;
      case "zip":
        if (!/^\d{5}$/.test(value)) {
          return "Invalid ZIP code";
        }
        break;
      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) {
          return "Invalid phone number";
        }
        break;
      case "model":
        if (value.trim().length < 2 || value.trim().length > 100) {
          return "Model must be between 2 and 100 characters";
        }
        break;
      case "color":
        if (value.trim().length < 3 || value.trim().length > 50) {
          return "Color must be between 3 and 50 characters";
        }
        break;
      case "firstName":
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          return "First name must contain only letters and spaces";
        }
        break;
      case "lastName":
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          return "Last name must contain only letters and spaces";
        }
        break;
      default:
        return "";
    }
  };

  useEffect(() => {
    const unsubscribe = FB.auth.onAuthStateChanged((authUser) => {
      if (authUser && authUser.emailVerified) {
        setUser(authUser);
      } else {
        // Redirect to sign up page if not authenticated
        alert("Please sign in to create an auction listing request.");
        history.push({
          pathname: "/login",
          state: { from: "/new" },
        });
      }
    });
    return unsubscribe;
  }, [history]);

  const [formData, setFormData] = useState({
    year: "",
    brand: "",
    model: "",
    serial_number: "",
    reserve: "",
    description: "",
    current_bid: "",
    bid_end_time: "",
    condition: "",
    frame_size: "",
    wheel_size: "",
    type: "",
    frame_material: "",
    components: [],
    additional_notes: "",
    reserve_requested: "",
    color: "",
    miles: "",
    weight: "",
    original_owner: "",
    listed_elsewhere: "",
    city: "",
    zip: "",
    state: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    code: "",
  });

  async function addAuction(event) {
    event.preventDefault();

    try {
      setLoading(true);
      setProgress(0); // set initial progress to 0
      setServerResponse(null);

      // Validation for the referral code
      if (formData.code && formData.code.trim() !== "") {
        if (user.referralCode === formData.code.trim()) {
          setServerResponse({
            type: "error",
            message: "You cannot use your own referral code.",
          });
          setLoading(false);
          return;
        }

        const validationResponse = await FB.validateReferralCode(formData.code);
        if (!validationResponse) {
          setServerResponse({
            type: "error",
            message: "Invalid referral code. Please check again.",
          });
          setLoading(false);
          return;
        }
      }

      const result = await FB.addAuction(formData, (progress) => {
        setProgress(progress); // update progress state as the upload progresses
      });

      // Check if the operation was successful and we have an auction ID
      if (result.success && result.id) {
        const auctionId = result.id;
        //console.log("Auction ID:", auctionId); // Now you have the auction ID

        // Proceed with uploading images using this auction ID...
        if (filesDriveSide.length > 0) {
          await uploadFile(filesDriveSide[0].file, auctionId, "driveSide");
        }
        if (filesNonDriveSide.length > 0) {
          await uploadFile(
            filesNonDriveSide[0].file,
            auctionId,
            "nonDriveSide"
          );
        }

        setServerResponse({
          type: "success",
          message: "Auction added successfully, including images.",
        });

        // Reset state to clear form and selected files
        resetFormAndFiles();
      } else {
        // Handle the case where the auction creation did not succeed or did not return an ID
        throw new Error(
          result.message || "Auction creation failed without a specific error."
        );
      }
    } catch (error) {
      console.error("Error adding new auction listing: ", error);
      setServerResponse({
        type: "error",
        message: "Failed to add auction. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }

  const uploadFile = async (file, auctionId, imageType) => {
    // Generate a unique ID and construct the storage path
    const uniqueId = uuidv4();
    const storagePath = `Auctions/${auctionId}/${uniqueId}`;

    // Use Firebase functions to upload the file
    const uploadTask = FB.uploadFile(storagePath, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: Update progress state here
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          const downloadURL = await FB.getFileDownloadURL(storagePath);

          // Optionally update the auction document with the image URL
          await FB.addFileToAuction(auctionId, {
            path: storagePath,
            url: downloadURL,
          });
          resolve(downloadURL);
        }
      );
    });
  };

  // Helper function to reset form and selected files
  function resetFormAndFiles() {
    setFormData({
      year: "",
      brand: "",
      model: "",
      serial_number: "",
      reserve: "",
      description: "",
      current_bid: "",
      bid_end_time: "",
      condition: "",
      frame_size: "",
      wheel_size: "",
      type: "",
      frame_material: "",
      components: [],
      additional_notes: "",
      reserve_requested: "",
      color: "",
      miles: "",
      weight: "",
      original_owner: "",
      listed_elsewhere: "",
      city: "",
      zip: "",
      state: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      code: "",
    });
    setFilesDriveSide([]);
    setFilesNonDriveSide([]);
  }

  async function handleChange(event) {
    const { name, value } = event.target;

    // Convert referral code to uppercase
    const updatedValue = name === "code" ? value.toUpperCase() : value;

    // Reset error if the field is cleared
    if (!value.trim()) {
      setErrors({ ...errors, [name]: "" });
    } else {
      // Validate on change
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
    }

    // Update the form data for other cases
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "zip") {
      if (value.length === 5) {
        fetchCityAndState(value);
      } else {
        // Clear city and state if zip code is not 5 digits
        setFormData((prevFormData) => ({
          ...prevFormData,
          city: "",
          state: "",
        }));
      }
    }

    if (name === "type") {
      setSelectedBikeType(value);
    }
  }

  const phoneRegex = /^\d{10}$/; // Matches a 10-digit number

  let debounceTimer;

  const validateReferralCode = async () => {
    if (!formData.code.trim()) {
      setValidationMessage({ type: "", message: "" });
      return;
    }

    setIsCodeValidating(true);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(async () => {
      try {
        const code = formData.code; // Replace with your code variable

        const validationResponse = await FB.validateReferralCode(code);

        if (validationResponse.isValid) {
          setValidationMessage({
            type: "success",
            message: "Referral code is valid!",
          });
        } else {
          setValidationMessage({
            type: "error",
            message: "Invalid referral code. Please check again.",
          });
        }
      } catch (error) {
        console.error("Error validating referral code:", error);
        setValidationMessage({
          type: "error",
          message: "An error occurred while validating the code.",
        });
      } finally {
        setIsCodeValidating(false);
      }
    }, 500);
  };

  useEffect(() => {
    // Simplified field checks for demonstration. Expand as needed.
    const formFieldsAreValid =
      formData.year.trim() !== "" &&
      formData.brand.trim() !== "" &&
      formData.model.trim() !== "" &&
      formData.reserve_requested.trim() !== "" &&
      formData.color.trim() !== "" &&
      formData.city.trim() !== "" &&
      formData.state.trim() !== "" &&
      formData.zip.trim() !== "" &&
      formData.firstName.trim() !== "" &&
      formData.lastName.trim() !== "" &&
      formData.year.trim() !== "" &&
      formData.lastName.trim() !== "" &&
      formData.original_owner.trim() !== "" &&
      formData.listed_elsewhere.trim() !== "";

    // Check if both FilePond instances have at least one file each
    const driveSideHasFiles = filesDriveSide.length > 0;
    const nonDriveSideHasFiles = filesNonDriveSide.length > 0;

    // Combine checks to set form validity
    const formIsValid =
      formFieldsAreValid && driveSideHasFiles && nonDriveSideHasFiles;

    setFormValid(formIsValid);
  }, [formData, filesDriveSide, filesNonDriveSide]); // Add all relevant dependencies here

  return (
    <div className="signup-section">
      <Breadcrumb pageName="Sell Your Bike" pageTitle="Sell Your Bike" />
      <div className="container">
        <div className="row d-flex justify-content-center clearfix">
          <div className="container-fluid" style={{ margin: "90px 30px 30px" }}>
            <form onSubmit={addAuction} className="container">
              <h1>Tells us about your bike</h1>
              <p style={{ padding: "0px 0px 15px 5px", fontSize: "16px" }}>
                Please submit your bike for review. If it's a good fit for our
                platform, we'll notify you to provide additional details and
                photos. Thanks for choosing BidBikes!
              </p>
              <div className="d-flex row gx-3 justify-content-between">
                <div className="col-md-6">
                  <label htmlFor="year" className="form-label">
                    Bicycle Model Year*:
                  </label>
                  {errors.year && (
                    <div className="error-message">{errors.year}</div>
                  )}
                  <select
                    type="number"
                    name="year"
                    value={formData.year}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 ">
                  <label htmlFor="brand" className="form-label">
                    Bicycle Brand*:
                  </label>
                  <select
                    type="text"
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Brand</option>
                    {brands.map((brand, index) => (
                      <option key={index} value={brand}>
                        {brand}
                      </option>
                    ))}
                  </select>
                </div>

                <div className=" col-md-6">
                  <label htmlFor="model" className="form-label">
                    Bicycle Model*:
                  </label>
                  <input
                    type="text"
                    id="model"
                    name="model"
                    value={formData.model}
                    placeholder="Model and build (e.g. Turbo Levo Pro)"
                    onChange={handleChange}
                    required
                    className="form-input"
                    disabled={loading}
                    maxLength={50}
                  />
                </div>

                <div className=" col-md-6">
                  <label htmlFor="type" className="form-label">
                    Bicycle Type*:
                  </label>
                  <select
                    type="text"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Type</option>
                    {bikeTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>

                <div className=" col-md-6">
                  <label htmlFor="frame_size" className="form-label">
                    Bicycle Size*:
                  </label>
                  <select
                    type="text"
                    name="frame_size"
                    value={formData.frame_size}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Type</option>
                    {frameSizes.map((size, index) => (
                      <option key={index} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>

                <div className=" col-md-6">
                  <label htmlFor="wheel_size" className="form-label">
                    Wheel Size*:
                  </label>
                  <select
                    type="text"
                    name="wheel_size"
                    value={formData.wheel_size}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Type</option>
                    {wheelSizes.map((size, index) => (
                      <option key={index} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" col-md-6">
                  <label htmlFor="reserve" className="form-label">
                    Requested reserve*:
                  </label>
                  {errors.reserve_requested && (
                    <div className="error-message">
                      {errors.reserve_requested}
                    </div>
                  )}
                  <input
                    type="tel"
                    id="reserve_requested"
                    name="reserve_requested"
                    value={formData.reserve_requested}
                    placeholder="Requested reserve price?"
                    onChange={handleChange}
                    required
                    className="form-input"
                    disabled={loading}
                    max={20000}
                    min={0}
                    pattern="\d*"
                  />
                </div>

                <div className=" col-md-6">
                  <label htmlFor="condition" className="form-label">
                    Bicycle Condition*:
                  </label>
                  <select
                    id="condition"
                    name="condition"
                    value={formData.condition}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Condition</option>
                    <option value="New">New</option>
                    <option value="Used">Used</option>
                  </select>
                </div>
                <div className=" col-md-6">
                  <label className="form-label">Bicycle Frame Material*:</label>
                  <select
                    name="frame_material"
                    value={formData.frame_material}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select Frame Material</option>
                    <option value="Carbon">Carbon</option>
                    <option value="Aluminum">Aluminum</option>
                    <option value="Steel">Steel</option>
                    <option value="Titanium">Titanium</option>
                  </select>
                </div>
                <div className=" col-md-6">
                  <label className="form-label">Color*:</label>
                  <input
                    type="text"
                    name="color"
                    value={formData.color}
                    placeholder="Please specify frame color"
                    onChange={handleChange}
                    required
                    className="form-input"
                    disabled={loading}
                  />
                </div>

                <div className=" col-md-6">
                  <label className="form-label">Original Owner?*</label>
                  <select
                    type="text"
                    name="original_owner"
                    value={formData.original_owner}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className=" col-md-6">
                  <label className="form-label">
                    Listed For Sale on Another Site?*
                  </label>
                  <select
                    type="text"
                    name="listed_elsewhere"
                    value={formData.listed_elsewhere}
                    onChange={handleChange}
                    required
                    className="nice-select"
                    disabled={loading}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <label htmlFor="year" className="form-label">
                Seller Info:
              </label>
              <div className="d-flex row gx-3 justify-content-between">
                <div className=" col-md-6">
                  {errors.firstName && (
                    <div className="error-message">{errors.firstName}</div>
                  )}
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    placeholder="Enter your first name*"
                    className="form-input"
                    disabled={loading}
                    maxLength={30}
                  />
                </div>
                <div className=" col-md-6">
                  {errors.lastName && (
                    <div className="error-message">{errors.lastName}</div>
                  )}
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    placeholder="Enter your last name*"
                    className="form-input"
                    disabled={loading}
                    maxLength={30}
                  />
                </div>
              </div>
              <label htmlFor="year" className="form-label">
                Bicycle Location:
              </label>
              <div className="d-flex row gx-3 justify-content-between">
                <div className=" col-md-6">
                  <input
                    type="tel"
                    id="zip"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    maxLength={5}
                    pattern="\d*"
                    required
                    placeholder="Zip code where bike is located*"
                    className="form-input"
                    disabled={loading}
                  />
                </div>

                <div className=" col-md-6">
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    placeholder="City"
                    onChange={handleChange}
                    className="form-input"
                    disabled={loading}
                  />{" "}
                </div>

                <div className=" col-md-6">
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    placeholder="State"
                    readOnly // Make this field read-only since it's auto-filled
                    className="form-input"
                  />
                </div>
              </div>
              <label htmlFor="code" className="form-label">
                Referral Code:
              </label>
              <input
                type="text"
                name="code"
                value={formData.code}
                placeholder="Referral Code"
                onChange={handleChange}
                onBlur={validateReferralCode}
                className="form-input"
                disabled={isCodeValidating}
              />
              {isCodeValidating && (
                <Spinner
                  style={{ marginBottom: "5px" }}
                  animation="border"
                  size="sm"
                />
              )}{" "}
              {!isCodeValidating && validationMessage.message && (
                <div
                  className={
                    validationMessage.type === "error"
                      ? "text-danger"
                      : "text-success"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {validationMessage.message}
                </div>
              )}
              <div className="d-flex row gx-3 justify-content-between">
                <h1>Bike Images</h1>
                <p>
                  Photos should be taken in landscape (horizontal) orientation,
                  ideally with a 3:2 aspect ratio.
                </p>
                <div className="col-lg-6 col-md-6">
                  <p>
                    <b>Drive side</b>
                  </p>
                  <FilePond
                    files={filesDriveSide}
                    onupdatefiles={setFilesDriveSide}
                    allowMultiple={false}
                    instantUpload={false}
                    maxFiles={1}
                    server={pondServerConfig}
                    maxFileSize="10MB"
                    credits="false"
                    name="img_driveside"
                    labelIdle='Drag & Drop your drive side image or <span class="filepond--label-action">Browse</span>'
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <p>
                    <b>Non-drive side</b>
                  </p>
                  <FilePond
                    files={filesNonDriveSide}
                    onupdatefiles={setFilesNonDriveSide}
                    allowMultiple={false}
                    instantUpload={false}
                    maxFiles={1}
                    server={pondServerConfig}
                    maxFileSize="10MB"
                    credits="false"
                    name="img_nondriveside"
                    labelIdle='Drag & Drop your non-drive side image or <span class="filepond--label-action">Browse</span>'
                  />
                </div>
              </div>
              <button
                type="submit"
                className="form-button"
                disabled={!formValid || loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Uploading {progress > 0 ? `(${progress}%)` : ""}
                  </>
                ) : (
                  "Submit"
                )}
              </button>
              {serverResponse && (
                <div className={`message ${serverResponse.type}`}>
                  {serverResponse.message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewAuctionListing;
