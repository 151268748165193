import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState("");

  const getActionCodeFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get("oobCode");
  };

  const handleNewPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const actionCode = getActionCodeFromURL();
      if (!actionCode) throw new Error("Reset code is missing.");

      await FB.auth.confirmPasswordReset(actionCode, newPassword);
      setLoading(false);

      setTimeout(() => {
        history.push("/login");
      }, 1000);
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-section pt-160 pb-120">
      <div className="container">
        <div className="row d-flex justify-content-center g-4">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div
              className="form-wrapper wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <div className="form-title">
                <p>Reset Password</p>
              </div>

              <form className="w-100" onSubmit={handleNewPassword}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-inner">
                      {/* <label>Enter your new password</label> */}
                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter your new password"
                        required
                        title="Please enter your new password"
                      />
                    </div>
                  </div>
                </div>
                <button className="account-btn" type="submit">
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </form>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
