import React, { useContext, useEffect, useState } from "react";
import Counter from "../../common/Counter";
import { ListingContext } from "../auctionDetails/AuctionDetails";
import CustomImageGallery from "../../common/CustomImageGallery";
import BidModal from "../../common/BidModal";
import { useHistory } from "react-router-dom";
import FB from "../../../data/Firebase";
import FeeModal from "../../common/FeeModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function AuctionDetailsInfo({ imagesDoneLoading }) {
  const { auction } = useContext(ListingContext);
  const [bid, setBid] = useState(0);
  const [recentBid, setRecentBid] = useState(0);
  const [lastBidder, setLastBidder] = useState("");
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const [bidMessages, setBidMessages] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [resizedImageUrls, setResizedImageUrls] = useState([]);
  const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const auctionType =
    auction.reserve_requested === 0 ? "No Reserve" : "Reserve";
  const tooltipText =
    auction.reserve_requested === 0
      ? "This is a No Reserve auction where the item will be sold to the highest bidder regardless of a minimum price."
      : "This is a Reserve auction where there is a minimum price that must be met for the item to be sold.";

  // Callback function to be called when all images are loaded
  const handleAllImagesLoaded = () => {
    //console.log("images loaded");
    setImagesLoaded(true);
    imagesDoneLoading();
    // You can do any additional logic or actions here once all images are loaded.
    // For example, you can update other state variables or show/hide other components.
  };

  let unsubscribe;

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleOpenFeeModal() {
    setIsFeeModalOpen(true);
  }

  function handleCloseFeeModal() {
    setIsFeeModalOpen(false);
  }

  useEffect(() => {
    FB.auth.onAuthStateChanged((authUser) => {
      if (authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });
  }, []);

  useEffect(() => {
    if (auction.id) {
      const unsubscribe = FB.subscribeToStatus(auction.id, (status) => {
        // Handle the status update here
        // console.log("Auction status updated to: ", status);
      });
      return () => unsubscribe();
    }
  }, [auction.id]);

  useEffect(() => {
    let unsubscribe;
    if (auction.id) {
      unsubscribe = FB.subscribeToBidEndDate(auction.id, setEndDate);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auction.id]);

  useEffect(() => {
    let unsubscribe;
    if (auction.id) {
      unsubscribe = FB.getBidMessages(auction.id, setBidMessages);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auction.id]);

  useEffect(() => {
    if (bidMessages.length > 0) {
      setLastBidder(bidMessages[0].username);
    }
  }, [bidMessages]);

  useEffect(() => {
    if (auction && auction.current_bid) {
      const bid = auction.current_bid;
      setRecentBid(bid);
      //console.log(recentBid);
    }
  }, [auction]);

  const handleSignIn = () => {
    history.push({
      pathname: "/login",
      state: { from: `/auction-details/${auction.id}` },
    });
  };

  const handleRegister = () => {
    history.push({
      pathname: "/register",
      state: { from: `/auction-details/${auction.id}` },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (user) {
      if (userDetails.stripeCustomerId) {
        handleOpenModal();
      } else {
        alert("Please register to bid via your user dashboard.");
        history.push(`/dashboard`); // Redirect to the dashboard where user can register to bid
      }
    } else {
      history.push(`/login`); // Redirect to the login page
    }
  };

  useEffect(() => {
    if (auction) {
      //console.log(auction.resized_image_urls);
      setImageUrls(auction.image_urls || []);
      setResizedImageUrls(auction.resized_image_urls || {});
      console.log(resizedImageUrls);
    }
  }, [auction]);

  useEffect(() => {
    const getUserDetails = async () => {
      if (user) {
        const userDetails = await FB.getUserInfo(user.uid);
        setUserDetails(userDetails);
      }
    };

    getUserDetails();
  }, [auction]);

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0, // Ensures no decimal places
        maximumFractionDigits: 0, // Ensures rounding to the nearest dollar
      });
    }
  }

  function formatDate(timestamp) {
    const { seconds, nanoseconds } = timestamp;
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;
    let date = new Date(milliseconds);
    const formattedDate = date.toLocaleString();
    return formattedDate;
  }

  const handleInputChange = (event) => {
    setBid(event.target.value);
  };

  useEffect(() => {
    if (auction && auction.current_bid) {
      const bid = auction.current_bid;
      setRecentBid(bid);
      //console.log(recentBid);
    }
  }, [auction]);

  return (
    <>
      {isModalOpen && (
        <BidModal
          handleCloseModal={handleCloseModal}
          auctionId={auction.id}
          recentBid={recentBid}
          setRecentBid={setRecentBid}
          onClose={handleCloseModal}
          min={recentBid}
          max={20000}
        />
      )}
      {isFeeModalOpen && (
        <FeeModal
          handleCloseModal={handleCloseFeeModal}
          onClose={handleCloseFeeModal}
        />
      )}
      {/* <div className="sticky-auction-status">
        <Counter auction={auction} />
      </div> */}

      <div className="" style={{ marginTop: "0px" }}>
        {resizedImageUrls && (
          <div className="gallery-container">
            <CustomImageGallery
              originalUrls={imageUrls}
              resizedUrls={resizedImageUrls}
              onImagesLoad={handleAllImagesLoaded}
            />
          </div>
        )}

        <div className="auction-details">
          <h4>
            <b>{auction.title}</b>
          </h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0px 10px",
              gap: "10px",
            }}
          >
            {auction.reserve_requested === 0 && (
              <span className="no-reserve-tag">No Reserve</span>
            )}
            <h5 style={{ margin: "0px", fontSize: "16px" }}>
              {auction.short_description}
            </h5>
          </div>
        </div>

        <div>
          <table>
            <tbody>
              <tr>
                <td>Bid status:</td>
                <td>
                  {auction.status === "Sold" ? (
                    <b>{`${auction.status} for ${formatCurrency(
                      auction.current_bid
                    )} to @${lastBidder}`}</b>
                  ) : auction.status === "Reserve Not Met" ? (
                    <b>Reserve Not Met</b>
                  ) : auction.current_bid ? (
                    bidMessages.length === 0 ? (
                      <b>Opening Bid: {formatCurrency(auction.current_bid)}</b> // Show just the current bid if no bids
                    ) : (
                      <>
                        <b>{formatCurrency(auction.current_bid)}</b> by @
                        <b>{lastBidder}</b>
                      </> // Show bid and last bidder if there are bids
                    )
                  ) : (
                    <b>No bid yet</b> // Fallback if there's no current bid
                  )}
                </td>
              </tr>
              <tr>
                <td>Auction ends:</td>
                <td>
                  <b>{formatDate(endDate)}</b>
                </td>
              </tr>
              <tr>
                <td>Bids: </td>
                <td>
                  <b>{bidMessages.length}</b>
                </td>
              </tr>
              <tr>
                <td>Seller: </td>
                <td>
                  <b>{auction?.seller_username}</b>
                </td>
              </tr>
              <tr>
                <td>Location:</td>
                <td>
                  <b>
                    {auction?.city || auction?.bike_city},{" "}
                    {auction?.state || auction?.bike_state}{" "}
                    {auction?.zip || auction?.bike_zip}
                  </b>
                </td>
              </tr>
              {/* New row for Auction Type with Tooltip */}
              <tr>
                <td>Auction Type:</td>
                <td>
                  <b>
                    {auctionType}{" "}
                    <OverlayTrigger
                      key="tooltip"
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">{tooltipText}</Tooltip>
                      }
                    >
                      <span
                        className="tooltip-icon"
                        onClick={toggleTooltip}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-info-circle"></i>
                      </span>
                    </OverlayTrigger>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginTop: "15px", marginLeft: "10px" }}>
            {auction.watch_count} watchers | {auction.view_count} views
          </p>
          {/* <button
            className="form-button"
            type="submit"
            style={{
              width: "100%",
              marginTop: "30px",
              marginBottom: "0px",
            }}
            onClick={handleOpenFeeModal}
          >
            Fee Estimator
          </button> */}
          {status === "Live" &&
            (!user ? (
              <button
                className="form-button"
                type="submit"
                style={{ width: "100%", marginTop: "20px" }}
                onClick={handleSignIn}
              >
                Sign in to bid
              </button>
            ) : userDetails && userDetails.stripeCustomerId ? (
              <button
                className="form-button"
                type="submit"
                style={{
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
                onClick={handleSubmit}
              >
                Place Bid
              </button>
            ) : (
              <button
                className="form-button"
                type="submit"
                style={{
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
                onClick={handleRegister}
              >
                Register to bid
              </button>
            ))}
        </div>

        <div className="col-xl-12 col-lg-12">
          <div
            style={{
              paddingTop: "0px",
              paddingBottom: "20px",
              paddingLeft: "10px",
              padding: "10px",
            }}
          >
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              {/* <h4>What we like about this bike</h4> */}
            </div>
            <div
              className="auction-description"
              style={{ marginBottom: "20px" }}
              dangerouslySetInnerHTML={{ __html: auction.description }}
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default AuctionDetailsInfo;
