import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EmailSubscriptionModal from "../../common/EmailModal";

function HeroBanner() {
  const history = useHistory();

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  const openEmailModal = () => {
    setEmailModalVisible(true);
  };

  function handleSignUp() {
    history.push(`/signup`);
  }

  const closeEmailModal = () => {
    setEmailModalVisible(false);
  };

  return (
    <>
      {emailModalVisible && (
        <EmailSubscriptionModal
          show={emailModalVisible}
          onClose={closeEmailModal}
        />
      )}
      <div className="hero-area hero-style-three">
        <div className="container-fluid">
          <div className="row d-flex justify-content-start align-items-end">
            <div className="banner3-content">
              <div className="centered-content">
                <span
                  className="wow fadeInDown"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  Sell with Ease
                  <br /> Ride with Confidence
                </span>
                <p
                  className="wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="1s"
                >
                  At BidBikes, we curate a trusted marketplace where selling
                  your bike is hassle-free. Enjoy free listings and connect with
                  buyers across the U.S. Our dedicated community of enthusiasts
                  ensures your bike finds its perfect rider. List your bike
                  today and experience the ease of selling with BidBikes.
                </p>

                <button
                  className="form-button join-button wow fadeInUp"
                  style={{
                    maxWidth: "300px",
                  }}
                  onClick={handleSignUp}
                >
                  Join BidBikes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroBanner;
