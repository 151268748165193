import React from "react";
import Breadcrumb from "../common/Breadcrumb";

const TermsOfUsePage = () => {
  return (
    <>
      <Breadcrumb pageName="Terms of Use" pageTitle="Terms of Use" />
      <div className="signup-section pt-160 pb-120">
        <div className="container">
          <p>
            Effective date: October 19th, 2023<br></br>
            <br></br> Welcome to BidBikes! These terms of use govern your use of
            our website(s), products, services and applications (the{" "}
            <b>“Services”</b>). By accessing or using our Services, you agree to
            comply with these terms and any additional terms or policies we my
            provide. If you have any questions, comments, or concerns regarding
            these terms or the Services, please contact us at:<br></br>
            <br></br> Email: legal@BidBikes.com
            <br></br>
            <br></br>
            These Terms of Use (the <b>“Terms”</b>) are a binding contract
            between you and BidBikes, LLC (<b>“BidBikes,</b>” <b>“we”</b> and{" "}
            <b>“us”</b>). Your use of the Services in any way means that you
            agree to all of these Terms, and these Terms will remain in effect
            while you use the Services. These Terms include the provisions in
            this document as well as those in the Privacy Policy.
            <br></br>
            <br></br>
            <b>Please read these Terms carefully.</b> They cover important
            information about Services provided to you. 
            <b>
              These Terms include information about future changes to these
              Terms, limitations of liability, a class action waiver and
              resolution of disputes by arbitration instead of in court. PLEASE
              NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO
              THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING,
              YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
            </b>
            <br></br>
            <br></br>
            <b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b> EXCEPT FOR
            CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT
            SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE
            RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT
            TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
            <br></br>
            <br></br>
            <b>
              <u>Connecting Bike Sellers and Bidders</u>
            </b>
            <br></br>
            <br></br>
            BidBikes connects those offering bikes for sale (<b>“Sellers”</b>)
            and those looking to acquire bikes (<b>“Bidders”</b>). The Services
            act as a meeting place only; the actual contract for sale for each
            Bike listed on the Services is directly between the Seller and the
            winning Bidder.
            <br></br>
            <br></br>When we use the word “you” in these Terms, it refers to any
            user, regardless of whether he or she is a Seller, Bidder, other
            registered user or unregistered user, while if we use one of those
            specific terms, it only applies to that category of user.
            <br></br>
            <br></br>
            Before acquiring a bike from any Seller (each, a{" "}
            <b>“Seller Bike”</b>), Bidders are responsible for making their own
            determinations that the Seller Bike is suitable. BidBikes is only
            responsible for connecting Sellers and Bidders, and can’t and won’t
            be responsible for making sure that any Seller bike is up to a
            certain standard of quality. BidBikes similarly can’t and won’t be
            responsible for ensuring that information (including credentials)
            any Bidder or Seller provides about himself or herself or about any
            Seller bike he or she is offering is accurate or up-to-date. Each
            Seller acknowledges and agrees that they shall make reasonable
            efforts to complete the sale of their Seller Bike if there is a
            winning bid. Each Bidder acknowledges and agrees that if they are
            the winning bidder in an auction through the Services, their bid is
            binding on the Bidder and Bidder is responsible for all applicable
            government fees and taxes for the bicycle won through such bid.
            Notwithstanding the foregoing, we don’t control the actions of any
            Bidder or Seller, and Sellers aren’t our employees.
            <br></br>
            <br></br>
            <b>
              BidBikes DOES NOT DIRECTLY OFFER THE SELLER BIKES. YOU HEREBY
              ACKNOWLEDGE THAT BidBikes DOES NOT SUPERVISE, DIRECT, CONTROL OR
              MONITOR THE SELLER BIKES AND EXPRESSLY DISCLAIMS ANY
              RESPONSIBILITY AND LIABILITY FOR THE SELLER BIKES, INCLUDING BUT
              NOT LIMITED TO ANY WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR
              A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, REGULATION, OR
              CODE.
            </b>
            <br></br>
            <br></br>
            Sellers may not remove a bike made available for auction on the
            Services before the end of such auction. While a Seller Bike is
            listed on the Services, Seller may not list or make available such
            Seller Bike on any other auction, dealership, listing service or
            publication.
            <br></br>
            <br></br>
            <b>
              <u>Will these Terms ever change?</u>
            </b>
            <br></br>
            <br></br>
            We are constantly trying to improve our Services, so these Terms may
            need to change along with our Services. We reserve the right to
            change the Terms at any time, but if we do, we will place a notice
            on our site, send you an email, and/or notify you by some other
            means. If you don’t agree with the new Terms, you are free to reject
            them; unfortunately, that means you will no longer be able to use
            the Services. If you use the Services in any way after a change to
            the Terms is effective, that means you agree to all of the changes.
            Except for changes by us as described here, no other amendment or
            modification of these Terms will be effective unless in writing and
            signed by both you and us.
            <br></br>
            <br></br>
            <b>
              <u>What about my privacy?</u>
            </b>
            <br></br>
            <br></br> BidBikes takes the privacy of its users very seriously.
            For the current BidBikes Privacy Policy, please click here.
            <br></br>
            <br></br>
            <i>
              <u>Children’s Online Privacy Protection Act</u>
            </i>
            <br></br>
            <br></br> The Children’s Online Privacy Protection Act (“COPPA”)
            requires that online service providers obtain parental consent
            before they knowingly collect personally identifiable information
            online from children who are under thirteen (13). We do not
            knowingly collect or solicit personally identifiable information
            from children under thirteen (13); if you are a child under thirteen
            (13), please do not attempt to register for or otherwise use the
            Services or send us any personal information. If we learn we have
            collected personal information from a child under thirteen (13), we
            will delete that information as quickly as possible. If you believe
            that a child under thirteen (13) may have provided us personal
            information, please contact us at legal@BidBikes.com.
            <br></br>
            <br></br>
            <b>
              <u>What are the basics of using BidBikes?</u>
            </b>
            <br></br>
            <br></br>
            You may be required to sign up for an account, select a password and
            user name (“BidBikes User ID”), and provide us with certain
            information or data, such as your contact information. You promise
            to provide us with accurate, complete, and updated registration
            information about yourself. You may not select as your BidBikes User
            ID a name that you do not have the right to use, or another person’s
            name with the intent to impersonate that person. You may not
            transfer your account to anyone else without our prior written
            permission.
            <br></br>
            <br></br>
            You represent and warrant that you are an individual of legal age to
            form a binding contract (or if not, you’ve received your parent’s or
            guardian’s permission to use the Services and have gotten your
            parent or guardian to agree to these Terms on your behalf). If
            you’re agreeing to these Terms on behalf of an organization or
            entity, you represent and warrant that you are authorized to agree
            to these Terms on that organization’s or entity’s behalf and bind
            them to these Terms (in which case, the references to “you” and
            “your” in these Terms, except for in this sentence, refer to that
            organization or entity).
            <br></br>
            <br></br>
            You will only use the Services for your own internal, personal use,
            and not on behalf of or for the benefit of any third party. You will
            comply with all laws that apply to you, your use of the Services,
            and your actions and omissions that relate to the Services (for
            example, Sellers must comply with all laws that relate to the Seller
            Bikes). If your use of the Services is prohibited by applicable
            laws, then you aren’t authorized to use the Services. We can’t and
            won’t be responsible for your using the Services in a way that
            breaks the law.
            <br></br>
            <br></br>
            You will not share your BidBikes User ID, account or password with
            anyone, and you must protect the security of your BidBikes User ID,
            account, password and any other access tools or credentials. You’re
            responsible for any activity associated with your BidBikes User ID
            and account.
            <br></br>
            <br></br>
            <b>
              <u>What about messaging?</u>
            </b>
            <br></br>
            <br></br>As part of the Services, you may receive communications
            through the Services, including messages that BidBikes sends you
            (for example, via email). When signing up for the Services, you will
            receive a welcome message and instructions on how to stop receiving
            certain marketing messages.
            <br></br>
            <br></br>
            <b>
              <u>Are there restrictions in how I can use the Services?</u>
            </b>
            <br></br>
            <br></br>
            You represent, warrant, and agree that you will not contribute any
            Content or User Submission (each of those terms is defined below) or
            otherwise use the Services or interact with the Services in a manner
            that:
            <br></br>
            <br></br>
            (a)   infringes or violates the intellectual property rights or any
            other rights of anyone else (including BidBikes);
            <br></br>
            <br></br>
            (b)   violates any law or regulation, including, without limitation,
            any applicable export control laws, privacy laws or any other
            purpose not reasonably intended by BidBikes;
            <br></br>
            <br></br>
            (c)    is dangerous, harmful, fraudulent, deceptive, threatening,
            harassing, defamatory, obscene, or otherwise objectionable
            (including, without limitation, by creating multiple accounts for
            purposes of cheating or gaming the bidding system);
            <br></br>
            <br></br>
            (d)   jeopardizes the security of your BidBikes User ID, account or
            anyone else’s (such as allowing someone else to log in to the
            Services as you);
            <br></br>
            <br></br>
            (e)   attempts, in any manner, to obtain the password, account, or
            other security information from any other user;
            <br></br>
            <br></br>
            (f)    violates the security of any computer network, or cracks any
            passwords or security encryption codes;
            <br></br>
            <br></br>
            (g)   runs Maillist, Listserv, any form of auto-responder or “spam”
            on the Services, or any processes that run or are activated while
            you are not logged into the Services, or that otherwise interfere
            with the proper working of the Services (including by placing an
            unreasonable load on the Services’ infrastructure);
            <br></br>
            <br></br>
            (h)   “crawls,” “scrapes,” or “spiders” any page, data, or portion
            of or relating to the Services or Content (through use of manual or
            automated means);
            <br></br>
            <br></br>
            (i)     copies or stores any significant portion of the Content; or
            <br></br>
            <br></br>
            (j)     decompiles, reverse engineers, or otherwise attempts to
            obtain the source code or underlying ideas or information of or
            relating to the Services.
            <br></br>
            <br></br>A violation of any of the foregoing is grounds for
            termination of your right to use or access the Services.
            <br></br>
            <br></br>
            <b>
              <u>How may I use information obtained from other users?</u>
            </b>{" "}
            You may not collect any information from or relating to another user
            (<b>“User Information”</b>), whether via the Services, in the course
            of offering or making an offer on Seller Bikes, as applicable, or
            otherwise, beyond what is necessary to complete your diligence and
            the auction transaction. Upon the conclusion of an auction, you must
            properly destroy all User Information from or relating to such user
            and make no further use of it whatsoever. You must collect, use,
            maintain, and transmit all User Information in compliance with all
            applicable laws.
            <br></br>
            <br></br>
            <b>
              <u>What are my rights in the Services?</u>
            </b>
            <br></br>
            <br></br>
            The materials displayed or performed or available on or through the
            Services, including, but not limited to, text, graphics, data,
            articles, photos, images, illustrations, User Submissions (as
            defined below) and so forth (all of the foregoing, the{" "}
            <b>“Content”</b>) are protected by copyright and/or other
            intellectual property laws. You promise to abide by all copyright
            notices, trademark rules, information, and restrictions contained in
            any Content you access through the Services, and you won’t use,
            copy, reproduce, modify, translate, publish, broadcast, transmit,
            distribute, perform, upload, display, license, sell, commercialize
            or otherwise exploit for any purpose any Content not owned by you,
            (i) without the prior consent of the owner of that Content or (ii)
            in a way that violates someone else’s (including BidBikes’) rights.
            <br></br>
            <br></br>
            Subject to these Terms, we grant each user of the Services a
            worldwide, non-exclusive, non-sublicensable and non-transferable
            license to use (i.e., to download and display locally) Content
            solely for purposes of using the Services. Use, reproduction,
            modification, distribution or storage of any Content for any purpose
            other than using the Services is expressly prohibited without prior
            written permission from us. You understand that BidBikes owns the
            Services. You won’t modify, publish, transmit, participate in the
            transfer or sale of, reproduce (except as expressly provided in this
            Section), create derivative works based on, or otherwise exploit any
            of the Services. The Services may allow you to copy or download
            certain Content, but please remember that even where these
            functionalities exist, all the restrictions in this section still
            apply.
            <br></br>
            <br></br>
            <b>
              <u>
                What about anything I contribute to the Services – do I have to
                grant any licenses to BidBikes or to other users?
              </u>
            </b>
            <br></br>
            <br></br>{" "}
            <u>
              <i>User Submissions</i>
            </u>
            <br></br>
            <br></br>
            Anything you post, upload, share, store, or otherwise provide
            through the Services is your “User Submission”. Some User
            Submissions may be viewable by other users. You are solely
            responsible for all User Submissions you contribute to the Services.
            You represent that all User Submissions submitted by you are
            accurate, complete, up-to-date, and in compliance with all
            applicable laws, rules and regulations.
            <br></br>
            <br></br>
            You agree that you will not post, upload, share, store, or otherwise
            provide through the Services any User Submissions that: (i) infringe
            any third party’s copyrights or other rights (e.g., trademark,
            privacy rights, etc.); (ii) contain sexually explicit content or
            pornography; (iii) contain hateful, defamatory, or discriminatory
            content or incite hatred against any individual or group; (iv)
            exploit minors; (v) depict unlawful acts or extreme violence; (vi)
            depict animal cruelty or extreme violence towards animals; (vii)
            promote fraudulent schemes, multi-level marketing (MLM) schemes, get
            rich quick schemes, online gaming and gambling, cash gifting, work
            from home businesses, or any other dubious money-making ventures; or
            (viii) violate any law.
            <br></br>
            <br></br>
            <u>
              <i>Licenses</i>
            </u>
            <br></br>
            <br></br>
            In order to display your User Submissions on the Services, and to
            allow other users to enjoy them (where applicable), you grant us
            certain rights in those User Submissions (see below for more
            information). Please note that all of the following licenses are
            subject to our Privacy Policy to the extent they relate to User
            Submissions that are also your personally-identifiable information.
            <br></br>
            <br></br>
            By submitting User Submissions through the Services, you hereby do
            and shall grant BidBikes a worldwide, non-exclusive, perpetual,
            royalty-free, fully paid, sublicensable and transferable license to
            use, edit, modify, truncate, aggregate, reproduce, distribute,
            prepare derivative works of, display, perform, and otherwise fully
            exploit the User Submissions in connection with this site, the
            Services and our (and our successors’ and assigns’) businesses,
            including without limitation for promoting and redistributing part
            or all of this site or the Services (and derivative works thereof)
            in any media formats and through any media channels (including,
            without limitation, third party websites and feeds), and including
            after your termination of your account or the Services. You also
            hereby do and shall grant each user of this site and/or the Services
            a non-exclusive, perpetual license to access your User Submissions
            through this site and/or the Services, and to use, edit, modify,
            reproduce, distribute, prepare derivative works of, display and
            perform such User Submissions, including after your termination of
            your account or the Services. For clarity, the foregoing license
            grants to us and our users do not affect your other ownership or
            license rights in your User Submissions, including the right to
            grant additional licenses to your User Submissions, unless otherwise
            agreed in writing. You represent and warrant that you have all
            rights to grant such licenses to us without infringement or
            violation of any third party rights, including without limitation,
            any privacy rights, publicity rights, copyrights, trademarks,
            contract rights, or any other intellectual property or proprietary
            rights.
            <br></br>
            <br></br>
            Finally, you understand and agree that BidBikes, in performing the
            required technical steps to provide the Services to our users
            (including you), may need to make changes to your User Submissions
            to conform and adapt those User Submissions to the technical
            requirements of connection networks, devices, services, or media,
            and the foregoing licenses include the rights to do so.
            <br></br>
            <br></br>
            <b>
              <u>Who is responsible for what I see and do on the Services?</u>
            </b>
            <br></br>
            <br></br>
            Any information or Content publicly posted or privately transmitted
            through the Services is the sole responsibility of the person from
            whom such Content originated, and you access all such information
            and Content at your own risk, and we aren’t liable for any errors or
            omissions in that information or Content or for any damages or loss
            you might suffer in connection with it. We cannot control and have
            no duty to take any action regarding how you may interpret and use
            the Content or what actions you may take as a result of having been
            exposed to the Content, and you hereby release us from all liability
            for you having acquired or not acquired Content through the
            Services. We can’t guarantee the identity of any users with whom you
            interact in using the Services and are not responsible for which
            users gain access to the Services.
            <br></br>
            <br></br>
            You are responsible for all Content you contribute, in any manner,
            to the Services, and you represent and warrant you have all rights
            necessary to do so, in the manner in which you contribute it.
            <br></br>
            <br></br>
            The Services may contain links or connections to third-party
            websites or services that are not owned or controlled by BidBikes.
            When you access third-party websites or use third-party services,
            you accept that there are risks in doing so, and that BidBikes is
            not responsible for such risks.
            <br></br>
            <br></br>
            BidBikes has no control over, and assumes no responsibility for, the
            content, accuracy, privacy policies, or practices of or opinions
            expressed in any third-party websites or by any third party that you
            interact with through the Services. In addition, BidBikes will not
            and cannot monitor, verify, censor or edit the content of any
            third-party site or service. We encourage you to be aware when you
            leave the Services and to read the terms and conditions and privacy
            policy of each third-party website or service that you visit or
            utilize. By using the Services, you release and hold us harmless
            from any and all liability arising from your use of any third-party
            website or service. If there is a dispute between participants on
            this site or Services, or between users and any third party, you
            agree that BidBikes is under no obligation to become involved. In
            the event that you have a dispute with one or more other users, you
            release BidBikes, its directors, officers, employees, agents, and
            successors from claims, demands, and damages of every kind or
            nature, known or unknown, suspected or unsuspected, disclosed or
            undisclosed, arising out of or in any way related to such disputes
            and/or our Services. You shall and hereby do waive California Civil
            Code Section 1542 or any similar law of any jurisdiction, which says
            in substance: “A general release does not extend to claims that the
            creditor or releasing party does not know or suspect to exist in his
            or her favor at the time of executing the release and that, if known
            by him or her, would have materially affected his or her settlement
            with the debtor or released party.”
            <br></br>
            <br></br>
            <b>
              <u>Will BidBikes ever change the Services?</u>
            </b>
            <br></br>
            <br></br> We’re always trying to improve our Services, so they may
            change over time. We may suspend or discontinue any part of the
            Services, or we may introduce new features or impose limits on
            certain features or restrict access to parts or all of the Services.
            We reserve the right to remove any Content from the Services at any
            time, for any reason (including, but not limited to, if someone
            alleges you contributed that Content in violation of these Terms),
            in our sole discretion, and without notice.
            <br></br>
            <br></br>
            <b>
              <u>Do the Services cost anything?</u>
            </b>
            <br></br>
            <br></br>
            It is free to register for the Services, but certain aspects of the
            Services may require that you pay us fees. If you decide to use
            these paid aspects of the Services, you agree to the Fees Terms set
            forth below, as we may amend them from time to time.
            <br></br>
            <br></br>
            We reserve the right to charge for certain or all Services in the
            future. We will notify you before any Services you are then using
            begin carrying a fee, and if you wish to continue using such
            Services, you must pay all applicable fees for such Services. You
            must pay all fees and applicable taxes associated with our Services
            by the payment due date (including, without limitation, any fees
            incurred in connection with the use of our third-party payment
            processor, Stripe). Stripe’s terms of service can be found at
            https://stripe.com/legal.
            <br></br>
            <br></br>
            <b>
              <i>Fees</i>
            </b>
            <br></br>
            <br></br>
            <i>Listing Fees</i>
            <br></br>
            <br></br>
            There are currently no Seller listing fees to list a Seller Bike on
            the Services.
            <br></br>
            <br></br>
            <i>Buyer Fees</i>
            <br></br>
            <br></br>
            If you are the winning bidder for a Bike listed on the Services, you
            shall pay us a fee (a “Buyer’s Fee”) equal to 5% of the winning bid
            amount listed on the Services. Notwithstanding the foregoing, for
            each winning bid on the Services, the minimum Buyer’s Fee is $25,
            and the maximum Buyer’s Fee is $500. All Buyer’s Fees are
            non-refundable. If you place a bid on the Services, we will place a
            hold on your credit card in an amount equal to 5% of your bid amount
            (a minimum of $25, up to a maximum of $500). We will remove all such
            holds – except the hold on the winning Bidder’s credit card – after
            the auction is completed. The hold on the winning Bidder’s credit
            card will be charged as payment for the Buyer’s Fee immediately
            after the auction listing ends. Please visit our “FAQs” page for
            more information about our reserve sales and other product features.
            <br></br>
            <br></br>
            <i>Seller Payments</i>
            <br></br>
            <br></br>
            Within ninety (90) days after the end of an auction, Seller must
            provide BidBikes with information it reasonably requires to process
            any payments to Seller under the Services. This information may
            include, without limitation, PayPal or bank account information and
            a current IRS Form W-9. If Seller fails to comply with the
            foregoing, BidBikes shall not be liable for, and Seller waives, any
            and all payment obligations then owed by BidBikes to Seller.
            <br></br>
            <br></br>
            <i>PayPal Fees</i>
            <br></br>
            <br></br>
            Some sellers on BidBikes may choose to receive payments via PayPal.
            Please note that PayPal charges fees for processing transactions.
            When a seller opts for PayPal as their payment method, the PayPal
            fees will be deducted from the final auction proceeds. These fees
            are determined by PayPal's fee structure and are the responsibility
            of the seller. The exact fee amount may vary based on the seller's
            location and currency.
            <br></br>
            <br></br>
            Please keep in mind that our platform's fees, including our
            commission, are automatically collected through Stripe and are
            separate from PayPal's fees. We encourage users to review the total
            costs, including any applicable fees, before finalizing
            transactions.
            <br></br>
            <br></br>
            <b>
              <u>What if I want to stop using the Services? </u>
            </b>
            <br></br>
            <br></br>
            You’re free to do that at any time by contacting us
            at legal@BidBikes.com; please refer to our Privacy Policy, as well
            as the licenses above, to understand how we treat information you
            provide to us after you have stopped using our Services. BidBikes is
            also free to terminate (or suspend access to) your use of the
            Services or your account for any reason in our discretion, including
            your breach of these Terms.
            <br></br>
            <br></br>
            BidBikes has the sole right to decide whether you are in violation
            of any of the restrictions set forth in these Terms; for example, a
            Bidder who believes that a Seller may be in breach of these Terms is
            not able to enforce these Terms against that Seller.
            <br></br>
            <br></br>
            Account termination may result in destruction of any Content
            associated with your account, so keep that in mind before you decide
            to terminate your account.
            <br></br>
            <br></br>
            If you have deleted your account by mistake, contact us immediately
            at legal@BidBikes.com – we will try to help, but unfortunately, we
            can’t promise that we can recover or restore anything.
            <br></br>
            <br></br>
            Provisions that, by their nature, should survive termination of
            these Terms shall survive termination. By way of example, all of the
            following will survive termination: any obligation you have to pay
            us or indemnify us, any limitations on our liability, any terms
            regarding ownership or intellectual property rights, and terms
            regarding disputes between us, including without limitation the
            arbitration agreement.
            <br></br>
            <br></br>
            <b>
              <u>What else do I need to know?</u>
            </b>
            <br></br>
            <br></br>
            <u>
              <i>Warranty Disclaimer.</i>
            </u>
             BidBikes and its licensors, suppliers, partners, parent,
            subsidiaries or affiliated entities, and each of their respective
            officers, directors, members, employees, consultants, contract
            employees, representatives and agents, and each of their respective
            successors and assigns (BidBikes and all such parties together, the
            <b>“BidBikes Parties”</b>) make no representations or warranties
            concerning the Services, including without limitation regarding any
            Content contained in or accessed through the Services, and the
            BidBikes Parties will not be responsible or liable for the accuracy,
            copyright compliance, legality, or decency of material contained in
            or accessed through the Services or any claims, actions, suits
            procedures, costs, expenses, damages or liabilities arising out of
            use of, or in any way related to your participation in, the
            Services. The BidBikes Parties make no representations or warranties
            regarding suggestions or recommendations of services or products
            offered or purchased through or in connection with the Services
            including, without limitation, any Seller Bikes. Seller Bikes and
            services purchased or offered (whether or not following such
            recommendations and suggestions) through the Services are provided
            “AS-IS” and without any warranty of any kind from the BidBikes
            Parties or others (unless, with respect to such others only,
            provided expressly and unambiguously in writing by a designated
            third party for a specific product). THE SERVICES AND CONTENT ARE
            PROVIDED BY BidBikes (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS”
            BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
            OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
            <br></br>
            <br></br>
            BidBikes (I) MAKES NO REPRESENTATIONS OR WARRANTIES OR ASSUME
            RESPONSIBILITY FOR ANY BIKE ADVERTISED OR OFFERED BY A SELLER
            THROUGH THE SERVICE (OR ANY HYPERLINKED WEBSITE OR SERVICE) AND (II)
            WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN
            YOU AND A BIDDER OR OTHER THIRD-PARTY PROVIDER.
            <br></br>
            <br></br>
            SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY
            LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            <u>
              <i>Limitation of Liability.</i>
            </u>{" "}
            TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
            CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
            LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL
            ANY OF THE BidBikes PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON
            FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL
            DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS
            INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE,
            ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY
            SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE
            AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100)
            DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU
            TO BidBikes IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH
            PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR
            REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES,
            SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
            <br></br>
            <br></br>
            <u>
              <i>Indemnity.</i>
            </u>
            You agree to indemnify and hold the BidBikes Parties harmless from
            and against any and all claims, liabilities, damages (actual and
            consequential), losses and expenses (including attorneys’ fees)
            arising from or in any way related to any claims relating to (a)
            your use of the Services (including any actions taken by a third
            party using your account), and (b) your violation of these Terms. In
            the event of such a claim, suit, or action (<b>“Claim”</b>), we will
            attempt to provide notice of the Claim to the contact information we
            have for your account (provided that failure to deliver such notice
            shall not eliminate or reduce your indemnification obligations
            hereunder).
            <br></br>
            <br></br>
            <u>
              <i>Assignment.</i>
            </u>
            You may not assign, delegate or transfer these Terms or your rights
            or obligations hereunder, or your Services account, in any way (by
            operation of law or otherwise) without BidBikes' prior written
            consent. We may transfer, assign, or delegate these Terms and our
            rights and obligations without consent. Choice of Law. These Terms
            are governed by and will be construed under the Federal Arbitration
            Act, applicable federal law, and the laws of the State of
            California, without regard to the conflicts of laws provisions
            thereof.
            <br></br>
            <br></br>
            <u>
              <i>Arbitration Agreement.</i>
            </u>
             Please read the following ARBITRATION AGREEMENT carefully because
            it requires you to arbitrate certain disputes and claims with
            BidBikes and limits the manner in which you can seek relief from
            BidBikes. Both you and BidBikes acknowledge and agree that for the
            purposes of any dispute arising out of or relating to the subject
            matter of these Terms, BidBikes' officers, directors, employees and
            independent contractors (<b>“Personnel”</b>) are third-party
            beneficiaries of these Terms, and that upon your acceptance of these
            Terms, Personnel will have the right (and will be deemed to have
            accepted the right) to enforce these Terms against you as the
            third-party beneficiary hereof.
            <br></br>
            <br></br>
            <u>
              <i>
                (a) Arbitration Rules; Applicability of Arbitration Agreement.
              </i>
            </u>
            The parties shall use their best efforts to settle any dispute,
            claim, question, or disagreement arising out of or relating to the
            subject matter of these Terms directly through good-faith
            negotiations, which shall be a precondition to either party
            initiating arbitration. If such negotiations do not resolve the
            dispute, it shall be finally settled by binding arbitration in San
            Diego County, California. The arbitration will proceed in the
            English language, in accordance with the JAMS
            Streamlined Arbitration Rules and Procedures (the <b>“Rules”</b>)
            then in effect, by one commercial arbitrator with substantial
            experience in resolving intellectual property and commercial
            contract disputes. The arbitrator shall be selected from the
            appropriate list of JAMS arbitrators in accordance with such Rules.
            Judgment upon the award rendered by such arbitrator may be entered
            in any court of competent jurisdiction.
            <br></br>
            <br></br>
            <u>
              <i>(b) Costs of Arbitration.</i>
            </u>
            The Rules will govern payment of all arbitration fees. BidBikes will
            pay all arbitration fees for claims less than seventy-five thousand
            ($75,000) dollars. BidBikes will not seek its attorneys’ fees and
            costs in arbitration unless the arbitrator determines that your
            claim is frivolous.
            <br></br>
            <br></br>
            <u>
              <i>(c) Small Claims Court; Infringement.</i>
            </u>
            Either you or BidBikes may assert claims, if they qualify, in small
            claims court in San Diego County, California or any United States
            county where you live or work. Furthermore, notwithstanding the
            foregoing obligation to arbitrate disputes, each party shall have
            the right to pursue injunctive or other equitable relief at any
            time, from any court of competent jurisdiction, to prevent the
            actual or threatened infringement, misappropriation or violation of
            a party's copyrights, trademarks, trade secrets, patents or other
            intellectual property rights.
            <br></br>
            <br></br>
            <u>
              <i>(d) Waiver of Jury Trial.</i>
            </u>
            YOU AND BidBikes WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO
            TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and
            BidBikes are instead choosing to have claims and disputes resolved
            by arbitration. Arbitration procedures are typically more limited,
            more efficient, and less costly than rules applicable in court and
            are subject to very limited review by a court. In any litigation
            between you and BidBikes over whether to vacate or enforce an
            arbitration award, YOU AND BidBikes WAIVE ALL RIGHTS TO A JURY
            TRIAL, and elect instead to have the dispute be resolved by a judge.
            <br></br>
            <br></br>
            <u>
              <i>(e) Waiver of Class or Consolidated Actions. </i>
            </u>
            ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND
            NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE
            OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or
            consolidated actions is deemed invalid or unenforceable, neither you
            nor BidBikes is entitled to arbitration; instead all claims and
            disputes will be resolved in a court as set forth in (g) below.
            <br></br>
            <br></br>
            <u>
              <i>(f) Opt-out.</i>
            </u>
            You have the right to opt out of the provisions of this Section by
            sending written notice of your decision to opt out to the following
            address: “350 10th Avenue, Suite 1000, San Diego, California 92101”
            postmarked within thirty (30) days of first accepting these Terms.
            You must include (i) your name and residence address, (ii) the email
            address and/or telephone number associated with your account, and
            (iii) a clear statement that you want to opt out of these Terms’
            arbitration agreement.
            <br></br>
            <br></br>
            <u>
              <i>(g) Exclusive Venue.</i>
            </u>{" "}
            If you send the opt-out notice in (f), and/or in any circumstances
            where the foregoing arbitration agreement permits either you or
            BidBikes to litigate any dispute arising out of or relating to the
            subject matter of these Terms in court, then the foregoing
            arbitration agreement will not apply to either party, and both you
            and BidBikes agree that any judicial proceeding (other than small
            claims actions) will be brought in the state or federal
            courts located in, respectively, San Diego County, California, or
            the federal district in which that county falls.
            <br></br>
            <br></br>
            <u>
              <i>(h) Severability.</i>
            </u>{" "}
            If the prohibition against class actions and other claims brought on
            behalf of third parties contained above is found to be
            unenforceable, then all of the preceding language in this
            Arbitration Agreement section will be null and void. This
            arbitration agreement will survive the termination of your
            relationship with BidBikes.
            <br></br>
            <br></br>
            <u>
              <i>Miscellaneous.</i>
            </u>{" "}
            You will be responsible for paying, withholding, filing, and
            reporting all taxes, duties, and other governmental assessments
            associated with your activity in connection with the Services,
            provided that the BidBikes may, in its sole discretion, do any of
            the foregoing on your behalf or for itself as it sees fit. The
            failure of either you or us to exercise, in any way, any right
            herein shall not be deemed a waiver of any further rights hereunder.
            If any provision of these Terms are found to be unenforceable or
            invalid, that provision will be limited or eliminated, to the
            minimum extent necessary, so that these Terms shall otherwise remain
            in full force and effect and enforceable. You and BidBikes agree
            that these Terms are the complete and exclusive statement of the
            mutual understanding between you and BidBikes, and that these Terms
            supersede and cancel all previous written and oral agreements,
            communications and other understandings relating to the subject
            matter of these Terms. You hereby acknowledge and agree that you are
            not an employee, agent, partner, or joint venture of BidBikes, and
            you do not have any authority of any kind to bind BidBikes in any
            respect whatsoever. Except as expressly set forth in the section
            above regarding the arbitration agreement, you and BidBikes agree
            there are no third-party beneficiaries intended under these Terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfUsePage;
