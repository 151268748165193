import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "./Dashboard";
import { Spinner } from "react-bootstrap";
import FB from "../../../data/Firebase";
import PaymentForm from "./PaymentForm";
import AddCardModal from "./AddCardModal";
import { XCircle } from "react-bootstrap-icons";
import ReferrerSignupModal from "../../common/ReferrerSignupModal"; // Replace with the correct path to your ReferrerSignupModal component

import visaLogo from "../../../assets/images/icons/visa.png";
import mastercardLogo from "../../../assets/images/icons/mastercard.png";
import amexLogo from "../../../assets/images/icons/amex.png";
import discoverLogo from "../../../assets/images/icons/discover.png";

function ContentOfProfile() {
  const [openEye, setOpenEye] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, userDetails, setUserDetails, updateUserDetails } =
    useContext(UserContext);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [initials, setInitials] = useState("");
  const canvasRef = useRef(null);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [savedCards, setSavedCards] = useState([]);
  const [isOnlyOneCard, setIsOnlyOneCard] = useState(false);
  const [showReferrerSignupModal, setShowReferrerSignupModal] = useState(false);

  const openReferrerSignupModal = () => {
    setShowReferrerSignupModal(true);
  };

  const generateInitials = (fullName) => {
    const names = fullName.split(" ");
    const initials = names
      .map((name) => name.charAt(0))
      .join("")
      .toUpperCase();
    return initials;
  };

  const cardLogos = {
    visa: visaLogo,
    mastercard: mastercardLogo,
    amex: amexLogo,
    discover: discoverLogo,
  };

  async function deleteCard(card) {
    // Construct the message using card details
    const message = `Are you sure you want to delete the ${card.brand} card ending in ${card.last4} (Exp: ${card.expMonth}/${card.expYear})?`;

    // Show a confirmation dialog with the detailed message
    const userConfirmed = window.confirm(message);

    // If user clicks "Cancel", do nothing
    if (!userConfirmed) return;

    try {
      setIsLoading(true);
      const cardId = card.id;
      const deleteResponse = await FB.deleteSavedCard(cardId);

      if (deleteResponse.success) {
        // Successfully deleted, now update UI
        loadCards();
      } else {
        // Handle the case where there is an error
        console.error("Error deleting card:", deleteResponse.error);
        // You can show a notification to the user or handle the error as needed
      }
    } catch (error) {
      console.error("Error deleting card:", error);
      // Handle error, maybe show a notification to the user
    } finally {
      setIsLoading(false);
    }
  }

  const loadCards = async () => {
    setIsLoading(true);
    const cards = await FB.fetchSavedCards();
    setSavedCards(cards);
    setIsOnlyOneCard(cards.length === 1);
    setIsLoading(false);
  };

  useEffect(() => {
    loadCards();
  }, []);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setShowAddCardModal(true);
  };

  async function handleCloseModal() {
    setShowAddCardModal(false);
    await loadCards();
  }

  function handleRegisterToBid(event) {
    event.preventDefault();

    // Show the PaymentForm only if the key is valid
    history.push("/register");
  }

  function handleAdminDashboard() {
    history.push("/admin"); // replace '/edit-auction' with the path of the desired page
  }

  function handleEditUser() {
    history.push(`/user/${user.uid}/edit`); // replace '/edit-auction' with the path of the desired page
  }

  function formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      // Remove all non-digit characters from the phone number
      const cleaned = phoneNumber.replace(/\D/g, "");

      // Apply formatting based on the cleaned phone number
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }

      // Return the original phone number if it couldn't be formatted
      return phoneNumber;
    }
  }

  useEffect(() => {
    if (userDetails?.firstName && userDetails?.lastName) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const initials = generateInitials(
        `${userDetails.firstName} ${userDetails.lastName}`
      );

      // Customize the canvas styling
      const canvasSize = 80;
      const halfSize = canvasSize / 2;
      const fontSize = 36;
      const font = `${fontSize}px Arial`;
      const textAlign = "center";
      const textHeight = fontSize;
      const verticalPosition = halfSize + textHeight / 8;

      canvas.width = canvasSize;
      canvas.height = canvasSize;
      context.arc(halfSize, halfSize, halfSize, 0, 2 * Math.PI); // Define a circular path
      context.fillStyle = "#26a96c"; // Customize the background color
      context.fill(); // Fill the circular path
      context.font = font;
      context.fillStyle = "#fff"; // Customize the text color
      context.textBaseline = "middle"; // Center the text vertically
      context.textAlign = textAlign;
      context.fillText(initials, halfSize, verticalPosition);
    }
  }, [userDetails]);

  const handleAddReferrer = (event) => {
    event.preventDefault();
    openReferrerSignupModal();
  };

  const fetchAndUpdateUserDetails = async () => {
    try {
      // Replace with your actual fetch logic
      const updatedUserDetails = await FB.getUserInfo(user.uid);
      console.log("Updated User Details: ", updatedUserDetails); // Debug log

      setUserDetails(updatedUserDetails); // Update context
      setUserInfo(updatedUserDetails); // Update local state if needed
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleCloseReferrerModal = () => {
    setShowReferrerSignupModal(false);
    updateUserDetails();
    //    fetchAndUpdateUserDetails(); // Fetch updated user details after modal closes
  };

  return (
    <>
      {showAddCardModal && (
        <AddCardModal show={showAddCardModal} onClose={handleCloseModal} />
      )}
      {showReferrerSignupModal && (
        <ReferrerSignupModal
          show={showReferrerSignupModal}
          onClose={handleCloseReferrerModal}
        />
      )}
      <div
        className="tab-pane fade show active"
        id="v-pills-profile"
        role="tabpanel"
        aria-labelledby="v-pills-profile-tab"
        style={{ minHeight: "800px" }}
      >
        <div className="dashboard-profile">
          {userDetails?.firstName && userDetails?.lastName ? (
            <div
              className="owner"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="image">
                <canvas ref={canvasRef}></canvas>
              </div>
              <div className="content">
                <h3>
                  {userDetails?.firstName} {userDetails?.lastName}
                </h3>
              </div>
            </div>
          ) : null}

          <div className="form-wrapper">
            <form action="#">
              <div className="row">
                <p className="para">Contact Information</p>
                <table>
                  <tbody>
                    {/* <tr className="table-row">
                      <td>Identity validated:</td>
                      <td>Yes</td>
                    </tr> */}
                    <tr className="table-row">
                      <td>Username:</td>
                      <td>{userDetails?.username}</td>
                    </tr>
                    <tr className="table-row">
                      <td>First Name:</td>
                      <td>{userDetails?.firstName}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Last Name:</td>
                      <td>{userDetails?.lastName}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Phone:</td>
                      <td>{formatPhoneNumber(userDetails?.phoneNumber)}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Email:</td>
                      <td>{userDetails?.email}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Location:</td>
                      <td>
                        {userDetails?.city
                          ? `${userDetails.city}, ${userDetails.state} ${userDetails.zip}`
                          : null}
                      </td>
                    </tr>
                    <tr className="table-row">
                      <td>
                        Registered to bid: <br />
                        {/* <i>Registered Bidding in Test Mode Status</i> */}
                      </td>
                      <td>
                        {userDetails?.stripeCustomerId ? (
                          "Yes"
                        ) : (
                          <>
                            <div className="button-group">
                              <button
                                className="profile-btn"
                                onClick={handleRegisterToBid}
                                // disabled={!userDetails?.isValid} // Disable the button if the form fields are not valid
                              >
                                {loading ? (
                                  <>
                                    <Spinner animation="border" size="sm" />{" "}
                                    Registering
                                  </>
                                ) : (
                                  "Register to bid"
                                )}
                              </button>
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr className="table-row">
                      <td>Referral status:</td>
                      <td>
                        {userDetails?.referralCode ? (
                          "Signed up"
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                            className="button-group"
                          >
                            <button
                              onClick={handleAddReferrer}
                              className="profile-btn"
                            >
                              Sign Up
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr className="table-row">
                      <td>Saved Cards:</td>
                      <td>
                        {isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : savedCards.length ? (
                          savedCards.map((card, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                minHeight: "50px",
                                maxWidth: "200px",
                              }}
                              className="card-detail"
                            >
                              <img
                                src={cardLogos[card.brand.toLowerCase()]}
                                alt={card.brand}
                                style={{
                                  width: "40px",
                                  height: "auto",
                                  marginRight: "10px",
                                }}
                              />
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  **** {card.last4}
                                  {card.isDefault && (
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        backgroundColor: "#26a96c",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "2px 6px",
                                        fontSize: "10px",
                                        letterSpacing: "0.5px",
                                      }}
                                    >
                                      Default
                                    </span>
                                  )}
                                </div>
                                <div>
                                  Exp {card.expMonth}/{card.expYear}
                                </div>
                              </div>
                              {!isOnlyOneCard && (
                                <XCircle
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                  onClick={() => deleteCard(card)}
                                />
                              )}
                            </div>
                          ))
                        ) : (
                          "No saved cards"
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                          className="button-group"
                        >
                          {" "}
                          {userDetails?.stripeCustomerId && (
                            <button
                              onClick={handleOpenModal}
                              className="profile-btn"
                              //   style={{ margin: "10px 0px", minWidth: "150px" }}
                            >
                              Add Card
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="col-12">
                  {/* <div>
                    <textarea value={prompt} onChange={handlePromptChange} />
                    <button onClick={handleCallFunction}>Call Function</button>
                    <p>Completion: {completion}</p>
                  </div> */}
                  <div
                    className="button-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    {userDetails?.role === "admin" && (
                      <button
                        className="profile-btn"
                        onClick={handleAdminDashboard}
                        style={{ margin: "10px 0px", minWidth: "150px" }}
                      >
                        Dashboard
                      </button>
                    )}
                    <button
                      onClick={handleEditUser}
                      className="profile-btn"
                      style={{ margin: "10px 0px", minWidth: "150px" }}
                    >
                      Update Contact
                    </button>
                    {/* {userDetails?.stripeCustomerId && (
                      <button
                        onClick={handleOpenModal}
                        className="profile-btn"
                        style={{ margin: "10px 0px", minWidth: "150px" }}
                      >
                        Add Card
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContentOfProfile;
