import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import FB from "../../../data/Firebase";
import { UserContext } from "./Dashboard";

function ContentOfWatchList() {
  const { user, userDetails } = useContext(UserContext);
  const [auctions, setAuctions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [auctionsPerPage, setAuctionsPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [watchList, setWatchList] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const watchlist = await FB.getWatchlist();
      setWatchList(watchlist);
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    async function fetchAuctions() {
      if (watchList && watchList.length > 0) {
        const auctions = await FB.getAuctionsByIds(watchList);
        setAuctions(auctions);
      }
    }
    fetchAuctions();
  }, [watchList]);

  function formatCurrency(value) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  function formatDate(timestamp) {
    // Convert timestamp object to Date object
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );

    // Format date as string
    const formattedDate = date.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return formattedDate;
  }

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePrevPageClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const displayedAuctions = auctions.slice(startIndex, endIndex);

  const numPages = Math.ceil(auctions.length / pageSize);
  const pages = [];
  for (let i = 0; i < numPages; i++) {
    pages.push(i);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setPageSize(value);
    setAuctionsPerPage(value);
  }

  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-watchlist"
        role="tabpanel"
        aria-labelledby="v-pills-watchlist-tab"
        style={{ minHeight: "800px" }}
      >
        {/* table title*/}
        <div className="table-title-area">
          <h3>Watch list</h3>
          <select id="order-category" onChange={handleChange}>
            <option value={10}>Show: Last 10</option>
            <option value={100}>Show: Last 100</option>
          </select>
        </div>
        {/* table */}
        <div className="table-wrapper">
          <table className="">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Current Bid</th>
                <th>Bid Ends</th>
              </tr>
            </thead>
            <tbody>
              {watchList &&
                displayedAuctions.map((watchItem, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        alt="images"
                        src={
							watchItem.resized_image_urls?.["400"]?.[0] ||
                          "path/to/default/image.jpg"
                        }
                        className="img-fluid"
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                      />
                    </td>
                    <td>
                      <Link
                        to={`/auction-details/${watchItem.id}`}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        {watchItem.title}
                      </Link>
                    </td>
                    <td>
                      {formatCurrency(Number(watchItem.current_bid) || 0)}
                    </td>
                    {/* <td>
                      <p>
                        {watchItem?.bid_end_time.toDate().toLocaleDateString()}
                      </p>
                      <p>
                        {watchItem?.bid_end_time.toDate().toLocaleTimeString()}
                      </p>
                    </td> */}
                    <td>{formatDate(watchItem.bid_end_time)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination area */}
        <div className="table-pagination">
          <p>
            Showing {startIndex + 1} to{" "}
            {Math.min(startIndex + pageSize, auctions.length)} of{" "}
            {auctions.length} entries
          </p>
          <nav className="pagination-wrap">
            <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
              <li
                className={`page-item${currentPage === 0 ? " disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={handlePrevPageClick}
                  disabled={currentPage === 0}
                >
                  Prev
                </button>
              </li>
              {pages.map((pageIndex) => (
                <li
                  key={pageIndex}
                  className={`page-item${
                    currentPage === pageIndex ? " active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(pageIndex)}
                  >
                    {pageIndex + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item${
                  currentPage === numPages - 1 ? " disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={handleNextPageClick}
                  disabled={currentPage === numPages - 1}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default ContentOfWatchList;
