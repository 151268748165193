import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import FB from "../../../data/Firebase";
import Breadcrumb from "../../common/Breadcrumb";

function UserDetails(props) {
  const [user, setUser] = useState({});
  const [selectedValue, setSelectedValue] = useState("bids");
  const { id } = useParams();
  const history = useHistory();
  const [bidsArray, setBidsArray] = useState([]);
  const [commentsArray, setCommentsArray] = useState([]);
  const [watchArray, setWatchArray] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      if (selectedValue === "bids") {
        const bidsArray = await FB.getUserBids(id);
        setBidsArray(bidsArray);
      } else if (selectedValue === "comments") {
        const commentsArray = await FB.getUserComments(id);
        setCommentsArray(commentsArray);
      } else if (selectedValue === "watchlist") {
        const watchArray = await FB.getUserWatchlist(id);
        setWatchArray(watchArray);
      }
    };

    fetchData();
  }, [selectedValue]);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (!id || id.length !== 28) {
          setUser(null);
          return;
        }
        console.log(id);
        const user = await FB.getUserInfo(id);
        if (user) {
          setUser(user);
        } else {
          console.log(`No user found with ID ${id}`);
        }
      } catch (error) {
        console.error("Error fetching user details: ", error);
      }
    };

    getUser();
  }, [id]);

  function handleBack() {
    history.push("/admin");
  }

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-details-container">
      <Breadcrumb pageName="User Details" pageTitle="User Details" />
      <div
        style={{
          paddingTop: "120px",
          paddingBottom: "40px",
        }}
      >
        <table>
          <tbody>
            <tr className="table-row">
              <td>ID:</td>
              <td>{id}</td>
            </tr>
            <tr className="table-row">
              <td>Username:</td>
              <td>{user.username}</td>
            </tr>
            <tr className="table-row">
              <td>First Name:</td>
              <td>{user.firstName}</td>
            </tr>
            <tr className="table-row">
              <td>Last Name:</td>
              <td>{user.lastName}</td>
            </tr>
            <tr className="table-row">
              <td>Phone:</td>
              <td>{user.phoneNumber}</td>
            </tr>
            <tr className="table-row">
              <td>Email:</td>
              <td>{user.email}</td>
            </tr>
            <tr className="table-row">
              <td>Location:</td>
              <td>
                {user.city}, {user.state} {user.zip}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              maxWidth: "1000px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "20px 10px",
              }}
            >
              <select
                id="selection"
                name="selection"
                value={selectedValue}
                onChange={handleSelectChange}
                style={{
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  minWidth: "150px",
                }}
              >
                <option value="bids">Bids</option>
                <option value="comments">Comments</option>
              </select>
            </div>
          </div>
        </div>

        {selectedValue === "bids" ? (
          <table style={{ paddingTop: "20px" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Bid</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {bidsArray.map((bids, index) => (
                <tr key={bids.id} className="table-row">
                  <td> {bids.id.substring(0, 6)} </td>
                  <td>{formatCurrency(bids.bid)}</td>
                  <td>
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {bids.createdAt.toDate().toLocaleDateString("en-US")}
                      {"\n"}
                      {bids.createdAt.toDate().toLocaleTimeString("en-US")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : selectedValue === "comments" ? (
          <div>
            <table style={{ paddingTop: "20px" }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Comment</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {commentsArray.map((comments, index) => (
                  <tr key={comments.id} className="table-row">
                    <td> {comments.id.substring(0, 6)} </td>
                    <td>{comments.text}</td>
                    <td>
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {comments.createdAt
                          .toDate()
                          .toLocaleDateString("en-US")}
                        {"\n"}
                        {comments.createdAt
                          .toDate()
                          .toLocaleTimeString("en-US")}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <table style={{ paddingTop: "20px" }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {watchArray.map((watch, index) => (
                  <tr key={watch.id} className="table-row">
                    <td> {watch.id.substring(0, 6)} </td>
                    <td>{watch.title}</td>
                    <td>
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {watch.createdAt.toDate().toLocaleDateString("en-US")}
                        {"\n"}
                        {watch.createdAt.toDate().toLocaleTimeString("en-US")}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="button-group"
            style={{ display: "flex", justifyContent: "center", marginTop:"40px" }}
          >
            <button
              type="submit"
              className="profile-btn"
              onClick={handleBack}
              style={{ margin: "10px 0px", minWidth: "150px" }}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
