import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FB from "../../../data/Firebase";

function BlogPosts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchPosts = async () => {
      try {
        let postsData = await FB.fetchPosts();

        // Assuming you've added a 'sortableDate' field in the 'YYYY-MM-DD' format
        postsData.sort(
          (a, b) => new Date(b.sortableDate) - new Date(a.sortableDate)
        );

        if (isMounted) {
          setPosts(postsData);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError(error);
          setIsLoading(false);
        }
      }
    };

    fetchPosts();

    return () => {
      isMounted = false;
    };
  }, []);

  // Add the getImageUrl function outside the component
  const getImageUrl = (imageId) => {
    const storageRef = FB.store.ref();
    const imageRef = storageRef.child(`/BlogPhotos/${imageId}/blog1.jpg`);
    return imageRef.getDownloadURL();
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div
      className="recent-news-section"
      style={{ marginTop: "0px", padding: "0px 0px 80px" }}
    >
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
            <div className="section-title4">
              <h1>Blog Posts</h1>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center g-4">
          {posts.map((post) => (
            <div key={post.id} className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
              <div
                className="single-blog-style3 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <div className="blog-img">
                  <Link to={"#"} className="blog-date">
                    <i className="bi bi-calendar-check" />
                    {post.date}
                  </Link>
                  <img
                    alt="images"
                    src={post.imageUrl}
                    className="blog-post-image img-fluid"
                  />
                </div>
                <div className="blog-content">
                  <h5>
                    <Link to={`/blog-details/${post.id}`} onClick={scrollTop}>
                      {post.title}
                    </Link>
                  </h5>
                  <p className="para">{post.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogPosts;
