import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";

function ForgotPassword() {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [message, setMessage] = useState("");

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await FB.sendPasswordResetEmail(forgotPasswordEmail);
      setMessage("Password reset email sent. Please check your inbox."); // Update message state

      setTimeout(() => {
        history.push("/login");
      }, 2000); // Delay to allow users to read the message
    } catch (error) {
      setMessage(error.message); // Update message state
    } finally {
      setLoading(false);
      setForgotPasswordEmail("");
    }
  };

  return (
    <div className="login-section pt-160 pb-120">
      <div className="container">
        <div className="row d-flex justify-content-center g-4">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div
              className="form-wrapper wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <div className="form-title">
                {/* <h3>Log In</h3> */}
                <p>Forgot Password?</p>
              </div>
              <form className="w-100" onSubmit={handleForgotPassword}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-inner">
                      <label>Enter your email to reset your password:</label>
                      <input
                        type="email"
                        name="forgotPasswordEmail"
                        id="forgotPasswordEmail"
                        value={forgotPasswordEmail}
                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                        title="Please enter your email"
                      />
                    </div>
                  </div>
                </div>
                <button className="account-btn" type="submit">
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </form>
              {message && (
                <p className="feedback-message" style={{ marginTop: "20px" }}>
                  {message}
                </p>
              )}{" "}
              {/* Display message conditionally */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
