import React from "react";
import Breadcrumb from "../common/Breadcrumb";

const PrivacyPage = () => {
  return (
    <>
      <Breadcrumb pageName="Privacy Policy" pageTitle="Privacy Policy" />
      <div className="signup-section pt-160 pb-120">
        <div className="container">
          <p>
            Effective date: July 5th, 2023<br></br>
            <br></br>
            <br></br>
            At BidBikes, we take your privacy seriously. Please read this
            Privacy Policy to learn how we treat your personal information.{" "}
            <b>
              By using or accessing our Services in any manner, you acknowledge
              that you accept the practices and policies outlined below, and you
              hereby consent that we will collect, use and share your
              information as described in this Privacy Policy.
            </b>
            <br></br>
            <br></br>
            Remember that your use of the Services is at all times subject to
            our Terms of Use, which incorporates this Privacy Policy. Any terms
            we use in this Policy without defining them have the definitions
            given to them in the Terms of Use.
            <br></br>
            <br></br>
            You may print a copy of this Privacy Policy by clicking here. If you
            have a disability, you may access this Privacy Policy in an
            alternative format by contacting us using any of the methods
            provided for in Contact Information below.
            <br></br>
            <br></br>
            <b>
              <u>What this Privacy Policy Covers</u>
            </b>
            <br></br>
            <br></br>
            This Privacy Policy covers how we treat Personal Data that we gather
            when you access or use our Services. “Personal Data” means any
            information that identifies or relates to a particular individual
            and also includes information referred to as “personally
            identifiable information” or “personal information” under applicable
            data privacy laws, rules or regulations. This Privacy Policy does
            not cover the practices of companies we don’t own or control or
            people we don’t manage.
            <br></br>
            <br></br>
            <b>
              <u>Categories of Personal Data We Collect</u>
            </b>
            <br></br>
            <br></br>
            The chart in this section details the categories of Personal Data
            that we collect and have collected over the past 12 months:
            <br></br>
            <br></br>
            <table>
              <tr>
                <th>Category</th>
                <th>Data We Collect</th>
                <th>Source(s)</th>
                <th>
                  Categories of Third Parties With Whom We Share this Personal
                  Data for a Business Purpose
                </th>
              </tr>
              <tr>
                <td>
                  <b>Profile Information</b>
                </td>
                <td>
                  · First and last name · Email · Photo · IP address · Bicycle
                  Metadata
                </td>
                <td>You</td>
                <td>
                  · Service Providers · Affiliates · Acquirers · Selected
                  Recipients Authorized By You
                </td>
              </tr>

              <tr>
                <td>
                  <b>Other Personal Identifiers</b>
                </td>
                <td>· Unique User ID · IP address</td>
                <td>You</td>
                <td>· Service Providers · Affiliates · Acquirers</td>
              </tr>

              <tr>
                <td>
                  <b>Payment Information</b>
                </td>
                <td>· Credit Card Information</td>
                <td>Third Party</td>
                <td>
                  · Service Providers · Affiliates · Acquirers · Selected
                  Recipients Authorized By You
                </td>
              </tr>

              <tr>
                <td>
                  <b>Deposit Account Information</b>
                </td>
                <td>· Paypal or other bank deposit instructions</td>
                <td>You</td>
                <td>· Service Providers · Affiliates · Acquirers</td>
              </tr>

              <tr>
                <td>
                  <b>IRS Form W-9 Information</b>
                </td>
                <td>
                  · Information in the IRS Form W-9
                  https://www.irs.gov/pub/irs-pdf/fw9.pdf
                </td>
                <td>You</td>
                <td>· IRS · Service Providers · Affiliates · Acquirers</td>
              </tr>
            </table>
            <br></br>
            <br></br>
            <u>
              <i>Sources of Personal Data (Column 3)</i>
            </u>
            <br></br>
            <br></br>
            We collect Personal Data about you from the following categories of
            sources:
            <br></br>
            <br></br>
            <ul style={{ "list-style-type": "disc" }}>
              <li>
                "You"
                <ul style={{ "list-style-type": "circle" }}>
                  <li>When you provide such information directly to us.</li>
                  <li>
                    When Personal Data about you is automatically collected in
                    connection with your use of our Services (see the subsection
                    titled “Information Collected Automatically” below).
                  </li>
                </ul>
              </li>

              <li>
                "Third Parties"
                <ul style={{ "list-style-type": "circle" }}>
                  <li>
                    Third parties that provide us with Personal Data about you.
                    Third Parties that share your Personal Data with us include:
                  </li>

                  <ul style={{ "list-style-type": "square" }}>
                    <li>
                      <u>Service providers.</u> For example, we may use
                      analytics service providers to analyze how you interact
                      and engage with the Services, or third parties may help us
                      provide you with customer support or payment processing.
                    </li>
                  </ul>
                </ul>
              </li>
            </ul>
            <u>
              <i>Information Collected Automatically</i>
            </u>
            <br></br>
            <br></br>
            The Services use cookies and similar technologies such as pixel
            tags, web beacons, clear GIFs and JavaScript (collectively,
            <b>“Cookies”</b>) to enable our servers to recognize your web
            browser and tell us how and when you visit and use our Services, to
            analyze trends, learn about our user base and operate and improve
            our Services. Cookies are small pieces of data– usually text files –
            placed on your computer, tablet, phone or similar device when you
            use that device to access our Services. We may also supplement the
            information we collect from you with information received from third
            parties, including third parties that have placed their own Cookies
            on your device(s). Please note that because of our use of Cookies,
            the Services do not support “Do Not Track” requests sent from a
            browser at this time.
            <br></br>
            <br></br>
            <b>
              <u>How We Use Your Personal Data </u>
            </b>
            <br></br>
            <br></br>
            We process Personal Data to operate, improve, understand and
            personalize our Services. We use Personal Data for the following
            purposes:
            <ul style={{ "list-style-type": "disc" }}>
              <li>
                To meet or fulfill the reason you provided the information to
                us.
              </li>
              <li>
                To communicate with you about the Services, including Service
                announcements, updates or offers.
              </li>
              <li>To provide support and assistance for the Services.</li>
              <li>To create and manage your Account or other user profiles.</li>
              <li>
                To personalize website content and communications based on your
                preferences.
              </li>
              <li>To process transactions.</li>
              <li>To respond to user inquiries and fulfill user requests.</li>
              <li>
                To improve and develop the Services, including testing,
                research, analysis and product development.
              </li>
              <li>
                To protect against or deter fraudulent, illegal or harmful
                actions and maintain the safety, security and integrity of our
                Services.
              </li>
              <li>
                To comply with our legal or contractual obligations, resolve
                disputes, and enforce our Terms of Use.
              </li>
              <li>
                To respond to law enforcement requests and as required by
                applicable law, court order, or governmental regulations.
              </li>
              <li>
                For any other business purpose stated when collecting your
                Personal Data or as otherwise set forth in applicable data
                privacy laws, such as the California Consumer Privacy Act (the
                “CCPA”).
              </li>
            </ul>
            We will not collect additional categories of Personal Data or use
            the Personal Data we collected for materially different, unrelated,
            or incompatible purposes without providing you notice.
            <br></br>
            <br></br>
            As noted in the list above, we may communicate with you if you’ve
            provided us the means to do so. For example, if you’ve given us your
            email address, we may send you promotional email offers or email you
            about your use of the Services. Also, we may receive a confirmation
            when you open an email from us, which helps us improve our Services.
            If you do not want to receive communications from us, please
            indicate your preference by emailing us at legal@bidbikes.com.
            <br></br>
            <br></br>
            <b>
              <u>How We Share Your Personal Data</u>
            </b>
            <br></br>
            <br></br>
            <u>
              <i>
                Categories of Third Parties with Whom We Share Personal Data
                (Column 4){" "}
              </i>
            </u>
            <br></br>
            <br></br>
            We disclose your Personal Data as indicated in the chart above to
            the following categories of service providers and other parties:
            <br></br>
            <br></br>
            <ul style={{ "list-style-type": "disc" }}>
              <li>"Service Providers"</li>
              <ul style={{ "list-style-type": "circle" }}>
                <li>
                  These are third parties that help us provide our Services,
                  including payment processors, security and fraud prevention
                  providers, hosting and other technology and communications
                  providers, analytics providers, and staff augmentation and
                  contract personnel.
                </li>
              </ul>
              <li>"Acquirors"</li>
              <ul style={{ "list-style-type": "circle" }}>
                <li>
                  These are parties who acquire your Personal Data through an
                  acquisition or other change of control. Personal Data may be
                  transferred to a third party if we undergo a merger,
                  acquisition, bankruptcy or other transaction in which that
                  third party assumes control of our business (in whole or in
                  part).
                </li>
              </ul>
              <li>"Selected Recipients"</li>
              <ul style={{ "list-style-type": "circle" }}>
                <li>
                  These are third parties that we share your Personal Data with
                  at your direction, including:
                </li>
                <ul style={{ "list-style-type": "square" }}>
                  <li>
                    Other users (where you post information publicly or as
                    otherwise necessary to effect a transaction initiated or
                    authorized by you through the Services).
                  </li>
                  <li>
                    Third-party business partners who you access through the
                    Services.
                  </li>
                  <li>Other parties authorized by you</li>
                </ul>
              </ul>
            </ul>
            <br></br>
            <u>
              <i>Disclosures of Personal Data for a Business Purpose</i>
            </u>
            <br></br>
            <br></br>
            We disclose your Personal Data to service providers and other
            parties for the following business purposes:
            <br></br>
            <br></br>
            <ul style={{ "list-style-type": "disc" }}>
              <li>
                {" "}
                Auditing related to a current interaction and concurrent
                transactions, including, but not limited to, auditing compliance
                with this specification and other standards.
              </li>

              <li>
                Detecting security incidents, protecting against malicious,
                deceptive, fraudulent or illegal activity, and prosecuting those
                responsible for that activity.
              </li>

              <li>
                Debugging to identify and repair errors that impair existing
                intended functionality.
              </li>
              <li>
                {" "}
                Short-term, transient use of Personal Data that is not used by
                another party to build a consumer profile or otherwise alter
                your consumer experience outside the current interaction.
              </li>
              <li>
                Performing services on our behalf, including maintaining or
                servicing accounts, providing customer service, processing or
                fulfilling transactions, verifying customer information, or
                processing payments.
              </li>
            </ul>
            <b>
              <u>Data Security and Retention</u>
            </b>
            <br></br>
            <br></br> We seek to protect your Personal Data from unauthorized
            access, use and disclosure using appropriate physical, technical,
            organizational and administrative security measures based on the
            type of Personal Data and how we are processing that data. For
            example, the Services use industry standard Secure Sockets Layer
            (SSL) technology to allow for the encryption of Personal Data you
            provide to us. You should also help protect your data by
            appropriately selecting and protecting your password and/or other
            sign-on mechanism; limiting access to your computer or device and
            browser; and signing off after you have finished accessing your
            account. Although we work to protect the security of your account
            and other data that we hold in our records, please be aware that no
            method of transmitting data over the Internet or storing data is
            completely secure. We cannot guarantee the complete security of any
            data you share with us, and except as expressly required by law, we
            are not responsible for the theft, destruction, loss or inadvertent
            disclosure of your information or content. <br></br>
            <br></br>We retain Personal Data about you for as long as you have
            an open account with us or as otherwise necessary to provide you
            with our Services. In some cases we retain Personal Data for longer,
            if doing so is necessary to comply with our legal obligations,
            resolve disputes or collect fees owed, or is otherwise permitted or
            required by applicable law, rule or regulation. We may further
            retain information in an anonymous or aggregated form where that
            information would not identify you personally.
            <br></br>
            <br></br>
            <b>
              <u>Personal Data of Children</u>
            </b>
            <br></br>
            <br></br> As noted in the Terms of Use, we do not knowingly collect
            or solicit Personal Data about children under 13 years of age; if
            you are a child under the age of 13, please do not attempt to
            register for or otherwise use the Services or send us any Personal
            Data. If we learn we have collected Personal Data from a child under
            13 years of age, we will delete that information as quickly as
            possible. If you believe that a child under 13 years of age may have
            provided Personal Data to us, please contact us at
            legal@bidbikes.com.
            <br></br>
            <br></br>
            <b>
              <u>California Resident Rights</u>
            </b>
            <br></br>
            <br></br>If you are a California resident, you have the rights set
            forth in this section. Please see the “Exercising Your Rights”
            section below for instructions regarding how to exercise these
            rights. Please note that we may process Personal Data of our
            customers’ end users or employees in connection with our provision
            of certain services to our customers. If we are processing your
            Personal Data as a service provider, you should contact the entity
            that collected your Personal Data in the first instance to address
            your rights with respect to such data. If there are any conflicts
            between this section and any other provision of this Privacy Policy
            and you are a California resident, the portion that is more
            protective of Personal Data shall control to the extent of such
            conflict. If you have any questions about this section or whether
            any of the following rights apply to you, please contact us at
            legal@bidbikes.com.
            <br></br>
            <br></br>
            <u>
              <i>Access</i>
            </u>
            <br></br>
            <br></br> You have the right to request certain information about
            our collection and use of your Personal Data over the past 12
            months. In response, we will provide you with the following
            information:
            <br></br>
            <br></br>
            <ul style={{ "list-style-type": "disc" }}>
              <li>
                The categories of Personal Data that we have collected about
                you.
              </li>
              <li>
                The categories of sources from which that Personal Data was
                collected.
              </li>
              <li>
                The business or commercial purpose for collecting or selling
                your Personal Data.
              </li>
              <li>
                The categories of third parties with whom we have shared your
                Personal Data.
              </li>
              <li>
                The specific pieces of Personal Data that we have collected
                about you.
              </li>
            </ul>
            If we have disclosed your Personal Data to any third parties for a
            business purpose over the past 12 months, we will identify the
            categories of Personal Data shared with each category of third party
            recipient. If we have sold your Personal Data over the past 12
            months, we will identify the categories of Personal Data sold to
            each category of third party recipient.
            <br></br>
            <br></br>
            <u>
              <i>Deletion</i>
            </u>
            <br></br>
            <br></br>You have the right to request that we delete the Personal
            Data that we have collected about you. Under the CCPA, this right is
            subject to certain exceptions: for example, we may need to retain
            your Personal Data to provide you with the Services or complete a
            transaction or other action you have requested. If your deletion
            request is subject to one of these exceptions, we may deny your
            deletion request.
            <br></br>
            <br></br>
            <u>
              <i>Exercising Your Rights</i>
            </u>
            <br></br>
            <br></br>
            To exercise the rights described above, you must send us a request
            that (1) provides sufficient information to allow us to verify that
            you are the person about whom we have collected Personal Data, and
            (2) describes your request in sufficient detail to allow us to
            understand, evaluate and respond to it. Each request that meets both
            of these criteria will be considered a “Valid Request.” We may not
            respond to requests that do not meet these criteria. We will only
            use Personal Data provided in a Valid Request to verify your
            identity and complete your request. You do not need an account to
            submit a Valid Request.
            <br></br>
            <br></br>
            We will work to respond to your Valid Request within 45 days of
            receipt. We will not charge you a fee for making a Valid Request
            unless your Valid Request(s) is excessive, repetitive or manifestly
            unfounded. If we determine that your Valid Request warrants a fee,
            we will notify you of the fee and explain that decision before
            completing your request.
            <br></br>
            <br></br>
            You may submit a Valid Request by emailing us at: legal@bidbikes.com
            <br></br>
            <br></br>
            You may also authorize an agent (an<b>“Authorized Agent”</b>) to
            exercise your rights on your behalf. To do this, you must provide
            your Authorized Agent with written permission to exercise your
            rights on your behalf, and we may request a copy of this written
            permission from your Authorized Agent when they make a request on
            your behalf.
            <br></br>
            <br></br>
            <u>
              <i>
                We Will Not Discriminate Against You for Exercising Your Rights
                Under the CCPA
              </i>
            </u>
            <br></br>
            <br></br>
            We will not discriminate against you for exercising your rights
            under the CCPA. We will not deny you our goods or services, charge
            you different prices or rates, or provide you a lower quality of
            goods and services if you exercise your rights under the CCPA.
            However, we may offer different tiers of our Services as allowed by
            applicable data privacy laws (including the CCPA) with varying
            prices, rates or levels of quality of the goods or services you
            receive related to the value of Personal Data that we receive from
            you.
            <br></br>
            <br></br>
            <b>
              <u>Other State Law Privacy Rights</u>
            </b>
            <br></br>
            <br></br>
            <u>
              <i>California Resident Rights</i>
            </u>
            <br></br>
            <br></br>
            Under California Civil Code Sections 1798.83-1798.84, California
            residents are entitled to contact us to prevent disclosure of
            Personal Data to third parties for such third parties’ direct
            marketing purposes; in order to submit such a request, please
            contact us at legal@bidbikes.com.
            <br></br>
            <br></br>
            Your browser may offer you a “Do Not Track” option, which allows you
            to signal to operators of websites and web applications and services
            that you do not wish such operators to track certain of your online
            activities over time and across different websites. Our Services do
            not support Do Not Track requests at this time. To find out more
            about “Do Not Track,” you can visit www.allaboutdnt.com.
            <br></br>
            <br></br>
            <u>
              <i>Nevada Resident Rights</i>
            </u>
            <br></br>
            <br></br>
            If you are a resident of Nevada, you have the right to opt-out of
            the sale of certain Personal Data to third parties who intend to
            license or sell that Personal Data. You can exercise this right by
            contacting us at legal@bidbikes.com with the subject line “Nevada Do
            Not Sell Request” and providing us with your name and the email
            address associated with your account. Please note that we do not
            currently sell your Personal Data as sales are defined in Nevada
            Revised Statutes Chapter 603A.
            <br></br>
            <br></br>
            <b>
              <u>Changes to this Privacy Policy</u>
            </b>
            <br></br>
            <br></br>
            We’re constantly trying to improve our Services, so we may need to
            change this Privacy Policy from time to time, but we will alert you
            to any such changes by placing a notice on the BidBikes website, by
            sending you an email and/or by some other means. Please note that if
            you’ve opted not to receive legal notice emails from us (or you
            haven’t provided us with your email address), those legal notices
            will still govern your use of the Services, and you are still
            responsible for reading and understanding them. If you use the
            Services after any changes to the Privacy Policy have been posted,
            that means you agree to all of the changes. Use of information we
            collect is subject to the Privacy Policy in effect at the time such
            information is collected.
            <br></br>
            <br></br>
            <b>
              <u>Contact Information:</u>
            </b>
            <br></br>
            <br></br>
            If you have any questions or comments about this Privacy Policy, the
            ways in which we collect and use your Personal Data or your choices
            and rights regarding such collection and use, please do not hesitate
            to contact us at:
            <br></br>
            <br></br>
            <ul style={{ "list-style-type": "disc" }}>
              <li>email: legal@bidbikes.com</li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPage;
