import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Logo from "../../assets/images/bg/BBW_Logo4.png";
import FB from "../../data/Firebase";
import SendSVG from "../../assets/images/icons/send-icon.svg";
import { Spinner } from "react-bootstrap";

function Footer(props) {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const joinList = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      alert("Please wait; the request is being processed.");
      return;
    }

    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    setIsSubmitting(true);
    setLoading(true);

    try {
      await FB.addUserToSendGrid(email);
      alert("Thank you for joining our mailing list!");
    } catch (error) {
      console.error("Error adding user to contact list:", error);
    } finally {
      setLoading(false);
      setEmail("");
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <footer>
        <div
          className="footer-top"
          style={{ padding: "20px", paddingTop: "30px" }}
        >
          <div className="container overflow-hidden">
            <div className="row gy-5">
              <div className="col-lg-6 col-md-12 ">
                <div className="footer-item">
                  <Link to={`/`} onClick={scrollTop}>
                    <img
                      alt="images"
                      src={Logo}
                      style={{
                        height: "40px",
                        width: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <p>
                    Get insider access to our latest auction listings and
                    receive expert advice on buying and selling bicycles. Join
                    our mailing list to stay connected with BidBikes.
                  </p>
                  <form onSubmit={joinList}>
                    <div
                      className="input-with-btn d-flex"
                      style={{
                        padding: "0px",
                 
				 
				 
				      }}
                    >
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleChange}
                      />
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="white"
                            className="bi bi-send-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                          </svg>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>Navigation</h5>
                  <ul className="footer-list">
                    <li>
                      <Link onClick={scrollTop} to={`/live-auction`}>
                        Auctions
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={`/how-works`}>
                        How BidBikes Works
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={`/dashboard`}>
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={`/contact`}>
                        Contact Us
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={scrollTop} to={`/blog`}>
                        Latest News
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>Help &amp; FAQs</h5>
                  <ul className="footer-list">
                    {/* <li>
                      <Link to={`/product`} onClick={scrollTop}>
                        Help Center
                      </Link>
                    </li> */}
                    <li>
                      <Link to={`/faq`} onClick={scrollTop}>
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link to={`/terms`} onClick={scrollTop}>
                        Terms of Use
                      </Link>
                    </li>
                    <li>
                      <Link to={`/privacy`} onClick={scrollTop}>
                        Privacy Notice
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to={`/blog`}
                        onClick={scrollTop}
                      >
                        Merchant Add Policy
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row d-flex align-items-center g-4">
              <div className="col-lg-12 d-flex justify-content-lg-center justify-content-center">
                <p>
                  {"© "}
                  {new Date().getFullYear()} <Link to={"/"}>BidBikes.com</Link>
                  {". All rights reserved."}
                </p>
              </div>
              {/* <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center align-items-center flex-sm-nowrap flex-wrap">
                <p className="d-sm-flex d-none">We Accept:</p>
                <ul className="footer-logo-list">
                  <li>
                    <Link to={"#"} onClick={scrollTop}>
                      <img
                        alt="images"
                        src={
                          process.env.PUBLIC_URL + "/images/bg/footer-pay1.png"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"} onClick={scrollTop}>
                      <img
                        alt="images"
                        src={
                          process.env.PUBLIC_URL + "/images/bg/footer-pay4.png"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"} onClick={scrollTop}>
                      <img
                        alt="images"
                        src={
                          process.env.PUBLIC_URL + "/images/bg/footer-pay5.png"
                        }
                      />
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
