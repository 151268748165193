import React, { useState, useEffect, useCallback } from "react";
import LiveAuctionCard from "./LiveAuctionCard";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

function LiveAuctionWrapSearch() {
  const history = useHistory();
  const [watchList, setWatchList] = useState([]);
  const [user, setUser] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [auctionData, setAuctionData] = useState([]);
  const [yearFacets, setYearFacets] = useState([]);
  const [brandFacets, setBrandFacets] = useState([]);
  const [typeFacets, setTypeFacets] = useState([]);
  const [conditionFacets, setConditionFacets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    year: [],
    brand: [],
    type: [],
    condition: [],
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [query, setQuery] = useState("");
  const toggleFilters = () => setIsFiltersOpen(!isFiltersOpen);

  const [searchQuery, setSearchQuery] = useState({
    status: ["Live"],
  });
  // status: ["Submitted", "Upcoming", "Live"],

  const fetchAllFacets = async (searchTerm, activeFilters) => {
    try {
      const facetsResult = await FB.fetchAllFacets(searchTerm, activeFilters);
      // Update the state for each type of facet
      setYearFacets(facetsResult.facets[0]?.counts || []);
      setBrandFacets(facetsResult.facets[1]?.counts || []);
      setTypeFacets(facetsResult.facets[2]?.counts || []);
      setConditionFacets(facetsResult.facets[3]?.counts || []);
    } catch (error) {
      console.error("Error fetching facets:", error);
    }
  };

  const searchAuctions = async (term, activeFilters) => {
    setLoading(true);
    try {
      const searchTerm = term.toLowerCase();
      const filterBy = buildFilterQuery(activeFilters);

      const searchResult = await FB.performAuctionSearch(searchTerm, filterBy);

      // Update search results
      setAuctionData(searchResult.data.results);

      // Fetch and update year facets based on the current search term
      await fetchAllFacets(searchTerm, filterBy);
    } catch (error) {
      console.error("Error performing search:", error);
    } finally {
      setLoading(false);
    }
  };

  //   useEffect(() => {
  //     fetchAllFacets("", buildFilterQuery(filters));
  //   }, []);

  const handleSearch = async (event) => {
    event.preventDefault();
    searchAuctions(query, filters);
  };

  const handleFilterChange = (filterType, value, isChecked) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (isChecked) {
        newFilters[filterType] = [...newFilters[filterType], value];
      } else {
        newFilters[filterType] = newFilters[filterType].filter(
          (item) => item !== value
        );
      }

      // Perform search with current query and updated filters
      searchAuctions(query, newFilters);

      return newFilters;
    });
  };

  const buildFilterQuery = (filters) => {
    // Start with the status filter
    const filterParts = ["status:=[Live]"];
    // const filterParts = ["status:=[Submitted,Upcoming,Live]"];
    // Add other filters based on user selection
    Object.entries(filters)
      .filter(([_, values]) => values.length > 0)
      .forEach(([key, values]) => {
        filterParts.push(`${key}:=[${values.join(",")}]`);
      });

    return filterParts.join(" && ");
  };

  const handleImagesLoad = useCallback(() => {
    setImagesLoaded(true);
  }, []);

  useEffect(() => {
    let unsubscribe;

    const fetchListings = async () => {
      try {
        setLoadedImagesCount(0);
        unsubscribe = FB.getLiveAuctionListings(
          searchQuery,
          (updatedListings) => {
            setAuctionData(updatedListings);
          }
        );
        // console.log(auctionData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchListings();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const handleSearchInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchAuctions(query, filters);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = FB.auth.onAuthStateChanged((authUser) => {
      if (isMounted && authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe(); // Unsubscribe from the auth state change listener
      }
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    console.log(user);

    if (user) {
      FB.getWatchlist().then((watchlist) => {
        if (isMounted) {
          setWatchList(watchlist);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    <>
      {/* <div className="container" style={{ padding: "10px" }}>
        <div
          className="form-wrapper"
          style={{ padding: "0px 0px", marginBottom: "0px" }}
        >
          <div
            className="form-search"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="search"
              value={query}
              onChange={handleSearchInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Search for bikes (e.g. Yeti, Specialized, Pivot)"
              style={{
                flex: 1,
                backgroundColor: "transparent",
              }}
            />
            <button
              className="form-button"
              type="submit"
              disabled={loading || !query}
              style={{
                marginLeft: "10px",
                width: "50px",
                height: "50px",
                backgroundColor: !query || loading ? "#ccc" : "#26a96c",
                border: "none",
              }}
              onClick={handleSearch}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <i className="fas fa-search"></i>
              )}
            </button>

            <button
              className="form-button"
              onClick={toggleFilters}
              style={{
                marginLeft: "10px",
                width: "50px",
                height: "50px",
                backgroundColor: !query || loading ? "#ccc" : "#26a96c",
                border: "none",
              }}
            >
              <i className="fas fa-filter"></i>
            </button>
          </div>
        </div>

        {isFiltersOpen && (
          <div className="faq-section" style={{ paddingTop: "10px" }}>
            <div className="faq-wrap wow fadeInUp">
              <div className="accordion">
                <div className="accordion-item">
                  <h5 className="" id="heading-Year" style={{ margin: "0px" }}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-Year"
                      aria-expanded="false"
                      aria-controls="collapse-Year"
                    >
                      Year
                    </button>
                  </h5>
                  <div
                    id="collapse-Year"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-Year"
                    data-bs-parent=".accordion"
                  >
                    <div className="accordion-body">
                      {yearFacets &&
                        yearFacets.map(({ value, count }) => (
                          <div
                            key={value}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <input
                              style={{
                                height: "16px",
                                width: "16px",
                                marginBottom: "2px",
                              }}
                              type="checkbox"
                              id={`year-${value}`}
                              name={`year-${value}`}
                              value={value}
                              checked={filters.year.includes(value.toString())}
                              onChange={(e) =>
                                handleFilterChange(
                                  "year",
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              htmlFor={`year-${value}`}
                              style={{ marginBottom: 0, marginLeft: 5 }}
                            >
                              {value} ({count})
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="" id="heading-Brand" style={{ margin: "0px" }}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-Brand"
                      aria-expanded="false"
                      aria-controls="collapse-Brand"
                    >
                      Brand
                    </button>
                  </h5>
                  <div
                    id="collapse-Brand"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-Brand"
                    data-bs-parent=".accordion"
                  >
                    <div className="accordion-body">
                      {brandFacets &&
                        brandFacets.map(({ value, count }) => (
                          <div
                            key={value}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <input
                              style={{
                                height: "16px",
                                width: "16px",
                                marginBottom: "2px",
                              }}
                              type="checkbox"
                              id={`brand-${value}`}
                              name={`brand-${value}`}
                              value={value}
                              checked={filters.brand.includes(value)}
                              onChange={(e) =>
                                handleFilterChange(
                                  "brand",
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              htmlFor={`brand-${value}`}
                              style={{ marginBottom: 0, marginLeft: 5 }}
                            >
                              {capitalizeFirstLetter(value)} ({count})
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5
                    className=""
                    id="heading-Condition"
                    style={{ margin: "0px" }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-Condition"
                      aria-expanded="false"
                      aria-controls="collapse-Condition"
                    >
                      Condition
                    </button>
                  </h5>
                  <div
                    id="collapse-Condition"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-Condition"
                    data-bs-parent=".accordion"
                  >
                    <div className="accordion-body">
                      {conditionFacets &&
                        conditionFacets.map(({ value, count }) => (
                          <div
                            key={value}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <input
                              style={{
                                height: "16px",
                                width: "16px",
                                marginBottom: "2px",
                              }}
                              type="checkbox"
                              id={`condition-${value}`}
                              name={`condition-${value}`}
                              value={value}
                              checked={filters.condition.includes(value)}
                              onChange={(e) =>
                                handleFilterChange(
                                  "condition",
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              htmlFor={`condition-${value}`}
                              style={{ marginBottom: 0, marginLeft: 5 }}
                            >
                              {value} ({count})
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="" id="heading-Type" style={{ margin: "0px" }}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-Type"
                      aria-expanded="false"
                      aria-controls="collapse-Type"
                    >
                      Type
                    </button>
                  </h5>
                  <div
                    id="collapse-Type"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-Type"
                    data-bs-parent=".accordion"
                  >
                    <div className="accordion-body">
                      {typeFacets &&
                        typeFacets.map(({ value, count }) => (
                          <div
                            key={value}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <input
                              style={{
                                height: "16px",
                                width: "16px",
                                marginBottom: "2px",
                              }}
                              type="checkbox"
                              id={`type-${value}`}
                              name={`type-${value}`}
                              value={value}
                              checked={filters.type.includes(value)}
                              onChange={(e) =>
                                handleFilterChange(
                                  "type",
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              htmlFor={`type-${value}`}
                              style={{ marginBottom: 0, marginLeft: 5 }}
                            >
                              {capitalizeFirstLetter(value)} ({count})
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div> */}

      <div
        className="live-auction-container"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        {Array.isArray(auctionData) && auctionData.length > 0 ? (
          auctionData.map((auction) => (
            <div key={auction.id}>
              <LiveAuctionCard
                auction={auction}
                onImagesLoad={handleImagesLoad}
                watchList={watchList}
              />
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              //   height: "50vh",
              padding: "20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <h3>No Live Auctions at the moment.</h3>
            <p className="para" style={{ marginTop: "20px" }}>
              Interested in selling your bike? Look no further than BidBikes,
              the ultimate marketplace for bike enthusiasts! Whether you have a
              high-end carbon road bike, a cutting-edge eMTB, or a custom
              blinged-up mountain bike, we're here to help you sell it quickly
              and profitably.
            </p>
            <button
              className="form-button join-button"
              style={{
                maxWidth: "300px",
                marginTop: "20px",
                backgroundColor: "#26a96c",
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                fontSize: "16px",
                cursor: "pointer",
                border: "none",
              }}
              onClick={() => history.push("/sell-your-bike")}
            >
              Sell Your Bike
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default LiveAuctionWrapSearch;
