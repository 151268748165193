import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import FB from "../../../data/Firebase";

function EditUser(props) {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await FB.getUserInfo(id);

        if (user) {
          setUser(user);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, [id]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      // Update the user data
      await FB.updateUserInfo(id, user);

      // Redirect the user back to the profile page
      history.push(`/user/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  function handleBack() {
    history.push("/admin");
  }

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Breadcrumb pageName="Edit User" pageTitle="Edit User" />
      <div
        style={{
          paddingTop: "100px",
          paddingBottom: "20px",
        }}
      >
        <div className="form-wrapper">
          <form onSubmit={handleFormSubmit}>
            <div className="form-inner">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                value={user.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="phoneNumber">Phone:</label>
              <input
                type="tel"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="role">Role:</label>
              <input
                type="text"
                name="role"
                value={user.role}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                name="username"
                value={user.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                name="city"
                value={user.city}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="state">State:</label>
              <input
                type="text"
                name="state"
                value={user.state}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inner">
              <label htmlFor="zip">Zip:</label>
              <input
                type="text"
                name="zip"
                value={user.zip}
                onChange={handleInputChange}
              />
            </div>

            <div
              className="button-group"
              style={{ display: "flex", justifyContent: "space-between", marginTop:"40px" }}
            >
              <button
                type="submit"
                onClick={handleBack}
                className="profile-btn"
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                Back
              </button>
              <button
                type="submit"
                className="profile-btn"
                style={{ margin: "10px 0px", minWidth: "150px" }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
