import React from "react";
// import AboutUsCounter from "../../common/AboutUsCounter";
// import CategoryArea from "./CategoryArea";
// import RecentNewsPost from "./RecentNewsPost";
// import SponsorArea from './SponsorArea'
import Testimonial from "./Testimonial";
// import UpComingArea from "./UpComingArea";
// import HowItWorks from "./HowItWorks";
import HeroBanner from "./HeroBanner";
import LiveAuctionHome from "./LiveAuction";
import WhyChooseUs from "../howItWork/WhyChooseUs";
import BlogPosts from "./BlogPosts";

function HomePage() {
  return (
    <>
      <HeroBanner />
      {/* <CategoryArea /> */}
      {/* <GiveawayContainer /> */}
      <LiveAuctionHome />
      <Testimonial />
      <WhyChooseUs />
      {/* <HowItWorks /> */}
      {/* <UpComingArea /> */}
      <BlogPosts />
      {/* <RecentNewsPost /> */}
      {/* <AboutUsCounter /> */}
    </>
  );
}

export default HomePage;
