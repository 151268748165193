import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";

function ActionURL() {
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();

  //   const urlParams = new URLSearchParams(location.search);
  //   const mode = urlParams.get("mode");
  //   const oobCode = urlParams.get("oobCode");
  //   const isEmailVerified = urlParams.get("emailVerified");
  //   const userId = urlParams.get("userId");

  // Parse the verification code from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");
  const mode = urlParams.get("mode");
  const continueUrl = urlParams.get("continueUrl");

  // Parse the continueUrl to extract the userId
  const continueUrlParams = new URLSearchParams(continueUrl.split("?")[1]);
  const isEmailVerified = continueUrlParams.get("emailVerified");
  const userId = continueUrlParams.get("userId");

  //   console.log("oobCode", oobCode);
  //   console.log("emailVerified", isEmailVerified);
  //   console.log("userId", userId);

  const sendWelcomeEmail = async () => {
    try {
      setLoading(true);
      await FB.sendWelcomeEmail(userId); // Pass the user ID to the function
      console.log("Welcome email sent successfully.");
      setLoading(false);
    } catch (error) {
      console.error("Error sending welcome email:", error);
      setLoading(false);
    }
  };

  const handleLogin = (event) => {
    history.push("/login");
  };

  useEffect(() => {
    if (mode === "verifyEmail") {
      const verifyEmail = async () => {
        try {
          await FB.verifyEmail(oobCode);
          console.log("Email verification success");

          // Rest of your code...
        } catch (error) {
          console.error("Email verification error:", error);
          setError(error.message);
        }
      };
      verifyEmail();
    }
  }, [mode, oobCode, userId, isEmailVerified]);

  const handleNewPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!oobCode) throw new Error("Reset code is missing.");

      await FB.resetPassword(oobCode, newPassword);
      // Navigate to login after a delay
      setTimeout(() => {
        history.push("/login");
      }, 1000);
      // Redirect or additional actions upon success
    } catch (err) {
      console.error(error.message);
      setError(err.message);
      // Additional error handling
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-section pt-160 pb-120">
      <div className="container">
        <div className="row d-flex justify-content-center g-4">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div
              className="form-wrapper wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <div className="form-title">
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <p>
                    {mode === "verifyEmail"
                      ? "Welcome to BidBikes!"
                      : "Reset Password"}
                  </p>
                </div>
                {mode === "verifyEmail" && (
                  <form className="w-100" onSubmit={handleLogin}>
                    <button
                      type="submit"
                      className="account-btn"
                      style={{ marginTop: "20px", maxWidth: "80%" }}
                    >
                      Login
                    </button>
                  </form>
                )}
              </div>

              {mode === "resetPassword" && (
                <form className="w-100" onSubmit={handleNewPassword}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-inner">
                        <input
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          placeholder="Enter your new password"
                          required
                          title="Please enter your new password"
                        />
                      </div>
                    </div>
                  </div>
                  <button className="account-btn" type="submit">
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </form>
              )}

              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionURL;
