import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import FB from "../../../data/Firebase";
import { Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { setegid } from "process";

const CARD_ELEMENT_OPTIONS = {
  // Customize the appearance and behavior of the CardElement
  // For example:
  style: {
    base: {
      fontSize: "16px",
      color: "#32325d",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
    },
  },
};

// function PaymentForm({ user }) {
function PaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    city: "",
    state: "",
    zip: "",
  });

  const [userDetails, setUserDetails] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable the form submission or show a loading indicator.
      return;
    }

    // Create a payment method using the card element
    const cardElement = elements.getElement(CardElement);
    const { error: cardError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

    if (cardError) {
      setError(cardError.message);
      return;
    }

    try {
      const response = await FB.callRegisterToBidFunction(
        paymentMethod.id,
        formValues
      );
      console.log(response);

      const { from } = location.state || { from: { pathname: "/dashboard" } };
      history.push(from);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    history.push("/dashboard");
  };

  const usStates = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  return (
    <>
      <div className="login-section pt-120" style={{ paddingBottom: "20px" }}>
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="form-title" style={{ marginBottom: "30px" }}>
                    <h4 style={{ margin: "20px" }}>Register To Bid</h4>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      To participate in bidding, a valid credit card must be on
                      file. BidBikes is not currently charging any buyer fees.
                      <br />
                      <br /> Please note that bids are binding, so make sure to
                      bid wisely!
                    </p>
                    {/* <p
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      To participate in bidding, a valid credit card must be on
                      file. Winning bidders are required to pay a 0% fee to
                      BidBikes on top of the winning bid amount that is paid to
                      the seller. The buyer's fee ranges from $25 to a maximum
                      of $500. <br />
                      <br /> Please note that bids are binding, so make sure to
                      bid wisely!
                    </p> */}
                  </div>

                  {/* <label>Registration Information</label> */}

                  <div className="form-inner">
                    {/* <label>First Name *</label> */}
                    <input
                      type="text"
                      name="firstName"
                      value={formValues.firstName}
                      onChange={handleChange}
                      placeholder="First Name*"
                      required
                    />
                  </div>

                  <div className="form-inner">
                    {/* <label>Last Name *</label> */}
                    <input
                      type="text"
                      name="lastName"
                      value={formValues.lastName}
                      onChange={handleChange}
                      placeholder="Last Name*"
                      required
                    />
                  </div>

                  <div className="form-inner">
                    {/* <label>Phone Number *</label> */}
                    <input
                      type="tel"
                      name="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number*"
                      //pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      required
                    />
                  </div>

                  <div className="form-inner">
                    {/* <label>City *</label> */}
                    <input
                      type="text"
                      name="city"
                      value={formValues.city}
                      onChange={handleChange}
                      placeholder="City*"
                      required
                    />
                  </div>

                  <select
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                    className="nice-select"
                  >
                    <option value="">Select State</option>
                    {usStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <div className="form-inner">
                    {/* <label>Zip *</label> */}
                    <input
                      type="text"
                      name="zip"
                      value={formValues.zip}
                      onChange={handleChange}
                      placeholder="Zip*"
                      required
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid #EEEEEE",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    <CardElement
                      id="card-element"
                      options={CARD_ELEMENT_OPTIONS}
                    />
                  </div>
                  <p style={{ fontSize: "12px", marginTop: "20px" }}>
                    We take the privacy and security of your payment information
                    seriously. Your payment details will be securely processed
                    by our trusted payment service provider, Stripe. We do not
                    store nor have access to your full credit card details on
                    our servers.
                  </p>

                  {error && (
                    <div className="error" style={{ marginTop: "20px" }}>
                      {error}
                    </div>
                  )}
                  <div className="button-group">
                    <button
                      className="profile-btn"
                      type="submit"
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        width: "100%",
                      }}
                      disabled={
                        loading ||
                        !formValues.firstName ||
                        !formValues.lastName ||
                        !formValues.phoneNumber ||
                        !formValues.city ||
                        !formValues.state ||
                        !formValues.zip
                      }
                    >
                      {loading ? (
                        <>
                          <Spinner animation="border" size="sm" /> Registering{" "}
                        </>
                      ) : (
                        "Register To Bid"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentForm;
