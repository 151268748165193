import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";

SwiperCore.use([Navigation, Autoplay]);

// Component to render star ratings
const StarRating = ({ rating }) => {
  const totalStars = 5;
  let stars = [];
  for (let i = 1; i <= totalStars; i++) {
    stars.push(
      <i
        key={i}
        className={`bi ${i <= rating ? "bi-star-fill" : "bi-star"}`}
        style={{ color: "orange" }}
      ></i>
    );
  }
  return <div className="star-rating">{stars}</div>;
};

function Testimonial3() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const options = {
        method: "GET",
        headers: { accept: "application/json" },
      };
      try {
        const response = await fetch(
          `https://api.fera.ai/v3/public/reviews?public_key=${process.env.REACT_APP_FERA_PUBLIC_KEY}`,
          options
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const { data } = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const testimonialSlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 24,
    loop: testimonials.length > 1, // Only loop if more than one testimonial
    roundLengths: true,
    autoplay: {
      delay: 5000, // Delay in milliseconds between slides
      disableOnInteraction: false, // Continue autoplay after interaction
    },
    navigation: {
      nextEl: ".testi-prev1",
      prevEl: ".testi-next1",
    },
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
    // breakpoints: {
    //   280: { slidesPerView: 1 },
    //   480: { slidesPerView: 1, autoplay: testimonials.length > 1 },
    //   768: { slidesPerView: 1 },
    //   992: { slidesPerView: testimonials.length > 1 ? 2 : 1 },
    //   1200: {
    //     slidesPerView: testimonials.length > 2 ? 3 : testimonials.length,
    //   },
    // },
  };

  return (
    <>
      <div className="testimonial-section pt-80 pb-0">
        {/* Background Images */}
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title4">
                <h2>What Customers Say</h2>
                {/* <p className="mb-0">
                  Don't just take our word for it, hear from our happy
                  customers.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row justify-content-center position-relative">
            <Swiper
              {...testimonialSlider}
              className="swiper testimonial-slider"
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonial-single hover-border3">
                    {/* <img alt="Quote" src={process.env.PUBLIC_URL + "/images/icons/quote-green2.svg"} className="quote-icon" /> */}

                    <div className="testi-img">
                      <img
                        alt="Customer"
                        src={testimonial.customer.avatar_url}
                      />
                    </div>
                    <div className="testi-content">
                      <StarRating rating={testimonial.rating} />
                      <h5 style={{ marginTop: "10px" }}>
                        {testimonial.heading}
                      </h5>
                      <p className="para">{testimonial.body}</p>
                      <div className="testi-designation">
                        <h5>
                          <Link to="#">
                            {testimonial.customer.display_name}
                          </Link>
                        </h5>
                        <p>{testimonial.customer.display_location}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="slider-arrows testimonial2-arrow d-flex justify-content-between gap-3">
              <div className="testi-prev1 style-3 swiper-prev-arrow">
                <i className="bi bi-arrow-left" />
              </div>
              <div className="testi-next1 style-3 swiper-next-arrow">
                <i className="bi bi-arrow-right" />
              </div>
            </div>
          </div>
          {/* Swiper and testimonials */}
          {/* Attribution Text */}
          <div className="row justify-content-center">
            <div className="col text-center" style={{ marginTop: "20px" }}>
              <p className="text-muted">
                Reviews are managed & verified by{" "}
                <a
                  href="https://www.fera.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fera.ai
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial3;
