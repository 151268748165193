import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import FB from "../../../data/Firebase";
import { UserContext } from "./Dashboard";
import Select from "react-select";

function ContentOfMessages() {
  const { user, userDetails } = useContext(UserContext);
  const [userChatMessages, setUserChatMessages] = useState([]);
  const [chatAuctionIds, setChatAuctionIds] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [auctionsPerPage, setAuctionsPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);

  const fetchAuctions = async (auctionIds) => {
    if (auctionIds.length > 0) {
      try {
        const fetchedAuctions = await FB.getAuctionsByIds(auctionIds);
        setAuctions(fetchedAuctions);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    }
  };

  const getUserChatMessages = async () => {
    if (user) {
      try {
        // Get all user bids
        const chatMessages = await FB.getUserComments(user.uid);

        const auctionIds = chatMessages.map(
          (chatMessage) => chatMessage.auctionId
        );
        setChatAuctionIds(auctionIds);

        // Call fetchAuctions after bidAuctionIds has been set
        if (auctionIds.length > 0) {
          await fetchAuctions(auctionIds);
        }

        setUserChatMessages(chatMessages);
      } catch (error) {
        console.error("Error fetching user bid messages:", error);
      }
    }
  };

  useEffect(() => {
    getUserChatMessages();
  }, [user]);

  function formatCurrency(value) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    window.scrollTo(0, 0);
  };

  const handlePrevPageClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const displayedUserChatMessages = userChatMessages.slice(
    startIndex,
    endIndex
  );

  const numPages = Math.ceil(userChatMessages.length / pageSize);
  const pages = [];
  for (let i = 0; i < numPages; i++) {
    pages.push(i);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setPageSize(value);
    setAuctionsPerPage(value);
  }

  const getAuction = (auctionId) => {
    if (auctions) {
      const auction = auctions.find((auction) => auction.id === auctionId);
      return auction || {}; // Return an empty object if the auction is not found
    }
    return {};
  };

  const getImageUrl = (auctionId) => {
    if (auctions) {
      const auction = auctions.find((auction) => auction.id === auctionId);
      if (auction && auction.resized_image_urls) {
        return auction.resized_image_urls[0] || ""; // Return an empty string if the image URL is not found
      }
    }
    return "";
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-messages"
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
        style={{ minHeight: "800px" }}
      >
        {/* table title*/}
        <div className="table-title-area">
          <h3>Comments History</h3>
          <select id="order-category" onChange={handleChange}>
            <option value={10}>Show: Last 10</option>
            <option value={100}>Show: Last 100</option>
          </select>
        </div>
        {/* table */}
        <div className="table-wrapper">
          <table className="">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Comment</th>
                <th>Comment Date</th>
              </tr>
            </thead>
            <tbody>
              {userChatMessages &&
                userChatMessages.length > 0 &&
                displayedUserChatMessages.map((message, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        alt="images"
                        src={getImageUrl(message?.auctionId)}
                        className="img-fluid"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </td>
                    <td>
                      <Link
                        to={`/auction-details/${message.auctionId}`}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        {getAuction(message.auctionId) &&
                          getAuction(message.auctionId).title}
                      </Link>
                    </td>
                    <td>{message.text}</td>
                    <td>
                      <p>{message.createdAt.toDate().toLocaleDateString()}</p>
                      <p>{message.createdAt.toDate().toLocaleTimeString()}</p>

                      {/* <p>{new Date(message.createdAt).toLocaleDateString()}</p>
                      <p>{new Date(message.createdAt).toLocaleTimeString()}</p> */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* pagination area */}
        <div className="table-pagination">
          <p>
            Showing {startIndex + 1} to{" "}
            {Math.min(startIndex + pageSize, userChatMessages.length)} of{" "}
            {userChatMessages.length} entries
          </p>
          <nav className="pagination-wrap">
            <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
              <li
                className={`page-item${currentPage === 0 ? " disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={handlePrevPageClick}
                  disabled={currentPage === 0}
                >
                  Prev
                </button>
              </li>
              {pages.map((pageIndex) => (
                <li
                  key={pageIndex}
                  className={`page-item${
                    currentPage === pageIndex ? " active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(pageIndex)}
                  >
                    {pageIndex + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item${
                  currentPage === numPages - 1 ? " disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={handleNextPageClick}
                  disabled={currentPage === numPages - 1}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default ContentOfMessages;
