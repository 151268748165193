import React, { useState } from "react";

const FeeModal = (props) => {
  const [bidAmount, setBidAmount] = useState(0);
  const [userFee, setUserFee] = useState(0);
  const [sellerFee, setSellerFee] = useState(0);

  function calculateFees(bidAmount) {
    // Seller Fee (PayPal): 2.99% of the bid amount
    const sellerFeePercent = 2.99;
    const sellerFee = (bidAmount * sellerFeePercent) / 100;

    // User Fee (BidBikes): 5% of the bid amount, with a minimum of $25 and a maximum of $500
    const userFeePercent = 0;
    const minUserFee = 0;
    const maxUserFee = 500;
    const userFee = Math.min(
      Math.max((bidAmount * userFeePercent) / 100, minUserFee),
      maxUserFee
    );

    return {
      sellerFee,
      userFee,
    };
  }

  function handleBidAmountChange(event) {
    const newBidAmount = parseFloat(event.target.value);
    setBidAmount(newBidAmount);

    const { sellerFee, userFee } = calculateFees(newBidAmount);
    setSellerFee(sellerFee);
    setUserFee(userFee);
  }

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  }

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Fee Estimator</h5>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="bidAmount">Enter Bid Amount:</label>
              <input
                type="number"
                className="form-control"
                id="bidAmount"
                value={bidAmount}
                onChange={handleBidAmountChange}
                min={0}
                max={20000}
              />
            </div>
            <table>
              <tbody>
                <h4>
                  <b>Seller</b>
                </h4>
                <tr className="table-row">
                  <td>Final Bid Amount:</td>
                  <td>{formatCurrency(bidAmount)}</td>
                </tr>
                <tr className="table-row">
                  <td>PayPal Fees (@2.99%)*:</td>
                  <td>{formatCurrency(sellerFee)}</td>
                </tr>
                <tr className="table-row">
                  <td>Seller Take Home: </td>
                  <td>{formatCurrency(bidAmount - sellerFee)}</td>
                </tr>
                <h4 style={{ marginTop: "15px" }}>
                  <b>Buyer</b>
                </h4>
                <tr className="table-row">
                  <td>Final Bid Amount:</td>
                  <td>{formatCurrency(bidAmount)}</td>
                </tr>
                <tr className="table-row">
                  <td>BidBikes Fee (@0%):</td>
                  <td>{formatCurrency(userFee)}</td>
                </tr>
                <tr className="table-row">
                  <td>Shipping Fee**:</td>
                  <td>TBD</td>
                </tr>
                <tr className="table-row">
                  <td>Total Buyer Cost: </td>
                  <td>{formatCurrency(bidAmount + userFee)}</td>
                </tr>
              </tbody>
            </table>
            <h5 style={{ marginTop: "15px" }}>
              <b>Notes</b>
              <p style={{ fontSize: "12px", marginTop: "10px" }}>
                * Seller fee optional if using PayPayl to collect payment.
              </p>
              <p style={{ fontSize: "12px", marginTop: "10px" }}>
                ** Seller may include shipping in auction listing. Otherwise,
                shipping is responsibility of buyer.
              </p>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeModal;
