import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../../assets/images/bg/1-bikes.png";
import img2 from "../../../assets/images/bg/2-curate.png";
import img3 from "../../../assets/images/bg/3-BicycleShop.png";
import img4 from "../../../assets/images/bg/4-alarm.png";
import img5 from "../../../assets/images/bg/5-buyer-seller.png";
import img6 from "../../../assets/images/bg/6-auction.png";
import img7 from "../../../assets/images/bg/7-transaction.png";
import img8 from "../../../assets/images/bg/8-thanks2.png";

function HowItWorks() {
  return (
    <>
      <div
        className="container-fluid"
        style={{
          maxWidth: "1200px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="testimonial-section"
          style={{
            marginTop: "60px",
            padding: "50px 20px 20px",
            // maxWidth: "1000px"
          }}
        >
          {/* <div
            className="row d-flex justify-content-center"
            //   style={{
            //     marginTop: "60px",
            //     paddingLeft: "10px",
            //     paddingRight: "10px",
            //   }}
          >
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-4">
              <div className="section-title4">
                <h2>How It Works</h2>
               
              </div>
            </div>
          </div> */}
          <div className="how-work-section pt-0 pb-0">
            {/* <img
              alt="images"
              src={"/images/bg/section-bg.png"}
              className="section-bg-top"
            /> */}
            <div className="section-title4">
              <h2>How It Works</h2>
            </div>
            <div className="container-fluid">
              <div className="row g-4 mb-60">
                <div className="col-lg-6">
                  <div
                    className="how-work-content wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    {/* <span>01</span> */}
                    <h4>Sell Your Bike</h4>

                    <p className="">
                      Start your selling journey by listing your mountain, road,
                      or electric bikes with us. Just fill in the details—make,
                      model, year, condition, and any special modifications.
                      Don’t forget to upload eye-catching photos that highlight
                      what makes your bike special. <br></br> <br></br>
                      Our team at BidBikes carefully checks each listing to
                      ensure authenticity and top quality. We're here to make
                      sure every bike on our platform is the real deal, ready to
                      find a new home. <br></br> <br></br>
                      Ready to turn your bike into someone else's treasure? List
                      it now and let the excitement begin.
                    </p>
                  </div>
                  <div
                    className="slider-bottom d-flex justify-content-center align-items-center"
                    style={{ marginBottom: "40px", marginTop: "20px" }}
                  >
                    <Link
                      to={`/sell-your-bike`}
                      className="form-button wow fadeInUp"
                      style={{ maxWidth: "300px" }}
                    >
                      Sell Your Bike
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
                  <div
                    className="how-work-img wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <img alt="images" src={img1} className="work-img" />
                  </div>
                </div>
              </div>

              <div className="row g-4 mb-60">
                <div className="col-lg-6">
                  <div
                    className="how-work-content wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    {/* <span>02</span> */}
                    <h4>Bidding</h4>
                    <p className="">
                      Feel the rush of the auction! Bid on premium bikes and get
                      swept up in the excitement of competing for that perfect
                      ride. <br></br> <br></br>
                      Our auctions are designed for fairness—extended by
                      last-minute bids to give everyone a fair shot. With no
                      fees to bid, why wait? Dive into our vibrant community of
                      cycling enthusiasts and start bidding today. <br></br>{" "}
                      <br></br>
                      Register now and let the adventure begin. Who knows? Your
                      dream bike could be just a bid away!
                    </p>
                  </div>
                  <div
                    className="slider-bottom d-flex justify-content-center align-items-center"
                    style={{ marginBottom: "40px", marginTop: "20px" }}
                  >
                    <Link
                      to={`/register`}
                      className="form-button wow fadeInUp"
                      style={{ maxWidth: "300px" }}
                    >
                      Register to Bid
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
                  <div
                    className="how-work-img wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <img alt="images" src={img6} className="work-img" />
                  </div>
                </div>
              </div>

              <div className="row g-4 mb-60">
                <div className="col-lg-6">
                  <div
                    className="how-work-content wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    {/* <span>03</span> */}
                    <h4>Finalizing the Deal</h4>
					<p className="">
  Congratulations to the highest bidder! As the auction ends, the winning bid marks the start of a new journey with your new bike. <br></br> <br></br>
  We facilitate a smooth and secure transaction between you and the seller, ensuring everything from payment to pickup goes without a hitch. <br></br> <br></br>
  From your first bid to finally riding your new bike, we make sure the process is straightforward and hassle-free. Got questions? Our FAQ section has answers, or you can reach out to our friendly support team.
</p>

                  </div>
                  <div
                    className="slider-bottom d-flex justify-content-center align-items-center"
                    style={{ marginBottom: "40px", marginTop: "20px" }}
                  >
                    <Link
                      to={`/faq`}
                      // onClick={scrollTop}
                      //   className="eg-btn btn--primary3-outlineR btn--md"
                      className="form-button wow fadeInUp"
                      style={{ maxWidth: "300px" }}
                    >
                      View FAQs
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
                  <div
                    className="how-work-img wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <img alt="images" src={img7} className="work-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
