import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { ListingContext } from "../auctionDetails/AuctionDetails";
import { Spinner } from "react-bootstrap";
import FB from "../../../data/Firebase";
import SendIcon from "../../../assets/images/icons/send-icon.svg";
import BidModal from "../../common/BidModal";

function AuctionDetailsTab({ bidSectionRef }) {
  const { auction } = useContext(ListingContext);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [listing, setListing] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [bidMessages, setBidMessages] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [newChatMessage, setNewChatMessage] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [replyToUsername, setReplyToUsername] = useState("");
  //const commentInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("pills-all");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bid, setBid] = useState(0);
  const [recentBid, setRecentBid] = useState("$0");

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!auction.id) return; // Add this line

        const listing = await FB.getAuctionInfo(auction.id);
        if (listing) {
          setListing(listing);
          setBid(listing.current_bid);
          setIsLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching auction info: ", error);
      }
    };

    fetchData();
  }, [auction.id]);

  useEffect(() => {
    const getUserDetails = async () => {
      if (user) {
        const userDetails = await FB.getUserInfo(user.uid);
        setUserDetails(userDetails);
      }
    };

    getUserDetails();
  }, [user]);

  useEffect(() => {
    FB.auth.onAuthStateChanged((authUser) => {
      if (authUser && authUser.emailVerified) {
        setUser(authUser);
      }
    });
  }, []);

  useEffect(() => {
    if (!auction.id) return; // Add this line

    const unsubscribeBids = FB.getBidMessages(auction.id, setBidMessages);
    const unsubscribeMessages = FB.getChatMessages(auction.id, setChatMessages);

    return () => {
      unsubscribeBids();
      unsubscribeMessages();
    };
  }, [auction.id]);

  useEffect(() => {
    // console.log("5");
    const combineAndSortData = () => {
      // Combine
      const data = [...bidMessages, ...chatMessages];

      // Sort by 'createdAt' timestamp
      data.sort((a, b) => b.createdAt - a.createdAt);

      // Set combined data
      setCombinedData(data);
    };

    combineAndSortData();
  }, [bidMessages, chatMessages]);

  const handleSignIn = () => {
    history.push({
      pathname: "/login",
      state: { from: `/auction-details/${auction.id}` },
    });
  };

  const handleRegister = () => {
    history.push({
      pathname: "/register",
      state: { from: `/auction-details/${auction.id}` },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    //console.log("recentBid before opening modal: ", recentBid); // Debugging line

    if (user) {
      if (userDetails.stripeCustomerId) {
        handleOpenModal();
      } else {
        alert("Please register to bid via your user dashboard.");
        history.push(`/dashboard`); // Redirect to the dashboard where user can register to bid
      }
    } else {
      history.push(`/login`); // Redirect to the login page
    }
  };

  useEffect(() => {
    if (auction && auction.current_bid) {
      const bid = auction.current_bid;
      setRecentBid(bid);
      //console.log(recentBid);
    }
  }, [auction]);

  const handleReplyComment = async (commentId) => {
    try {
      // Check if the user is authenticated
      const user = FB.auth.currentUser;
      if (!user) {
        // If the user is not authenticated, redirect them to the signing page
        history.push("/login"); // Make sure to import 'history' from react-router-dom
        return;
      }

      // Find the user's name from the chatMessages array using the commentId
      const comment = chatMessages.find((message) => message.id === commentId);
      if (!comment) {
        console.log("Comment not found.");
        return;
      }

      // Get the user's name and add it to the comment text box
      const userName = comment.username;
      setReplyToUsername(userName);

      // Switch to the comments tab
      setActiveTab("pills-comments");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLikeComment = async (commentId) => {
    try {
      // Check if the user is authenticated
      const user = FB.auth.currentUser;
      if (!user) {
        // If the user is not authenticated, redirect them to the signing page
        history.push("/login"); // Make sure to import 'history' from react-router-dom
        return;
      }
      // Update the like count for the comment and increment it by 1
      const updatedLikesCount = await FB.incrementCommentLikes(
        auction.id,
        commentId
      );

      // Optionally, you can update the like count in the state immediately
      // to provide a smooth user experience.
      setChatMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === commentId
            ? { ...message, likes: updatedLikesCount }
            : message
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleFlagComment = async (commentId) => {
    try {
      // Check if the user is authenticated
      const user = FB.auth.currentUser;
      if (!user) {
        // If the user is not authenticated, redirect them to the signing page
        history.push("/login"); // Make sure to import 'history' from react-router-dom
        return;
      }

      // Update the comment in Firestore to set the "flagged" field to true
      await FB.updateCommentFlag(auction.id, commentId, true);

      // Optionally, you can update the flagged status in the state immediately
      // to provide a smooth user experience.
      setChatMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === commentId ? { ...message, flagged: true } : message
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
    setLoading(false);
  }

  const BidInfo = ({ username, bid, text, isSeller }) => (
    <div className="bidder-content">
      <div className="username-chip-container">
        <h6>{username}</h6>
        {isSeller && (
          <div
            className="seller-chip"
            style={{
              marginLeft: "5px", // Adjust the spacing between username and chip
              backgroundColor: "#26a96c",
              color: "white",
              borderRadius: "5px",
              padding: "2px 6px",
              fontSize: "12px",
              letterSpacing: "0.5px",
              marginBottom: "6px",
            }}
          >
            Seller
          </div>
        )}
      </div>
      {bid && (
        <div className="bid-chip">
          {bid.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      )}
      <p>
        {text?.split(" ").map((word, index) => (
          <span key={index} className={word.startsWith("@") ? "mention" : ""}>
            {word}{" "}
          </span>
        ))}
      </p>
    </div>
  );

  const CommentActions = ({ status, onLike, onReply, onFlag, likes }) =>
    status === "Live" && (
      <div className="comment-buttons" style={{ marginTop: "5px" }}>
        <Link to={"#"} onClick={onLike} className="comment-reply">
          <i className="bi bi-arrow-up-circle"></i>{" "}
          <b>{likes > 0 ? likes : 0}</b>
        </Link>
        <Link to={"#"} onClick={onReply} className="comment-reply">
          <i className="bi bi-reply"></i> Reply
        </Link>
        <Link to={"#"} onClick={onFlag} className="comment-reply">
          <i className="bi bi-flag"></i> Flag
        </Link>
      </div>
    );

  const MessageDateTime = ({ createdAt }) => (
    <div className="bid-time">
      <p>{createdAt.toLocaleDateString("en-US")}</p>
      <p>{createdAt.toLocaleTimeString("en-US")}</p>
    </div>
  );

  const BidButton = ({
    auction,
    user,
    userDetails,
    handleSignIn,
    handleSubmit,
    handleRegister,
  }) => {
    if (auction.status !== "Live") return null;

    if (!user) {
      return (
        <button className="form-button" onClick={handleSignIn}>
          Sign in to bid
        </button>
      );
    } else if (userDetails && userDetails.stripeCustomerId) {
      return (
        <button className="form-button" onClick={handleSubmit}>
          Place Bid
        </button>
      );
    } else {
      return (
        <button className="form-button" onClick={handleRegister}>
          Register to bid
        </button>
      );
    }
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Backspace" && replyToUsername && !newChatMessage) {
      setReplyToUsername(null);
    }
  };

  const handleMessageSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!newChatMessage) return;
      setLoading(true);
      FB.updateMessage(
        auction.id,
        replyToUsername
          ? `@${replyToUsername} ${newChatMessage}`
          : `${newChatMessage}`
      );
    } catch (error) {
      console.log(error.message);
    } finally {
      setNewChatMessage("");
      setReplyToUsername("");
      setLoading(false);
    }
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const TabSection = ({
    id,
    title,
    messages,
    onlyBids,
    onlyComments,
    activeTab,
  }) => (
    <div
      className={`tab-pane fade pt-3 show active`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
      ref={bidSectionRef}
    >
      <div className="bid-list-area">
        <div className="row d-flex pt-3">
          <div className="col-12 text-end">
            <div className="bid-time" id="bid-section">
              {title !== "Comments" ? (
                <BidButton
                  auction={auction}
                  user={user}
                  userDetails={userDetails}
                  handleSignIn={handleSignIn}
                  handleSubmit={handleSubmit}
                  handleRegister={handleRegister}
                  //setNewChatMessage={setNewChatMessage}
                />
              ) : (
                {
                  /* <CommentArea auction={auction} user={user} /> */
                }
              )}
            </div>
          </div>
        </div>
        <ul className="bid-list">
          <div
            className="blog-widget-title"
            style={{ marginBottom: "0px", marginTop: "15px" }}
          >
            <h5>
              {title} ({messages.length})
            </h5>
            <span />
          </div>
          {messages.map((message) => (
            <li key={message.id}>
              <div
                className="row d-flex justify-content-center align-items-center"
                style={{ margin: "0px" }}
              >
                <div className="col-8 p-0">
                  <div className="bidder-area">
                    <BidInfo
                      username={message.username}
                      bid={onlyComments ? null : message.bid} // Display bid if not in onlyComments mode
                      text={onlyBids ? null : message.text} // Display text if not in onlyBids mode
                      isSeller={message.username === auction.seller_username}
                    />
                  </div>
                  {!onlyBids && message.text && (
                    <CommentActions
                      status={auction.status}
                      onLike={() => handleLikeComment(message.id)}
                      onReply={() => handleReplyComment(message.id)}
                      onFlag={() => handleFlagComment(message.id)}
                      likes={message.likes}
                    />
                  )}
                </div>
                <div className="col-4 text-end">
                  <MessageDateTime createdAt={message.createdAt} />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Main Component Rendering
  return (
    <>
      {isModalOpen && (
        <BidModal
          handleCloseModal={handleCloseModal}
          auctionId={auction.id}
          recentBid={recentBid}
          setRecentBid={setRecentBid}
          onClose={handleCloseModal}
          min={recentBid}
          max={20000}
        />
      )}
      <div>
        <div className="blog-widget-title">
          <h5>Bike Information</h5>
          <span />
        </div>
        <table>
          <tbody>
            <tr className="table-row">
              <td>Year</td>
              <td>
                <b>{auction.year}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Brand</td>
              <td>
                <b>{auction.brand}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Model</td>
              <td>
                <b>{auction.model}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Type</td>
              <td>
                <b>{auction.type}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Size</td>
              <td>
                <b>{auction.frame_size}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Wheels</td>
              <td>
                <b>{auction.wheel_size}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Condition</td>
              <td>
                <b>{auction.condition}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Frame</td>
              <td>
                <b>{auction.frame_material}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Miles</td>
              <td>
                <b>{auction.miles}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Color</td>
              <td>
                <b>{auction.color}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>One Owner</td>
              <td>
                <b>{auction.original_owner}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Serial Number</td>
              <td>
                <b>{auction.serial_number}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Weight</td>
              <td>
                <b>{auction.weight}</b>
              </td>
            </tr>
            <tr className="table-row">
              <td>Shipping</td>
              <td>
                <b>{auction?.shipping ? auction.shipping : "TBD"}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <ul
          className="nav nav-pills d-flex flex-row gap-4"
          id="pills-tab"
          role="tablist"
          style={{ marginTop: "20px" }}
        >
          {["all", "bids", "comments"].map((tabId) => (
            <li className="nav-item flex-fill" role="presentation" key={tabId}>
              <button
                className={`nav-link details-tab-btn ${
                  activeTab === `pills-${tabId}` ? "active" : ""
                }`}
                id={`pills-${tabId}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#pills-${tabId}`}
                type="button"
                role="tab"
                aria-controls={`pills-${tabId}`}
                aria-selected={activeTab === `pills-${tabId}`}
                onClick={() => handleTabClick(`pills-${tabId}`)}
              >
                {capitalizeFirstLetter(tabId)}
              </button>
            </li>
          ))}
        </ul>

        {activeTab === "pills-all" && (
          <TabSection
            id="pills-all"
            title="Bids and Comments"
            messages={combinedData}
            onlyBids={false}
            onlyComments={false}
          />
        )}
        {activeTab === "pills-bids" && (
          <TabSection
            id="pills-bids"
            title="Bids"
            messages={bidMessages}
            onlyBids={true}
            onlyComments={false}
          />
        )}
        {activeTab === "pills-comments" && (
          <div className="bid-list-area">
            <div className="row d-flex pt-3">
              <div className="col-12 text-end">
                <div className="bid-time" id="bid-section">
                  {auction.status !== "Live" ? null : !user ? (
                    <button className="form-button" onClick={handleSignIn}>
                      Sign in to comment
                    </button>
                  ) : (
                    <div>
                      <div className="input-with-btn d-flex">
                        {replyToUsername && (
                          <div className="chip">
                            <button
                              type="button"
                              onClick={() => setReplyToUsername(null)}
                              aria-label="Remove reply"
                            >
                              @{replyToUsername}
                            </button>
                          </div>
                        )}
                        <textarea
                          type="text"
                          name="messageText"
                          id="messageText"
                          placeholder={
                            replyToUsername
                              ? `Reply to @${replyToUsername}...`
                              : "Add a comment..."
                          }
                          value={newChatMessage}
                          onChange={(event) =>
                            setNewChatMessage(event.target.value)
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                        <button
                          onClick={handleMessageSubmit}
                          disabled={loading || newChatMessage === ""}
                        >
                          {loading ? (
                            <Spinner animation="border" role="status" />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="white"
                              className="bi bi-send-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ul className="bid-list">
              <div
                className="blog-widget-title"
                style={{ marginBottom: "0px", marginTop: "15px" }}
              >
                <h5>
                  {"Comments"} ({chatMessages.length})
                </h5>
                <span />
              </div>
              {chatMessages.map((message) => (
                <li key={message.id}>
                  <div
                    className="row d-flex justify-content-center align-items-center"
                    style={{ margin: "0px" }}
                  >
                    <div className="col-8 p-0">
                      <div className="bidder-area">
                        <BidInfo
                          username={message.username}
                          bid={null}
                          text={message.text}
                          isSeller={
                            message.username === auction.seller_username
                          }
                        />
                      </div>
                      <CommentActions
                        status={auction.status}
                        onLike={() => handleLikeComment(message.id)}
                        onReply={() => handleReplyComment(message.id)}
                        onFlag={() => handleFlagComment(message.id)}
                        likes={message.likes}
                      />
                    </div>
                    <div className="col-4 text-end">
                      <MessageDateTime createdAt={message.createdAt} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default AuctionDetailsTab;

{
  /* <TabSection
id="pills-comments"
title="Comments"
messages={chatMessages}
onlyBids={false}
onlyComments={true}
/> */
}
