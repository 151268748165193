import PaymentForm from "./PaymentForm";

function RegistrationPage({ user }) {
  return (
    <div>
      <PaymentForm user={user} />
    </div>
  );
}

export default RegistrationPage;
