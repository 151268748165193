import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import FB from "../../../data/Firebase";
import Breadcrumb from "../../common/Breadcrumb";

function AuctionDetails(props) {
  const [auction, setAuction] = useState(null);
  const [selectedValue, setSelectedValue] = useState("bids");
  const { id } = useParams();
  const history = useHistory();
  const [bidsArray, setBidsArray] = useState([]);
  const [commentsArray, setCommentsArray] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedValue === "bids") {
      FB.getAuctionBids(id)
        .then((bidsArray) => {
          setBidsArray(bidsArray);
        })
        .catch((error) => {
          console.error("Error getting auction bids:", error);
        });
    } else if (selectedValue === "comments") {
      FB.getAuctionComments(id)
        .then((commentsArray) => {
          setCommentsArray(commentsArray);
        })
        .catch((error) => {
          console.error("Error getting auction comments:", error);
        });
    }
  }, [selectedValue, id]);

  useEffect(() => {
    const getAuctionDetails = async () => {
      try {
        if (!id || id.length !== 20) {
          setAuction(null);
          return;
        }
        const auction = await FB.getAuctionInfo(id);
        if (auction) {
          setAuction(auction);
        } else {
          console.log(`No auction found with ID ${id}`);
        }
      } catch (error) {
        console.error("Error fetching auction details:", error);
      }
    };

    getAuctionDetails();
  }, [id]);

  function handleBack() {
    history.push("/admin-auction");
  }

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function formatCurrency(value) {
    if (value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  }

  function formatDate(value) {
    if (value) {
      let dt = new Date(value.seconds * 1000);
      return dt.toLocaleString("en-US");
    }
  }

  return (
    <div>
      {!auction ? (
        <div>Loading...</div>
      ) : (
        <div className="user-details-container">
          <Breadcrumb pageName="Auction Details" pageTitle="Auction Details" />
          <div style={{ paddingTop: "120px", paddingBottom: "40px" }}>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <table>
                  <tbody>
                    <tr className="table-row">
                      <td>ID:</td>
                      <td>{id}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Title:</td>
                      <td>{auction.title}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Description:</td>
                      <td>{auction.description}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Bid Ends:</td>
                      <td>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {formatDate(auction.bid_end_time)}
                        </div>
                      </td>
                    </tr>
                    <tr className="table-row">
                      <td>Current Bid:</td>
                      <td>{formatCurrency(auction.current_bid)}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Condition:</td>
                      <td>{auction.condition}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Reserve:</td>
                      <td>{formatCurrency(auction.reserve_requested)}</td>
                    </tr>
                    <tr className="table-row">
                      <td>Status:</td>
                      <td>{auction.status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    maxWidth: "1000px",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "20px 10px",
                    }}
                  >
                    <select
                      id="selection"
                      name="selection"
                      value={selectedValue}
                      onChange={handleSelectChange}
                      style={{
                        padding: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        minWidth: "150px",
                      }}
                    >
                      <option value="bids">Bids</option>
                      <option value="comments">Comments</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                {selectedValue === "bids" ? (
                  <table style={{ paddingTop: "20px" }}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Bid</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bidsArray.map((bid, index) => (
                        <tr key={bid.id} className="table-row">
                          <td>{bid.id.substring(0, 6)}</td>
                          <td>{formatCurrency(bid.bid)}</td>
                          <td>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                              {new Date(
                                bid.createdAt.seconds * 1000
                              ).toLocaleDateString("en-US")}
                              {"\n"}
                              {new Date(
                                bid.createdAt.seconds * 1000
                              ).toLocaleTimeString("en-US")}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <table style={{ paddingTop: "20px" }}>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Comment</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsArray.map((comment, index) => (
                          <tr key={comment.id} className="table-row">
                            <td>{comment.id.substring(0, 6)}</td>
                            <td>{comment.text}</td>
                            <td>
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {new Date(
                                  comment.createdAt.seconds * 1000
                                ).toLocaleDateString("en-US")}
                                {"\n"}
                                {new Date(
                                  comment.createdAt.seconds * 1000
                                ).toLocaleTimeString("en-US")}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="button-group"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  type="submit"
                  className="profile-btn"
                  onClick={handleBack}
                  style={{ margin: "10px 20px", minWidth: "150px" }}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuctionDetails;
