import React, { useState, useEffect } from "react";
import FB from "../../data/Firebase";
import { Spinner } from "react-bootstrap";

function BidModal(props) {
  const [bidAmount, setBidAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function validateBidAmount() {
    if (
      !bidAmount ||
      isNaN(bidAmount) ||
      bidAmount <= 0 ||
      bidAmount < props.min ||
      bidAmount > props.max
    ) {
      return `Bid amount must be a valid number between $${props.min} and $${props.max}.`;
    }
    return "";
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");

    const validationError = validateBidAmount();
    if (validationError) {
      setErrorMessage(validationError);
      setLoading(false);
      return;
    }

    try {
      const result = await FB.setAuctionBid(props.auctionId, bidAmount);
      if (result && result.error) {
        throw new Error(result.error.message);
      }
      props.onClose();
    } catch (error) {
      console.error("Error placing bid:", error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  //console.log("Recent bid amount received as prop:", props.recentBid);

  // Adjustments inside BidModal
  const handleIncrement = () => {
    // Increment bid but do not exceed max
    setBidAmount((prev) => Math.min(Number(prev) + 1, props.max).toString());
  };

  const handleDecrement = () => {
    // Decrement bid but do not fall below min
    setBidAmount((prev) => Math.max(Number(prev) - 1, props.min).toString());
  };

  useEffect(() => {
    setBidAmount(props.recentBid.toString()); // Update whenever recentBid changes
  }, [props.recentBid]);

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Place your bid</h5>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {errorMessage && (
              <div className="text-danger" style={{ marginBottom: "15px" }}>
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDecrement}
                  disabled={Number(bidAmount) <= props.min}
                  style={{ width: "45px" }}
                >
                  -
                </button>
                <input
                  placeholder="Enter bid amount ($)"
                  type="number"
                  className="form-control text-center mx-2"
                  id="bidAmount"
                  min={props.min}
                  max={props.max}
                  value={bidAmount}
                  onChange={(event) => setBidAmount(event.target.value)}
                  style={{ flexGrow: 1 }}
                />
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleIncrement}
                  disabled={Number(bidAmount) >= props.max}
                  style={{ width: "45px" }}
                >
                  +
                </button>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginRight: "5px" }}
                  />
                ) : (
                  "Submit Bid"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidModal;
