// import React from "react";
// import Footer from "./common/Footer";
// import Header from "./common/Header";
// //import bg from "../assets/images/bg/FB Background.png";

// function Layout({ children }) {
//   return (
//     <>
//       <Header />
//       {children}
//       <Footer />
//     </>
//   );
// }

// export default Layout;

import React, { useState, useEffect } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import FeedbackButton from "./common/FeedbackButton";

function Layout({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <>
      <Header />
      {/* Network status indicator */}
      {!isOnline && (
        <div className="offline-warning">
          You are offline. Some features may not be available.
        </div>
      )}
      {children}
      <Footer />
      <FeedbackButton />
    </>
  );
}

export default Layout;
