import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./Dashboard";
import FB from "../../../data/Firebase";

function ReferralInfo() {
  const { user, userDetails } = useContext(UserContext);
  const [auctionData, setAuctionData] = useState([]);
  const [auctionIds, setAuctionIds] = useState([]);

  useEffect(() => {
    if (user && user.uid) {
      const fetchReferralAuctionIds = async () => {
        try {
          const referralAuctionIds = await FB.getReferralAuctionIds(user.uid);
          setAuctionIds(referralAuctionIds);
        } catch (error) {
          console.error("Error fetching referral auction IDs:", error);
        }
      };

      fetchReferralAuctionIds();
    }
  }, [user]);

  useEffect(() => {
    // Fetch auctions based on the retrieved auctionIds
    const fetchAuctions = async () => {
      try {
        const auctionPromises = auctionIds.map((auctionId) =>
          FB.getAuctionInfo(auctionId)
        );

        const auctionData = await Promise.all(auctionPromises);

        setAuctionData(auctionData);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    if (auctionIds.length > 0) {
      fetchAuctions();
    }
  }, [auctionIds, FB]);

  return (
    <div
      className="tab-pane fade show"
      id="v-pills-referral"
      role="tabpanel"
      aria-labelledby="v-pills-referral-tab"
      style={{ minHeight: "800px" }}
    >
      <div className="dashboard-referral">
        <div className="table-title-area">
          <h3>Your Referral Info</h3>
        </div>

        <div className="form-wrapper">
          <div className="row">
            <p className="para">Account Information</p>
            <table>
              <tbody>
                <tr className="table-row">
                  <td>Code:</td>
                  <td>{userDetails?.referralCode || "Not Available"}</td>
                </tr>
                <tr className="table-row">
                  <td>Referrals:</td>
                  <td>{auctionData.length || "0"}</td>
                </tr>
                <tr className="table-row">
                  <td>Payout revenue:</td>
                  <td>{userDetails?.referralRevenue || "$0"}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <p className="para">My Referrals</p>
            <table>
              <thead>
                <tr>
                  <th>Auction</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {auctionData.map((auction, index) => (
                  <tr key={index} className="table-row">
                    <td>
                      <img
                        alt="images"
                        src={auction?.resized_image_urls[400][0]}
                        className="img-fluid"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </td>
                    <td>{auction?.title}</td>
                    <td>{auction?.status}</td>
                    <td>
                      <p>{auction?.createdAt.toDate().toLocaleDateString()}</p>
                      <p>{auction?.createdAt.toDate().toLocaleTimeString()}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralInfo;
