import React from "react";
//import AboutUsCounter from "../../common/AboutUsCounter";
import Breadcrumb from "../../common/Breadcrumb";
import WhyChooseUs from "../howItWork/WhyChooseUs";
//import AboutTestimonial from "./AboutTestimonial";
import WhoWeAreArea from "./WhoWeAreArea";

function About() {
  return (
    <>
      <Breadcrumb pageName="Sell Your Bike" pageTitle="Sell Your Bike" />
      <WhoWeAreArea />
      <WhyChooseUs />
      {/* <AboutTestimonial/> */}
      {/* <AboutUsCounter/> */}
    </>
  );
}

export default About;
